import React from 'react';
import "./CivilTestToggleContent.css";
import { BiSolidBookOpen } from "react-icons/bi";
// import { IoIosArrowForward } from "react-icons/io";
import Content from '../../../AllPageContent/AllPageContent';


function CivilTestToggleContent({items}) {
    return (
        <>
            <div className='Toggle-Content-backaground bg-light p-4'>
                <div className='row'>
                    <div className='col-lg-2 text-center'>
                        <BiSolidBookOpen className='book-icon' />
                        <h5 className='fw-bold py-2 book-text'>SYLLABUS</h5>
                    </div>
                    <div className='col-lg-10'>
                        {/* <ul>
                        {items[0].map((item, index) => (
                                <li key={index}>
                                    <IoIosArrowForward /> {item}
                                </li>
                            ))}
                        </ul> */}
                        <Content paragraphs={items} />
                        {/* <h5 className='fw-bold fs-6'>{CurrentAffairs}</h5> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default CivilTestToggleContent;