import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import { RxDashboard } from "react-icons/rx";
import { FaPlus } from "react-icons/fa6";
import { FaMinus } from "react-icons/fa6";
import { IoMdArrowDropright } from "react-icons/io";

function Homequizmenu({ subjects, setSelectedSectionId, setSelectedSubjectId, setSelectedSection, setSelectedSubject }) {

    const [openSubject, setOpenSubject] = useState(null);

    const handleToggle = (subjectId) => {
        setOpenSubject(openSubject === subjectId ? null : subjectId);
    };

    const handleQuizid = (subjectId, sectionId, subject, section) => {
        console.log()
        setSelectedSectionId(sectionId)
        setSelectedSubjectId(subjectId)
        setSelectedSection(section)
        setSelectedSubject(subject)
    }

    return (
        <>
            <div className="card">
                <div className="card-header list-background py-3 fs-5 text-white">
                    <RxDashboard className='me-2 mb-1' />
                    Select Your Subject
                </div>
                <ul className="list-group list-group-flush">
                    {subjects.map((subject) => {
                        const sections = subject.sections || [];
                        return (
                            <div key={subject.subjectId}>
                                <Link
                                    className="list-group-item d-flex justify-content-between fw-bold list-container"
                                    onClick={() => handleToggle(subject.subjectId)}
                                    to="#"
                                >
                                    {subject.subject} {openSubject === subject.subjectId ? <FaMinus className='list-icon my-1 p-1' /> : <FaPlus className='list-icon my-1 p-1' />}
                                </Link>
                                {openSubject === subject.subjectId && sections.length > 0 && (
                                    <div className='p-3'>
                                        <ul style={{ listStyleType: "none", display: "flex", flexDirection: "column", padding: "0px" }}>
                                            {sections.map((section) => (
                                                <li key={section.sectionId} className=''>
                                                    <Link to={`/upsc-quiz`} onClick={() => handleQuizid(subject.subjectId, section.sectionId, subject.subject, section.sectionName)} className='list-group-item'><IoMdArrowDropright className="text-orange fs-4" />{section.sectionName}</Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </ul>
            </div>
        </>
    )
}

export default Homequizmenu;