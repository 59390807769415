import React, { useEffect, useState } from 'react'
import CardsPage from '../CardsPage/CardsPage';
import { fetchNotMyCourse } from '../DynamicCourse/api';

function Subscribemore({ heading}) {
  // const [userData, setUserData] = useState([]);
  // const [mycourse, setMycourse] = useState([]);
  const [notmycourse, setNotmycourse] = useState([]);
  const authtoken = localStorage.getItem('authtoken');
  // const [mytest, setMytest] = useState([]);
  // const [notmytest, setNotmytest] = useState([]);
  // useEffect(()=>{
  //   // {cards.map((card) => (
  //   //   setcoursenotpayment(card.paymentdonecourse),
  //   //   setcoursepayment(card.paymentdonecourse)
  //   // ))}
  //   setcoursepayment(0)
  //   setcoursenotpayment(1)
  //   setispayment(false)
  //   localStorage.setItem('ispayment', JSON.stringify(false));
  // },[])

  useEffect(() => {
    const fetchData = async () => {
        try {
            // const data = await fetchUserDetails(authtoken);
            // const data1 = await fetchMyCourse(authtoken);
            const data2 = await fetchNotMyCourse(authtoken);
            // const data3 = await fetchMyTest(authtoken);
            // const data4 = await fetchNotMyTest(authtoken);
            // setUserData(data);
            // setMycourse(data1);
            setNotmycourse(data2);
            // setMytest(data3);
            // setNotmytest(data4);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    fetchData();
}, [authtoken]); 
  return (
    <>
     <CardsPage cards={notmycourse} heading={heading}/> 
    </>
  )
}

export default Subscribemore;