import React from 'react';
import { Helmet } from 'react-helmet-async';
import "../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection.css";
import CivilTopSection from '../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection';
import userImage from "../../../Image/user.png";
import aboutImage from "../../../Image/aboutbuilding.jpg";
import CivilrightSection from '../../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection';
import Content from '../../AllPageContent/AllPageContent';
import ButtonPage from '../../ButtonPage/ButtonPage';
import CurrentAffair from '../../CurrentAffair/CurrentAffair';
import { FaPencilAlt } from "react-icons/fa";


function Civilwhysri() { 
  const Buttonname = "Multiplebutton";
  const componentName = "currentaffairs";

  // Banner Content For CivilAbout Page
  const data = {
    subacademy: "Director's Message" ,
    academyName: "Director's Message" ,
    userImage: userImage,
    userName: 'Priya Rathore',
    Image: aboutImage
  };

  // Content For CivilAbout Page
  const paragraphstatic = [
    {
      text: "Dear Aspirant,",
      styles: [
        { word: 'Dear Aspirant,', style: 'font-weight: bold; color: #26156d;' },
        { word: 'SRIRAM’s IAS', style: 'font-weight: bold; color: red;' }
      ]
    },
    {
      text: "Congrats on your first step towards becoming a civil servant! We're thrilled to have you join the SRIRAM's IAS family.",
      styles: [
        { word: 'Shri S. Baliyan', style: 'font-weight: bold;' },
        { word: 'History and Art and Culture', style: 'font-weight: bold;' },
        { word: "Congrats on your first step towards becoming a civil servant! We're thrilled to have you join the SRIRAM's IAS family.", style: 'font-size: 16px; color: #db261f;' }
      ]
    },
    {
      text: "Here's what makes us special:",
      styles: [
        { word: "Here's what makes us special:", style: 'font-weight: bold;' },
        { word: 'History and Art and Culture', style: 'font-weight: bold;' },
        { word: '‘A Compendium of Indian Art & Culture’', style: 'font-weight: bold; color: #C00000;' }
      ]
    },
    {
      text: "Expert faculty with in-depth UPSC knowledge",
      styles: [
        { word: 'Shri S. Baliyan', style: 'font-weight: bold;' },
        { word: 'History and Art and Culture', style: 'font-weight: bold;' },
        { word: '‘A Compendium of Indian Art & Culture’', style: 'font-weight: bold; color: #C00000;' }
      ]
    },
    {
      text: "Personalized mentorship",
      styles: [
        { word: 'Shri S. Baliyan', style: 'font-weight: bold;' },
        { word: 'History and Art and Culture', style: 'font-weight: bold;' },
        { word: '‘A Compendium of Indian Art & Culture’', style: 'font-weight: bold; color: #C00000;' }
      ]
    },
    {
      text: "Interactive learning sessions",
      styles: [
        { word: 'Shri S. Baliyan', style: 'font-weight: bold;' },
        { word: 'History and Art and Culture', style: 'font-weight: bold;' },
        { word: '‘A Compendium of Indian Art & Culture’', style: 'font-weight: bold; color: #C00000;' }
      ]
    }
  ];

  const paragraphstatic1 = [
    
    {
      text: "Regular feedback and custom resources",
      styles: [
        { word: 'Our High Success Rate', style: 'font-weight: bold; color: #26156d;' },
        { word: 'topped the UPSC Civil Services Examination', style: 'font-weight: bold;' }
      ]
    },
    {
      text: "A supportive environment for your growth",
      styles: [
        { word: 'holistic learning approach', style: 'font-weight: bold;' },
        { word: 'Maximum attention', style: 'font-weight: bold;' },
        { word: 'build solid foundations', style: 'font-weight: bold;' },
        { word: 'concept building', style: 'font-weight: bold;' },
        { word: 'regular interaction', style: 'font-weight: bold;' }
      ]
    }
  ]

  const paragraphstatic2 = [
    {
      text: "We're not just about teaching - we're here to boost your confidence and skills. Our mission is to help you ace the UPSC exams and prepare you to",
      styles: [{
        word: 'The strategy of SRIRAMs IAS for making students benefit immensely by their association with us is', style: 'font-weight: bold;'
      }]
    },
    {
      text: "make a real difference in society.",
      styles: [{
        word: 'The strategy of SRIRAMs IAS for making students benefit immensely by their association with us is', style: 'font-weight: bold;'
      }]
    },
    {
      text: "Remember, you're not alone on this journey. Our entire team is here to support you, answer your questions, and celebrate your wins.",
      styles: [{
        word: 'SRIRAMs IAS is at your service till you are in Civil Service.', style: 'font-weight: bold;'
      }],
    },
    {
      text: "Let's turn your aspirations into reality. With your determination and our guidance, we're confident you'll achieve great things!",
      styles: [{
        word: 'SRIRAMs IAS is at your service till you are in Civil Service.', style: 'font-weight: bold;'
      }],
    },
    {
      text: "Welcome aboard! We're excited to start this journey with you.",
      styles: [{
        word: 'SRIRAMs IAS is at your service till you are in Civil Service.', style: 'font-weight: bold;'
      }],
    },
    {
      text: "Best wishes,",
      styles: [{
        word: 'SRIRAMs IAS is at your service till you are in Civil Service.', style: 'font-weight: bold;'
      }],
    },
    {
      text: "The SRIRAM's IAS Team",
      styles: [{
        word: 'SRIRAMs IAS is at your service till you are in Civil Service.', style: 'font-weight: bold;'
      }],
    }
  ]

  const paragraphstatic4 = [
    {
      text: "Let's turn your aspirations into reality. With your determination and our guidance, we're confident you'll achieve great things!",
      styles: [{
        word: 'Why Sriram’s IAS', style: 'font-weight: bold;'
      }],
      lists: [
        'Dedicated and Experienced Faculties',
        'Comprehensive Coverage of UPSC Syllabus',
        'Well structured and updated Class Notes and books',
        'Personalized one-to-one Mentoring',
        'Recorded lectures availability for future reference and revision',
        'Hand Written Notes for ready reference'
      ] 
    }
  ]

  const paragraphstatic5 = [
    {
      text: "Welcome aboard! We're excited to start this journey with you.",
      styles: [{
        word: 'Why Sriram’s IAS', style: 'font-weight: bold;'
      }],
      lists: [
        'Regular Workshops with UPSC Toppers’ and retired Bureaucrats',
         'Continuous assessments through Prelims and Mains Test Series',
         'Exclusive sessions for doubt clearance',
         'Weekly Current Affairs Session',
         'Monthly Current Affairs Magazine',
         'Proven track record with consistent top results'
      ] 
    },
    {
      text: "Best wishes,",
      styles: [{
        word: 'Why Sriram’s IAS', style: 'font-weight: bold;'
      }],
      lists: [
        'Regular Workshops with UPSC Toppers’ and retired Bureaucrats',
         'Continuous assessments through Prelims and Mains Test Series',
         'Exclusive sessions for doubt clearance',
         'Weekly Current Affairs Session',
         'Monthly Current Affairs Magazine',
         'Proven track record with consistent top results'
      ] 
    }
  ]
  
 
  return (
    <>
      <Helmet>
        <title>About SRIRAMs IAS Coaching Institute, Delhi</title>
        <meta name="description" content="SRIRAM’s IAS has emerged as one of the leading institutes for UPSC in India with the vision to be the Centre of Excellence and the mission to develop IAS, IPS"></meta>
        <meta name="keywords" content="SRIRAM’s IAS,SRIRANGAM SRIRAM, sriramias, sriram ias, shriram ias, shriramias, shri ram ias"></meta>
      </Helmet>
      {/* Civil About Section Start */}
      <section className='civilabout'>
        {/* Civil Top Section Start */}
        <CivilTopSection
        subacademy={data.subacademy}
          academyName={data.academyName}
          userImage={data.userImage}
          userName={data.userName}
          Image={data.Image}
        />
        {/* Civil Top Section End */}
      </section>
      <div className='Civil-section'>
        <div className='civil-row'>
          <div className='col-lg-12 col-md-12 col-sm-12'>
            {/* Content Component Start */}
            <div className=''>
            <div className='about-message'>
              <h1 className='fw-bold wow animate__animated animate__bounceIn' style={{letterSpacing: "0.1vmax"}}><FaPencilAlt className='mb-3' /> A Message from the Founder / From Founder’s Desk</h1>
              <div className='d-flex justify-content-between'>
              <div className='col-lg-9 pe-4'>
            <Content paragraphstatic={paragraphstatic} />
              </div>
              <div className='col-lg-3'>
                <img style={{width: "100%"}} src='https://img.freepik.com/premium-photo/professional-businessman-holding-folder_1059430-104298.jpg?ga=GA1.1.1477802574.1694597719&semt=ais_hybrid' alt=''/>
              </div>
              </div>
            </div>
             <div className=''>
    {/* <img src={aboutImage} style={{height: "305px", width: "450px"}} alt=''/> */}
             </div>
            </div>
             <div className='about-message'>
             <Content paragraphstatic={paragraphstatic1} />
            <Content paragraphstatic={paragraphstatic2} />
             </div>
            {/* Content Component End */}
            {/* <ButtonPage Pagename={Buttonname} /> */}
            {/* <div className='continer-fluid'> */}
              {/* <div className='row'>
                <div className='col-lg-6 p-3'>
                    <div className='card aboutus-card-blue p-4 pb-5' style={{background: "#26156d", color: "white"}}>
                      <h1 className='fw-bold'>OUR VISION</h1>
                      <p>To be the Center of Excellence to ensure our students are successful and that achieve their aspirations within a time frame.</p>
                    </div>
                </div>
                <div className='col-lg-6 p-3'>
                  <div className='card aboutus-card-red p-4' style={{background: "#db261f", color: "white"}}>
                    <h1 className='fw-bold'>OUR MISSION</h1>
                    <p>To empower aspirants of Civil Services to excel by Imparting to them right and relevant information and perspective. The aim is to help them acquire appropriate analytical skills to be on their own.</p>
                  </div>
                </div>
              </div> */}
        
            {/* </div> */}
    
            {/* <div className='container'> */}
          </div>
          {/* Civil right Section Start */}
          {/* <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} />
              </div>
          </div> */}
          {/* Civil right Section End */}
        </div>
      </div>
      {/* Civil About Section End */}
    </>
  )
}

export default Civilwhysri;