import React from 'react';
import "./SriramTest.css";
import CardsPage from '../../CardsPage/CardsPage';

function SriramTest() {
  // Array of course data
  const civilheading = "CIVILS COURSES"

  const courses = [
    {
      title: "GS Prelims cum Mains + CSAT + Optional Online (GS+CSAT+Optional)",
      image: "https://sriramsias.com/admin/upload/BP240726125928.png", // Replace with actual image paths
      batchStart: "9th August 2024",
      time: "8:00AM to 10:30AM",
      language: "English",
      originalPrice: "₹175000",
      discountedPrice: "₹157500"
    },
    {
      title: "GS Prelims cum Mains + CSAT + Optional Offline (GS+CSAT+Optional)",
      image: "https://sriramsias.com/admin/upload/BP240726130019.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "General Studies Prelims cum Mains Online (GS Only)",
      image: "https://sriramsias.com/admin/upload/BP240726125843.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "General Studies Prelims cum Mains Offline (GS Only)",
      image: "https://sriramsias.com/admin/upload/BP240726125943.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "GS Prelims cum Mains + CSAT Online (GS+CSAT)",
      image: "https://sriramsias.com/admin/upload/BP240726125855.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "GS Prelims cum Mains + CSAT Offline (GS+CSAT)",
      image: "https://sriramsias.com/admin/upload/BP240726125952.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "GS Prelims cum Mains + Optional Online (GS+Optional)",
      image: "https://sriramsias.com/admin/upload/BP240726125910.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "GS Prelims cum Mains + Optional Offline (GS+Optional)",
      image: "https://sriramsias.com/admin/upload/BP240726130003.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "GS Prelims cum Mains + CSAT Offline Batch (GS + CSAT)",
      image: "https://sriramsias.com/admin/upload/BP240726123104.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },




    {
      title: "General Studies Prelims cum Mains Online Batch (GS Only)",
      image: "https://sriramsias.com/admin/upload/BP240726123026.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "GS Prelims cum Mains + CSAT Online Batch (GS + CSAT)",
      image: "https://sriramsias.com/admin/upload/BP240726123043.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "General Studies Prelims cum Mains Offline Batch (GS Only)",
      image: "https://sriramsias.com/admin/upload/BP240726123054.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "GS Prelims cum Mains + CSAT + Optional Pune - Off",
      image: "https://sriramsias.com/admin/upload/BP240617104438.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "GS Prelims cum Mains + CSAT + Optional Pune - On",
      image: "https://sriramsias.com/admin/upload/BP240617110217.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "General Studies Prelims cum Mains Pune-Off",
      image: "https://sriramsias.com/admin/upload/BP240617105241.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "General Studies Prelims cum Mains Pune - On",
      image: "https://sriramsias.com/admin/upload/BP240617110324.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "GS Prelims cum Mains + CSAT Pune-Off",
      image: "https://sriramsias.com/admin/upload/BP240617104759.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "GS Prelims cum Mains + CSAT Pune - On",
      image: "https://sriramsias.com/admin/upload/BP240617104838.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "GS Prelims cum Mains + Optional Pune -Off",
      image: "https://sriramsias.com/admin/upload/BP240617105345.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    {
      title: "GS Prelims cum Mains + Optional Pune - On",
      image: "https://sriramsias.com/admin/upload/BP240617105438.png",
      batchStart: "10th September 2024",
      time: "9:00AM to 11:00AM",
      language: "Hindi",
      originalPrice: "₹185000",
      discountedPrice: "₹167500"
    },
    // Add more course objects as needed
  ];

  return (
    <>
    {/* <div className="container">
        <div className='row'>
      {courses.map((course, index) => (
        <div key={index} className="col-lg-4 col-md-6 col-sm-12 p-3">
            <div className='course-card'>
          <img src={course.image} alt="Course" className="course-image" />
          <h6 className="course-title py-2">{course.title}</h6>
          <div className="course-info">
            <div><i className="fa fa-calendar"></i> Batch Starts: {course.batchStart}</div>
            <div><i className="fa fa-clock"></i> Time: {course.time}</div>
            <div><i className="fa fa-language"></i> Language: {course.language}</div>
            <div><i className="fa fa-language"></i>Price:  
            <span className="original-price">{course.originalPrice}</span>
            <span className="discounted-price">{course.discountedPrice}</span></div>
          </div>
          <div className="course-price">
            <span className="original-price">{course.originalPrice}</span>
            <span className="discounted-price">{course.discountedPrice}</span>
          </div> 
          <button className="view-details">View Details</button>
        </div>
        </div>
      ))}
      </div>
    </div> */}
    <CardsPage cards={courses} heading={civilheading} />
    </>
  );
}

export default SriramTest;
