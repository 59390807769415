import React, { useEffect, useState } from 'react';
import './Toggle.css'; // You can create a CSS file for styling
import { Link } from 'react-router-dom';
import { FaClock } from "react-icons/fa6";
// import Modal from 'react-modal';
import { Modal } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'animate.css';
import CivilModal from '../CivilModal/CivilModal';
import CivilTestToggleContent from '../CivilTestToggleContent/CivilTestToggleContent';
import axios from 'axios';
import { BASE_URL1 } from '../../../../insightConfig/insightConfig';



function Toggle({ testSeriesData }) {
    // packageid, Accesscode, Duration, MarksTotal, QuestionCount
    const [activeIndex, setActiveIndex] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [isDate, setisDate] = useState(true)

    const [testSeriesTests, setTestSeriesTests] = useState([])
    const [loadingTest, setLoadingTests] = useState(true);
    localStorage.setItem("CurrentTestSeriesTitleURL", JSON.stringify(testSeriesData.TitleHeading));
    console.log(testSeriesData)

    const storedAuthToken = localStorage.getItem('authtoken');
    useEffect(() => {
        setLoadingTests(true)
        axios.get(`${BASE_URL1}/course-test/${testSeriesData.CourseID}/${testSeriesData.Sn}/${storedAuthToken}`).then((response) => {
            console.log(response.data.data)
            setTestSeriesTests(response.data.data)
            setLoadingTests(false)
        }).catch((error) => {
            setLoadingTests(false)
            console.log(error)
        })

    }, [testSeriesData])


    console.log(testSeriesData)


    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
        },
        overlay: {
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.75)'
        },
    };
    const toggleState = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const handleTestAccessibility = (testData) => {
        console.log(testData)
        if ((testSeriesData?.isEnrolled == 'enrolled' || (testData?.Demo.toLowerCase() == 'yes' && storedAuthToken)) && testData.PublishDateNew == "Unlock" && testData.FilledTest == 1) {
            return < Link to={`/teststart/${testData.PackageID}/${testData.CourseID}`
            } state={{ testData: { ...testData, MCQRules: testSeriesData.Rules, SubjectiveRules: testSeriesData.RulesSubjective, testSeriesSn: testSeriesData.Sn } }} className='list-group-item p-1 fw-bold bg-pureorange test-btn text-white'>
                TAKE TEST
            </Link >
        } else {
            if (testSeriesData.isEnrolled != 'enrolled') {
                return < Link to='#' className='list-group-item p-1 fw-bold bg-pureorange test-btn disable text-white'>
                    TAKE TEST
                </Link >
            } else if (testData.PublishDateNew !== "Unlock") {
                return < Link to='#' className='list-group-item p-1 fw-bold bg-pureorange test-btn disable text-white'>
                    <FaClock className='mb-1 me-2' />
                    {new Date(testData.PublishDate).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })}
                </Link >
            } else {
                return < Link to='#' className='list-group-item p-1 fw-bold bg-pureorange test-btn disable text-white'>
                    TAKE TEST
                </Link >
            }
        }
    }

    if (!testSeriesTests.length && !loadingTest) {
        return <>
            <div className='card p-2 my-4 mx-2 shadow-lg'>
                <h1 className='fs-6 text-center m-2 text-danger'>There is no test exists in this test series</h1>
            </div>
        </>
    }

    if (loadingTest) {
        return <div className='card p-2 my-4 mx-2 shadow-lg'>
            <h1 className='fs-6 text-center m-2 text-info'>Loading tests...</h1>
        </div>

    }


    return (
        <>
            <div className='card p-2 my-4 mx-2 shadow-lg'>
                {testSeriesTests?.map((testData, index) => (
                    <div key={index} className="d-grid gap-1 m-1 test-togglebackground">
                        <button onClick={() => toggleState(index)} className="toggle-button py-2">
                            <div className="text-nowrap me-2">
                                {testData.Type === "MCQ" ? <h1 className='fs-6'>PTS-{testData.AccessCode}</h1> : <h1 className='fs-6'>MTS-{testData.AccessCode}</h1>}
                            </div>
                            <div className='toggleheadingContainer me-3'>
                                <h1 className='fs-6'>{testData.Title}</h1>
                            </div>
                            {
                                handleTestAccessibility(testData)
                            }
                        </button>

                        {activeIndex === index && (
                            <div className="content p-4">
                                <CivilTestToggleContent items={testData.Description || []} />
                            </div>
                        )}
                    </div>
                ))}
            </div>
            {/* <Modal show={isOpen} centered className="animate__animated animate__fadeIn mobileyoutube">
                <CivilModal closeModal={closeModal} authtoken={authtoken} BatchID={BatchID} CourseID={CourseID} payamount={payamount} />
            </Modal> */}
        </>
    );
}

export default Toggle;

