import React, { useState, useEffect } from 'react';
import "./Modal.css"
import { Link, useNavigate } from 'react-router-dom';

import logo1 from "../../../../Image/aboutimg.png";
import { BASE_URL } from '../../../../insightConfig/insightConfig';
import axios from 'axios';
import swal from 'sweetalert';
import { Modal } from 'react-bootstrap';

function CivilModal({ paymentFor, setOpen, payamount, CourseID, BatchID, authtoken, setModalBox }) {
  console.log(paymentFor, payamount, CourseID, BatchID, authtoken)

  const [userpay, setUserpay] = useState('');
  const userDetail = userpay[0]



  const navigate = useNavigate();
  // const [Razorpay] = useRazorpay();
  useEffect(() => {
    if (!authtoken) return;
    fetch(`${BASE_URL}/studentdashboard/${authtoken}`)
      .then(response => response.json())
      .then(data => {
        // Update state with the fetched data
        setUserpay(data.data);

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [authtoken]);
  console.log(paymentFor)


  const saveOrderDetails = async (amount, transactionid, status) => {
    let data = { courseid: CourseID, email: authtoken, batchid: BatchID, amount, transactionid, status }
    try {
      const response = await axios.post(`${BASE_URL}/${paymentFor == 'course' ? 'payorder' : 'payordertest'}`, data);
      if (response.data.statusCode == 200) {
        setModalBox(false)
        navigate("/paymentsuccess", {
          state: {
            transactionId: response.data.data[0].TranscationID,
            productTitle: response.data.data[0].Title,
            TitleHeading: response.data.data[0].TitleHeading,
            moduleName: paymentFor
          }
        })
      }

    } catch (error) {
      console.log(error)
    }
  }
  const verifyPayment = async (razorpay_order_id, razorpay_payment_id, razorpay_signature) => {
    const response = await axios.post(BASE_URL + '/paymentVarification', { razorpay_order_id, razorpay_payment_id, razorpay_signature });
    return response.data.success

  }

  const handlePayment = async () => {
    setOpen(false);
    const response = await axios.post(BASE_URL + '/checkout', { amount: Number(100) });
    var options = {
      key: 'rzp_live_MiLEU5w3lLxTXm', // Enter the Key ID generated from the Dashboard
      amount: response.data.order.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: "Insight Edutech Pvt...",
      description: "Test Transaction",
      image: logo1,
      order_id: response.data.order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      // callback_url: BASE_URL + '/paymentVarification',
      handler: (res) => {
        console.log(res)
        const isVerified = verifyPayment(res.razorpay_order_id, res.razorpay_payment_id, res.razorpay_signature);
        console.log('payment varification', isVerified)

        if (isVerified) {
          setModalBox(true);
          setTimeout(() => {
            saveOrderDetails(payamount, res.razorpay_payment_id, "Success");
          }, 1000)

        } else {
          swal("Invalid Payment Signature!", "Please Try Again", { icon: "error" })
        }

      },
      prefill: {
        name: userDetail?.FirstName || '',
        email: userDetail?.EmailId || '',
        contact: userDetail?.MobileNo || '',
      },
      notes: {
        address: "Razorpay Corporate Office"
      },
      theme: {
        color: "#005bf2"
      }
    };
    const rzpay = new window.Razorpay(options);
    rzpay.open();
  }

  return (
    <>
      <div className="modal-content text-center p-3">
        <div className="modal-header">
          <h5 className="modal-title">Terms & Conditions</h5>
          <button type="button" onClick={() => setOpen(false)} className='btn-close' aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <p>1. Details of a Student: Every student subscribing to any course has to fill/upload the required details on his/her 'Profile' page such as a passport size photo, ID Proof, Address proof, etc. in compliance with the KYC (Know your Customer) requirements mandated by the management of the institute and the regulatory authorities.</p>
          <Link to="/terms-and-conditions" target='_blank' className='list-group-item fw-bold py-2 text-orange'>Read More...</Link>
        </div>
        <div className="d-grid gap-2">
          <button type="button" onClick={handlePayment}/*  onClick={() => saveOrderDetails(1, "pay_Ny0x6XWDqUhxz3", "Success")} */ className="btn btn-primary py-3 my-1 fw-bold bg-pureorange proceed-btn">Proceed</button>
          {/* <button type="button" onClick={()=>handlePaymentSuccess({courseid:CourseID, batchid:BatchID, email:authtoken, amount:payamount, transactionid:"pay_Ny0x6XWDqUhxz3", status:"Success"})} className="btn btn-primary py-3 my-1 fw-bold bg-pureorange proceed-btn">Proceed</button> */}
        </div>
      </div>



    </>
  );
}

export default CivilModal;

