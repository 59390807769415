import React from 'react';
import Aboutimg from "../../Image/aboutimage.png"

function HomeAbout() {
  return (
    <>
      <div className='container-fluid bg-light'>
        <div className='container'>
            <div className='row py-5'>
                <div className='col-lg-6 col-md-6 col-sm-12'>
<div className='d-flex justify-content-center'>
    <img src={Aboutimg} style={{width: "100%"}} alt=''/>
    {/* <img src='https://sriramsias.com/wp-content/uploads/2019/12/About-Sriram-min.jpg' style={{width: "400px", height: "400px", borderRadius: "50%",}} alt=''/> */}
</div>
                </div>
                <div className='col-lg-6 col-md-6 col-sm-12'>
              <div>
                <h1 style={{fontSize: "5vmax"}} className='fw-bold'>Who We Are</h1>
                <p style={{textAlign: "justify", lineHeight: "30px"}}>We are a premier institute dedicated to training candidates appearing for Civil Services Examination. We have 35 years of experience in guiding candidates successfully to prestigious government positions.

Our faculty is predominantly in house though we do borrow services of distinguished professors and public servants (Civil and Defence).

We have developed techniques and methods for cutting short the learning curve because year on year we get to interact with tens of thousands of fine young minds.

We hesitate to brag about our record of success because much of the work is that of the students.
Rest assured our success rate has been the highest.</p>
<div className='d-flex justify-content-end w-100 p-2'>
<button className='btn btn-outline-dark rounded-pill' style={{padding: "1vmax 2vmax"}}>Know more</button>
</div>
              </div>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default HomeAbout;