import React from 'react';
import "../ReusableComponent/CIVIL/CivilModal/Modal.css";
import { Link } from 'react-router-dom/dist';
import { IoLogoAndroid } from "react-icons/io";
import { FaApple } from "react-icons/fa";

function ButtonModal({ closeModal }) {
    return (
        <>
            <div className="modal-content text-center">
                <div className="modal-header mb-2">
                    <h5 className="modal-title"></h5>
                    <button type="button" onClick={closeModal} className='btn-close' aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <div className="d-grid gap-2">
                        <Link className="btn bg-purple d-flex justify-content-center align-items-center  border-red fw-bold" to="https://play.google.com/store/apps/details?id=com.insi.insight" target='_blank' type="button"><IoLogoAndroid className='fs-4'/>ANDROID</Link>
                        <Link className="btn bg-purple border-red d-flex justify-content-center align-items-center fw-bold" to="https://apps.apple.com/in/app/insight-ias-delhi/id6449289683" target='_blank' type="button"><FaApple className='fs-5' />IOS</Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ButtonModal;