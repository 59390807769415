import React from 'react';
import "./QuestionNumber.css";

function QuestionNumber({ setSelectedQuestionNumber, selectedQuestionNumber, testQuestions, attemptedQuestions, markForReviewQuestionsArray, viewedQuestionsArray, handleFinishTest, finishTest }) {

  const numbers = new Array(testQuestions.length).fill(0).map((_, i) => i + 1);
  console.log(numbers)

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const selectBGColor = (number) => {
    if (number == selectedQuestionNumber) {
      if (attemptedQuestions?.includes(number)) {
        return 'answernumberActive'
      } else if (markForReviewQuestionsArray?.includes(number)) {
        return 'markedanswernumberActive'
      } else {
        return 'numberActive'
      }
    } else if (attemptedQuestions?.includes(number)) {
      return 'answernumberActive'
    } else if (markForReviewQuestionsArray?.includes(number)) {
      return 'markedanswernumberActive'
    } else if (viewedQuestionsArray?.includes(number)) {
      return 'noanswernumberActive'
    }
  }

  return (
    <div className='d-flex justify-content-center flex-column align-items-center' onClick={handleScrollToTop}>
      <ul className='number-list'>
        {numbers.map((number) => (
          <li className={selectBGColor(number)} key={number} onClick={() => setSelectedQuestionNumber(number)}>{number}</li>
        ))}
      </ul>
      {!finishTest && <button type="button" onClick={handleFinishTest} className="btn bg-Question-orange btn-md">FINISH</button>}
    </div>
  );
}
export default QuestionNumber;
