import React from 'react'
import cardimage from '../../Image/online-course.jpg';
import "./HomeCourse.css";
import { Link } from 'react-router-dom';

function HomeCourse() {
   return (
      <>
<div className='container-fluid bg-light p-5'>
  <h1 className='text-center' style={{ color: "#240E70" }}><b>OUR COURSES</b></h1>
  <div className='container'>
    <div className='row'>
      {[
        {
          image: "https://sriramsias.com/upload/pdf/csat-only-at-sriramsias-CP200213164252.png",
          title: "CSAT Only at SRIRAM's IAS",
          description: "In this Course, you will master the techniques to crack the CSAT with confidence.",
          link: "/course-details/csat-only-at-sriramsias"
        },
        {
          image: "https://sriramsias.com/upload/pdf/general-studies-comprehensive-course-CP200518170736.jpeg",
          title: "General Studies Comprehensive Course",
          description: "This course covers all the important subjects needed to excel in the Civil Services Exam.",
          link: "/course-details/general-studies-comprehensive-course"
        },
        {
          image: "https://sriramsias.com/upload/pdf/sriramsias-main-test-series-CP240703140526.jpeg",
          title: "SRIRAM's IAS Main Test Series",
          description: "Test yourself with our comprehensive test series to improve your IAS Main exam performance.",
          link: "/course-details/sriramsias-main-test-series"
        },
        {
          image: "https://sriramsias.com/upload/pdf/optional-subject-course-CP200518183743.jpeg",
          title: "Optional Subject Course",
          description: "Choose your optional subject wisely with our detailed courses designed for specific subjects.",
          link: "/course-details/optional-subject-course"
        },
        {
          image: "https://sriramsias.com/upload/pdf/general-studies-module-course-CP200518190919.jpeg",
          title: "General Studies Module Course",
          description: "In this Course, you can create a Module of subjects of your own choice.",
          link: "/course-details/general-studies-module-course"
        }
      ].map((course, index) => (
        <div className='col-lg-6 col-md-6 p-3' key={index}>
          <div className='d-flex align-items-center p-3' style={{ boxShadow: "0px 2px 6px rgba(0,0,0,0.2)", backgroundColor: "#fff", borderRadius: "8px" }}>
            <div className='col-lg-5'>
              <img src={course.image} style={{ width: "100%",height: "300px", borderRadius: "8px", objectFit: "cover" }} alt={course.title} />
            </div>
            <div className='col-lg-7 ps-3'>
              <h5 style={{ color: "#240E70" }}><b>{course.title}</b></h5>
              <h6 style={{ color: "green", fontSize: "14px" }}>ADMISSIONS OPEN</h6>
              <p style={{ fontSize: "14px" }}>
                {course.description}
              </p>
              <Link to={course.link} className='btn btn-primary' style={{ backgroundColor: "#240E70", borderColor: "#240E70" }}>Know More</Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
</div>
<div className='py-3 d-flex justify-content-center'>
      <button className='btn btn-outline-dark px-5 py-3'>View More</button>
    </div>


      </>
   )
}
export default HomeCourse;