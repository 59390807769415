import React, { useEffect, useState } from 'react';
import CivilButton from '../ReusableComponent/CIVIL/CivilButton/CivilButton';
import Dashboardleft2 from '../DashboardLeft/DashboardLeft2';
import { useLocation, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { fetchTestSeriesData, fetchtestData, fetchtestLessonData } from '../DynamicCourse/api';
import Loader from '../Loader/Loader';

function DynamicTestseries1({ isAuth, authtoken, setRulesubjective,  settestSn, coursepayment, coursenotpayment, ispayment, setPrevPath, settestcourseid, settestpackageid, setRule, settesttype, settestDuration, settestmarks, settestQuestion, settestAccesscode, settestTitle }) {
  const { cuetname } = useParams();
  const [testData, setTestData] = useState(null);
  const [testDataDetail, setTestDataDetail] = useState(null);
  const componentName = "civilTest";
  const location = useLocation();

  // Update prevPath whenever the route changes
  useEffect(() => {
    setPrevPath(location.pathname);
  }, [location.pathname, setPrevPath]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchtestData(cuetname, authtoken, coursepayment, coursenotpayment, ispayment);
        setTestData(data);
          setRule(data.Rules);
          localStorage.setItem('rule', data.Rules);
          setRulesubjective(data.RulesSubjective)
          localStorage.setItem('rulesubjective', data.RulesSubjective);
        // Fetch test lesson data after fetching test data
        const lessonData = await fetchTestSeriesData(data.CourseID);
        setTestDataDetail(lessonData);
        settestcourseid(data.CourseID);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [cuetname, authtoken, coursepayment, coursenotpayment, setRule, settestcourseid]);

  if (!testData || !testDataDetail) {
    return <div style={{backgroundColor: "white", zIndex: "180", width: "100%", height: "100vh", position: "fixed" , top: "0"}}><Loader/></div>;
  }

  const paragraphs = testData.Description;
  const testtoggleData = testDataDetail;

  return (
    <>
      <Helmet>
        <title>{testData.MetaTitle}</title>
        <meta name="description" content={`${testData.MetaDescription}`}></meta>
      </Helmet>
      {/* Civil Test Page Start */}
      <div className='Dashboard-background'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-3 col-md-12 col-sm-12 dashboardmain-left p-3'>
              <Dashboardleft2 testDetail={testData} authtoken={authtoken} payamount={testData.Price} CourseID={testData.CourseID} BatchID={testData.BatchId} />
            </div>
            <div className='col-lg-9 col-md-12 col-sm-12'>
              {/* Civil Button  Section Start */}
              <CivilButton authtoken={authtoken} settestSn={settestSn} isAuth={isAuth} ispayment={ispayment} CourseID={testData.CourseID} BatchID={testData.BatchId} payamount={testData.Price} componentName={componentName} paragraphs={paragraphs} testtoggleData={testtoggleData} settestpackageid={settestpackageid} settesttype={settesttype} settestDuration={settestDuration} settestmarks={settestmarks} settestQuestion={settestQuestion} settestAccesscode={settestAccesscode} settestTitle={settestTitle} />
              {/* Civil Button Foundation Section End */}
            </div>
          </div>
        </div>
      </div>
      {/* Civil Test Page End */}
    </>
  )
}

export default DynamicTestseries1;
