import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import "../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection.css";
import CivilTopSection from '../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection';
import userImage from "../../Image/user.png";
import CivilrightSection from '../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection';
import Content from '../AllPageContent/AllPageContent';
import ButtonPage from '../ButtonPage/ButtonPage';
import CurrentAffair from '../CurrentAffair/CurrentAffair';
import { useParams, useNavigate } from 'react-router-dom';
import { fetchaboutdetail } from '../DynamicCourse/api';
import Loader from '../Loader/Loader';

function Dynamicabout() { 
  const Buttonname = "Multiplebutton";
  const componentName = "currentaffairs";
  const [info, setInfo] = useState(null);
  const { abouturl } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
            try {
                const data = await fetchaboutdetail(abouturl);
                setInfo(data[0]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
    };

    fetchData();
}, [abouturl]);

if (!info) {
    return <div style={{backgroundColor: "white", zIndex: "180", width: "100%", height: "100vh", position: "fixed" , top: "0"}}><Loader/></div>
}

const SudeoDateClick = (date) => {
  if (date) {
      // setTimeout(()=>{
      // handleDateinput(date)
      // },1000)
      // setTimeout(()=>{
      navigate('/daily-current-affairs/News');
      // },2000)
  }
}

  // Banner Content For CivilAbout Page
  const data = {
    academyName: info.Title,
    userImage: userImage,
    userName: info.WrittenBy,
    Image: info.Image
  };

 const paragraphs = info.Description
  const aboutstyle = {
    before: {
        content: '""',
        width: "100%",
        backgroundImage: `url(${info.Image})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        opacity: "0.7",
        position: "absolute",
        filter: "blur(50px)",
        zIndex: "1",
    }
  }
 
  return (
    <>
      <Helmet>
        <title>{info.MetaTitle}</title>
        <meta name="description" content={`${info.MetaDescription}`}></meta>
        <meta name="keywords" content={`${info.Tag}`}></meta>
      </Helmet>
      {/* Civil About Section Start */}
      <section className='dynamicabout'>
      <section style={aboutstyle.before} className='dynamicabout-before' />
        {/* Civil Top Section Start */}
        <CivilTopSection
          academyName={data.academyName}
          userImage={data.userImage}
          userName={data.userName}
          Image={data.Image}
        />
        {/* Civil Top Section End */}
      </section>
      <div className='Civil-section'>
        <div className='civil-row'>
          <div className='col-lg-8 col-md-12 col-sm-12 me-2'>
            {/* Content Component Start */}
            <Content paragraphs={paragraphs} />
            {/* Content Component End */}
            <ButtonPage Pagename={Buttonname} />
          </div>
          {/* Civil right Section Start */}
          <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} onDateSelect={SudeoDateClick}/>
              </div>
          </div>
          {/* Civil right Section End */}
        </div>
      </div>
      {/* Civil About Section End */}
    </>
  )
}

export default Dynamicabout;