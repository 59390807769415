import React, { useEffect, useState, useRef } from 'react';
import "../../CIVIL/CivilButton/CivilButton.css";
import userImg from "../../../../Image/user.png";
import { FaTelegramPlane } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";
import { MdDelete } from "react-icons/md";
import { useAlert } from 'react-alert';
import { IoCloudUploadSharp } from "react-icons/io5";
import pdfImg from "../../../../Image/pdfphoto.png"
// import { toast } from 'react-toastify';
import axios from "axios";
import { Link } from 'react-router-dom';
import { BASE_URL, BASE_URL1 } from '../../../../insightConfig/insightConfig';
import { saveAs } from 'file-saver';
import { Spinner } from 'react-bootstrap';
import { TiTick } from "react-icons/ti";
import { LuMessageCircle } from "react-icons/lu";

function CivilPop({ isEnrolled, sectionid, iscourseid, title, isteacher, isupload, ctype1, ctype2 }) {
  const [userData, setUserData] = useState([]);
  const [reviewsData, setreviewsData] = useState(null);
  const [reviewsData1, setreviewsData1] = useState(null);
  const [CommentTitle, setCommentTittle] = useState('');
  const [EditCommentTitle, setEditCommentTittle] = useState('');
  const [uploadedFile, setuploadedFile] = useState('')
  const [selectedFile, setSelectedFile] = useState(null);
  const [Editreply, setEditreply] = useState(null);
  const [error, setError] = useState('');
  const alert = useAlert();
  const [reply, setreply] = useState(false);
  const [uploading, setuploading] = useState(0)

  const isLoggedIn = localStorage.getItem('isLoggedIn');
  const authtoken = localStorage.getItem('authtoken');


  // const handleFileChange = (e) => {
  //    (e.target.value, e.target.name)
  //   handleChangeCallBack(e.target.name, e.target.value)
  // };

  // const handleEditQuestion = () => {
  //   setreply(true)
  // }

  const editreply = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/updateTeacherSupport/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ CommentTitle: EditCommentTitle }),
      });

      if (!response.ok) {
        throw new Error('message failed');
      }
      setEditCommentTittle('')
      setreply(false)
      // Reset form fields and state
      // Show login success message
      alert.show('message Editing successfully');
      fetchData();
    } catch (error) {
      alert.show('message failed. Please check your credentials.');
      setError('message failed. Please check your credentials.');
    }
  }

  const handleEditQuestion = (id, title) => {
    setreply(!reply)
    setEditreply(id)
    setEditCommentTittle(title)
    //  edit(id)
  };

  const fileInputRef = useRef(null);
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    setuploading(20)
  };

  useEffect(() => {
    const uploadFile = async () => {
      setuploading(40)
      if (selectedFile) {
        const formData = new FormData();
        formData.append("uploadedFile", selectedFile);

        try {
          const result = await axios.post(`${BASE_URL1}/uploadFile`, formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          const uploadedFilename = result.data.imagePath;
          setuploadedFile(uploadedFilename);
          setuploading(60)
          alert.show("File uploaded successfully. Click the Submit button.");
        } catch (error) {
          console.error('Error uploading file:', error);
        }
      }
    };

    uploadFile();
  }, [selectedFile]);




  const convertTimestamp = (originalTimestamp) => {
    const originalDate = new Date(originalTimestamp)
    const istDate = new Date(originalDate.getTime() - (5.5 * 60 * 60 * 1000));
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      timeZone: 'Asia/Kolkata',
      month: 'long',
      day: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',

    }).format(istDate);
    return formattedDate;
  }

  const fetchData = async () => {
    try {
      if (ctype1) {
        const response = await fetch(`${BASE_URL}/teachersupport/${iscourseid}/${ctype1}/${authtoken}`);
        const data = await response.json();
        setreviewsData(data.data);
      } else {
        const response = await fetch(`${BASE_URL}/teachersupport/${iscourseid}/${ctype2}/${authtoken}`);
        const data = await response.json();
        setreviewsData1(data.data);
      }

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // Fetch data from API
    fetch(`${BASE_URL}/studentdashboard/${authtoken}`)
      .then(response => response.json())
      .then(data => {
        // Update state with the fetched data
        setUserData(data.data);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

    // if(dhandle <= 0){
    //    ("i am working")
    //   setispayment(true)
    // }
  }, []);

  const handleSubmit = async () => {
    // if (!CommentTitle.trim()) {
    //   setError('message is required');
    //   return;
    // }
    setuploading(75)
    try {
      if (CommentTitle || uploadedFile) {
        const response = await fetch(`${BASE_URL}/teachersupportp/${authtoken}`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ CommentTitle, uploadedFile, ctype: ctype1 || ctype2, Title: title, Category: title, SectionID: sectionid, CourseID: iscourseid }),
        });
        if (!response.ok) {
          throw new Error('message failed');
        }

      }
      else {
        // if(CommentTitle){
        //   return alert.show('Please write a Query');;

        return alert.show('Please check your response')
        //   const response = await fetch(`${BASE_URL}/teachersupportp/${authtoken}`, {
        //     method: 'POST',
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //     body: JSON.stringify({ CommentTitle, uploadedFile, ctype: ctype1 || ctype2, Title: reviewtitle, Category: reviewtitle, SectionID: sectionid, CourseID: iscourseid }),
        //   });
        //   if (!response.ok) {
        //     throw new Error('message failed');
        //   }
      }


      // Reset form fields and state
      setCommentTittle('');
      if (selectedFile) {
        setSelectedFile(null);
        fileInputRef.current.value = null;
        setuploadedFile('');
      }
      setuploading(100)
      setTimeout(() => {
        setuploading(0) // Clear the message after 3 seconds
      }, 3000);
      // Show success message
      alert.show('Message sent successfully');
      fetchData();
    } catch (error) {
      // Handle error
      console.error('Error submitting message:', error);
      setError('message failed. Please check your credentials.');
      alert.show('Message failed. Please check your credentials.');
    }
  };



  const handledelete = async (id) => {
    try {
      const response = await fetch(`${BASE_URL}/teachersupportd/${id}/${authtoken}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ id, userCode: authtoken }),
      });

      if (!response.ok) {
        throw new Error('message failed');
      }
      // Reset form fields and state
      // Show login success message
      alert.show('message deleting successfully');
      fetchData();
    } catch (error) {
      alert.show('message failed. Please check your credentials.');
      setError('message failed. Please check your credentials.');
    }
  };

  useEffect(() => {
    fetchData();
    // setIsPayment(true)
  }, [])
  if (!reviewsData && !userData) {
    return <div>Loading...</div>
  }

  const renderhtml1 = (paragraphs) => {
    return <span dangerouslySetInnerHTML={{ __html: paragraphs }} />;
  };

  const handleDownload = (fileUrl, fileName) => {
    saveAs(fileUrl, fileName);
  };

  const setIndicator = (selectedFile, uploadedFile) => {
    if (selectedFile && uploadedFile) {
      return <TiTick size={40} color='green' className='mt-1' />
    } else if (selectedFile && !uploadedFile) {
      return <Spinner animation="border" variant="info" className='mt-1' />
    } else {
      return null;
    }
  }
  console.log(isLoggedIn)
  return (
    <>
      {isLoggedIn && isEnrolled == 'enrolled' ?
        <>
          {isteacher && <div className="card bg-skyblue text-center mt-4">
            <div className="card-body d-flex justify-content-between">
              <div className='pop-img d-flex justify-content-start'>
                <img src={userImg} alt='' className='pop-user-img' />
              </div>
              <div className="form-floating pop-input1">
                <div className='pop-input d-flex align-items-end flex-column'>
                  <textarea className="form-control" placeholder="Type your query here..." id="floatingTextarea2" value={CommentTitle} onChange={(e) => setCommentTittle(e.target.value)} style={{ height: "200px", width: "100%" }}></textarea>
                  <div className="d-flex justify-content-end mt-3">
                    <button className="btn btn-darkpurple px-5" onClick={handleSubmit} type="button"><FaTelegramPlane /> SUBMIT</button>
                  </div>
                </div>
              </div>
            </div>
          </div>}
          {isupload && <div className="card bg-skyblue text-center mt-4">
            <div className="card-body d-flex align-items-center">
              <div className='pop-img col-1 d-flex justify-content-start'>
                <img src={userImg} alt='' className='pop-user-img' />
              </div>
              <div className="form-floating pop-input">
                <div className="d-flex">
                  <IoCloudUploadSharp className='btn-upload-icon' />
                  <input type="file" className="form-control btn-input" accept='application/pdf' required name="image" id="inputGroupFile01" ref={fileInputRef} onChange={handleFileChange} />
                  {/* {uploadedFile ? <button className="btn btn-darkpurple px-5 py-2" onClick={handleSubmit} type="button"><FaTelegramPlane /> SUBMIT</button> : <button className="btn btn-darkpurple px-5 py-2" onClick={handleClick} type="button">Upload</button>} */}

                  {selectedFile && <span className='col-1 justify-content-start'>{setIndicator(selectedFile, uploadedFile)}</span>}

                  {<button className="btn btn-darkpurple px-5 py-2 ms-3" onClick={handleSubmit} type="button"><FaTelegramPlane /> SUBMIT</button>}
                </div>
              </div>
            </div>

            {/* {uploadedFile && <div className={uploadedFile ? 'file-btn': ''}>                
                  <button className="btn px-5 py-0 text-white" type="button"></button>
                  </div>} */}
          </div>}
          {isteacher && <div className='reviewsData-background' >
            {reviewsData && reviewsData.map((item, index) => (
              <div className='reviewsCard mb-3' key={index}>
                <div className='reviewsImg'>
                  <img src={userImg} alt='' className='pop-user-img' />
                </div>
                <div className='reviewsInfo'>
                  {userData && userData[0] && userData[0].FirstName && <h5>{`${userData[0].FirstName} | ${convertTimestamp(item.PostDate)} | ${item.Title}`}</h5>}
                  {/* {<p><span className='fw-bold'></span>{item.CommentTitle}</p>} */}
                  {/* <div className='bg-danger' style={{width: "20%!important", overflow: "hidden!important"}}>
                  <div className="form-floating pop-input1">
                    <div className='pop-input d-flex'>
                      <textarea className="form-control" placeholder="Type your query here..." id="floatingTextarea2" value={CommentTitle} onChange={(e) => setCommentTittle(e.target.value)} style={{ height: "200px", width: "70%" }}></textarea>
                    </div>
                  </div>
                  </div> */}
                  {reply && Editreply === item.Sn && <div className='pb-3'>
                    <textarea className="form-control" placeholder="Teacher Reply here..." id="floatingTextarea2" value={EditCommentTitle} onChange={(e) => setEditCommentTittle(e.target.value)} style={{ height: "200px" }}></textarea>
                  </div>}


                  {<p className='my-2 d-flex'><span className='fw-bold'></span>{renderhtml1(item.CommentTitle)}</p>}
                  <div className='reviewsbtn'>
                    {item.TeacherReply && <button type="button" onClick={() => handleEditQuestion(item.Sn, item.CommentTitle)} className="btn btn-success btn-sm me-2"><LuMessageCircle className='fs-5 mb-1' /> 1</button>}
                    <button type="button" onClick={() => handleEditQuestion(item.Sn, item.CommentTitle)} className="btn btn-success btn-sm me-2"><FiEdit className='fs-5 mb-1' /> Edit</button>
                    <button type="button" onClick={() => handledelete(item.Sn)} className="btn btn-danger btn-sm"><MdDelete className='fs-5 mb-1' /> DELETE</button>
                    {reply && Editreply === item.Sn && <button type="button" onClick={() => editreply(item.Sn)} className="btn btn-success btn-darkpurple border-0 btn-sm ms-2"><FaTelegramPlane className='fs-5 mb-1' /> SUBMIT</button>}
                  </div>
                  {item.TeacherReply && <div className='py-3 d-flex'>
                    <div className='reviewsImg'>
                      <img src={userImg} alt='' className='pop-user-img' />
                    </div>
                    <div className='reviewsInfo'>
                      {item.FacultyName && <h5>{`${item.FacultyName} | ${convertTimestamp(item.PostDate)} | ${item.Title}`}</h5>}
                      {item.TeacherReply && <p className='my-2 d-flex'><span className='fw-bold'></span>{renderhtml1(item.TeacherReply)}</p>}
                      <div className='reviewsbtn'>
                        {/* <button type="button" onClick={() => handleEditQuestion(item.Sn, item.CommentTitle)} className="btn btn-success btn-sm me-2"><FiEdit className='fs-5 mb-1' /> Edit</button> */}
                        {/* <button type="button" onClick={() => handledelete(item.Sn)} className="btn btn-danger btn-sm"><MdDelete className='fs-5 mb-1' /> DELETE</button> */}
                        {/* {reply && Editreply === item.Sn && <button type="button" onClick={() => editreply(item.Sn)} className="btn btn-success btn-darkpurple border-0 btn-sm ms-2"><FaTelegramPlane className='fs-5 mb-1' /> SUBMIT</button>} */}
                      </div>
                    </div>
                  </div>}

                </div>
              </div>
            ))}
          </div>}
          {isupload && <div className='reviewsData-background' >
            {reviewsData1 && reviewsData1.map((item, index) => (
              <div className='reviewsCard mb-3' key={index}>
                <div className='reviewsImg'>
                  <img src={userImg} alt='' className='pop-user-img' />
                </div>
                <div className='reviewsInfo'>
                  {userData && userData[0] && userData[0].FirstName && <h5>{`${userData[0].FirstName} | ${convertTimestamp(item.PostDate)} | ${item.Title}`}</h5>}
                  <div className='reviewsbtn d-flex'>
                    <div className='p-2 d-flex flex-column justify-content-center align-itmes-center'>
                      <div className='w-90 d-flex justify-content-center'>
                        {<button style={{border:"white"}} className='reviewslink' onClick={() => window.open(item.StudentFile, '_blank')}>
                          <img src={pdfImg} alt='' />
                        </button>}
                      </div>
                      <button type="button"  className="btn bg-Question-white px-4 btn-sm" onClick={() => window.open(item.StudentFile, '_blank')}>Your File</button>
                    </div>
                    {item.TeacherUploadedFile !== null && <div className='p-2 d-flex flex-column justify-content-center align-itmes-center'>
                      <div className='w-90 d-flex justify-content-center'>
                        {<button style={{ border: "white" }} className='reviewslink' onClick={() => window.open(item.StudentFile, '_blank')}>
                          <img src={pdfImg} alt='' />
                        </button>}
                      </div>
                      <button type="button"  className="btn bg-Question-white px-4 btn-sm" onClick={() => window.open(item.TeacherUploadedFile, '_blank')}>Teacher File</button>
                    </div>}
                    {/* <button type="button" onClick={() => handledelete(item.Sn)} className="btn btn-danger btn-sm"><MdDelete className='fs-5 mb-1' /> DELETE</button> */}
                  </div>
                  <div className='reviewsbtn d-flex justify-content-end'>
                    {/* <button type="button" className="btn btn-success btn-sm me-2"><FiEdit className='fs-5 mb-1' /> REPLY</button> */}
                    <button type="button" onClick={() => handledelete(item.Sn)} className="btn btn-danger btn-sm"><MdDelete className='fs-5 mb-1' /> DELETE</button>
                  </div>
                </div>
              </div>
            ))}
          </div>}
        </>
        :
        <div className="card bg-skyblue py-1 text-center m-2">
          <div className="card-body">
            <h5 className="card-title fs-4 fw-bold">ONLY FOR ENROLLED STUDENTS</h5>
          </div>
        </div>
      }
    </>
  )
}

export default CivilPop;