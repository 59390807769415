import React from 'react';
import "../Dashborad/dashboard.css";
import userImage from "../../Image/user.png";
import { HiOutlineBookOpen } from "react-icons/hi";

function Dashboardleft({ authtoken, userDtails }) {
    let defaultAccessCode = (Math.random() * 1000000)
    return (
        <>
            <div className='dashboardleft-background'>
                <div className='dash1'>
                    <img src={userDtails.ProfileImage ? userDtails.ProfileImage : userImage} alt={userDtails.FirstName} className='dash1-img' />
                    {/* <h6>{authtoken}</h6> */}
                    <h6>{userDtails.FirstName}</h6>
                    <p><HiOutlineBookOpen className='me-2' />Code: {`${userDtails.AccessCode || Math.round(defaultAccessCode)}`}</p>
                </div>
                <div className='dash2'>
                    <div className="d-grid gap-2">
                        <button className="btn btn-subdash" type="button">SUBSCRIPTIONS</button>
                        <button className="btn btn-perdash" type="button">PERFORMANCE</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Dashboardleft;