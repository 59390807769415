import React from 'react';
import { Helmet } from 'react-helmet-async';
import "../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection.css";
import CivilTopSection from '../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection';
import userImage from "../../../Image/user.png";
import aboutImage from "../../../Image/aboutbuilding.jpg";
import CivilrightSection from '../../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection';
import Content from '../../AllPageContent/AllPageContent';
import ButtonPage from '../../ButtonPage/ButtonPage';
import CurrentAffair from '../../CurrentAffair/CurrentAffair';

function Interview() { 
  const Buttonname = "Multiplebutton";
  const componentName = "currentaffairs";

  // Banner Content For CivilAbout Page
  const data = {
    academyName: 'Interview',
    userImage: userImage,
    userName: 'Priya Rathore',
    Image: aboutImage
  };

  // Content For CivilAbout Page
  const paragraphstatic = [
    {
      text: "To assist the candidates in their preparation for the Interview, we conduct the Personality Test Guidance Programme within a week after the Main results are announced. To take care of the unique requirements of the personality tests, we are offering the following bouquet of services -",
      styles: [
        { word: 'Founded by SRIRANGAM SRIRAM more than 3 decades ago, SRIRAM’s IAS has emerged as one of the leading institutes in India with the vision to be the Centre of Excellence and the mission to develop knowledgeable and innovative leaders.', style: 'font-weight: bold; color: #26156d;' },
        { word: 'SRIRAM’s IAS', style: 'font-weight: bold; color: red;' }
      ],
      lists: [
        " 4 Hour Daily Personal Sessions with Mr. Sriram, who has been guiding Civil Service aspirants for over 35 years. It will include Group Mocks, Personal Sessions, Daily Current Affairs discussion, fine tuning attitude, DAF analysis and much more. Whatsapp Access to Mr. Sriram round the clock, to help you be updated with the Current Affairs and help you deal with them.",
        "4 Member Interview Mock Panel consisting of Retired and Serving Civil Servants & Senior Academicians. These are some of the panelists this year -",
        "Classes are held for Personality Test by serving Civil Servants on how to face the Interview Board and Ace it."
      ]
    }
  ];
 
  return (
    <>
      <Helmet>
        <title>About Us | Insight IAS, Delhi | Best IAS Coaching in Delhi</title>
        <meta name="description" content="Insight Academy Delhi, is the leading platform in India that provides coaching for candidates preparing for UPSC, IAS, CUET, NEET, UGC-NET examinations."></meta>
        <meta name="keywords" content="about insight ias, about us, in sight ias, insight ias, Baliyan Sir, balyan sir, baliyan sir history, history optional by baliyan sir, baliyan sir notes, history notes, insight ias delhi, insight ias india, insightsonindia, insights IAS, insight delhi, insight ias test series, insight test series, insight ias main test series, insight prelims test series, upsc coaching in delhi, civil services coaching in delhi, neet coaching center in delhi, ugc net coaching in delhi, CUET coaching in delhi, CUET form 2022, how to apply for CUET, CUET 2022, CUET Exam, Pattern of CUET Exam, Syllabus of CUET Exam, NEET Practice Tests."></meta>
        <meta name="subject" content="Insight Academy Delhi, is the leading platform in India that provides coaching for candidates preparing for UPSC, IAS, CUET, NEET, UGC-NET examinations."></meta>
      </Helmet>
      {/* Civil About Section Start */}
      <section className='civilabout'>
        {/* Civil Top Section Start */}
        <CivilTopSection
          academyName={data.academyName}
          userImage={data.userImage}
          userName={data.userName}
          Image={data.Image}
        />
        {/* Civil Top Section End */}
      </section>
      <div className='Civil-section'>
        <div className='civil-row'>
          <div className='col-lg-12 col-md-12 col-sm-12 me-2'>
            {/* Content Component Start */}
            <Content paragraphstatic={paragraphstatic} />
            {/* Content Component End */}
            {/* <ButtonPage Pagename={Buttonname} /> */}
            {/* <div className='continer-fluid'> */}
              {/* <div className='row'>
                <div className='col-lg-6 p-3'>
                    <div className='card p-4 pb-5' style={{background: "#26156d", color: "white"}}>
                      <h1 className='fw-bold'>OUR VISION</h1>
                      <p>To be the Center of Excellence to ensure our students are successful and that achieve their aspirations within a time frame.</p>
                    </div>
                </div>
                <div className='col-lg-6 p-3'>
                  <div className='card p-4' style={{background: "#db261f", color: "white"}}>
                    <h1 className='fw-bold'>OUR MISSION</h1>
                    <p>To empower aspirants of Civil Services to excel by Imparting to them right and relevant information and perspective. The aim is to help them acquire appropriate analytical skills to be on their own.</p>
                  </div>
                </div>
              </div> */}
            {/* </div> */}
            {/* <div className='container'> */}
              {/*  */}

              <div className='container-fluid'>
                <div className='row'>
                  <div className='col-lg-4 col-md-6 col-sm-12 p-4'>
                    <div className='d-flex flex-column align-items-center text-center'>
                      <img src='https://sriramsias.com/images/interview_pdt_ji.jpg' style={{borderRadius: "50%"}} alt=''/>
                      <b>Mr. PDT Achary</b>
                      <p>Former Lok Sabha Secretary General</p>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12 p-4'>
                  <div className='d-flex flex-column align-items-center text-center'>
                      <img src='https://sriramsias.com/images/interview_ravikant_ji.jpg' style={{borderRadius: "50%"}} alt=''/>
                      <b>Dr. M.Ravi Kanth</b>
                      <p>IAS (Retd.), Ex- HUDCO Chairman</p>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12 p-4'>
                  <div className='d-flex flex-column align-items-center text-center'>
                      <img src='https://sriramsias.com/images/interview_yogender_ji.jpg' style={{borderRadius: "50%"}} alt=''/>
                      <b>Mr. Yogendra Kumar</b>
                      <p>Former Ambassador and Author</p>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12 p-4'>
                  <div className='d-flex flex-column align-items-center text-center'>
                      <img src='https://sriramsias.com/images/interview_anil_ji.jpg' style={{borderRadius: "50%"}} alt=''/>
                      <b>Mr. Anil Trigunayat</b>
                      <p>Former Ambassador</p>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12 p-4'>
                  <div className='d-flex flex-column align-items-center text-center'>
                      <img src='https://sriramsias.com/images/interview_mishra_ji.jpg' style={{borderRadius: "50%"}} alt=''/>
                      <b>Mr. O.P. Mishra</b>
                      <p>Jt. Commissioner of Police, Delhi</p>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12 p-4'>
                  <div className='d-flex flex-column align-items-center text-center'>
                      <img src='https://sriramsias.com/images/interview_pasha_ji.jpg' style={{borderRadius: "50%"}} alt=''/>
                      <b>Mr. A.K. Pasha</b>
                      <p>Professor (Retd) JNU</p>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12 p-4'>
                  <div className='d-flex flex-column align-items-center text-center'>
                      <img src='https://sriramsias.com/images/interview_kapilkak_ji.jpg' style={{borderRadius: "50%"}} alt=''/>
                      <b>Mr. Kapil Kak</b>
                      <p>Former Air Vice Marshal</p>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12 p-4'>
                  <div className='d-flex flex-column align-items-center text-center'>
                      <img src='https://sriramsias.com/images/interview_prasad_ji.jpg' style={{borderRadius: "50%"}} alt=''/>
                      <b>Dr. A.J.V Prasad</b>
                      <p>Addl. Chief Secretary (Retd.)</p>
                    </div>
                  </div>
                  <div className='col-lg-4 col-md-6 col-sm-12 p-4'>
                  <div className='d-flex flex-column align-items-center text-center'>
                      <img src='https://sriramsias.com/images/interview_mohanti_ji.jpg' style={{borderRadius: "50%"}} alt=''/>
                      <b>Dr. S.M. Mohanty</b>
                      <p>Former Secretary, GOI</p>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          {/* Civil right Section Start */}
          {/* <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} />
              </div>
          </div> */}
          {/* Civil right Section End */}
        </div>
      </div>
      {/* Civil About Section End */}
    </>
  )
}

export default Interview;