import React from 'react'
import "./Questionsummary.css"
import Questionnumber from '../Questionnumber/Questionnumber'

function Questionsummary({ attemptedQuestions, markForReviewQuestionsArray, testQuestions, viewedQuestionsArray, setSelectedQuestionNumber, selectedQuestionNumber, handleFinishTest, finishTest }) {
  return (
    <>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6 col-md-12 col-sm-12 p-2'>
            <div className='bg-whites d-flex flex-column align-items-center justify-content-center py-3'>
              <h6 className='bg-text-whites p-3 rounded-circle d-flex justify-content-center align-items-center' style={{ width: "50px" }}>{testQuestions?.length - viewedQuestionsArray?.length}</h6>
              <p>Not Visited</p>
            </div>
          </div>
          <div className='col-lg-6 col-md-12 col-sm-12 p-2'>
            <div className='bg-lightorange d-flex flex-column align-items-center justify-content-center py-3'>
              <h6 className='bg-text-lightorange p-3 rounded-circle d-flex justify-content-center align-items-center' style={{ width: "50px" }}>{testQuestions?.length - attemptedQuestions?.length}</h6>
              <p>Not Answered</p>
            </div>
          </div>
          <div className='col-lg-6 col-md-12 col-sm-12 p-2'>
            <div className='bg-lightgreen d-flex flex-column align-items-center justify-content-center py-3'>
              <h6 className='bg-text-lightgreen p-3 rounded-circle d-flex justify-content-center align-items-center' style={{ width: "50px" }}>{attemptedQuestions?.length}</h6>
              <p>Answered</p>
            </div>
          </div>
          <div className='col-lg-6 col-md-12 col-sm-12 p-2'>
            <div className='bg-lightpurple d-flex flex-column align-items-center justify-content-center py-3'>
              <h6 className='bg-text-lightpurple p-3 rounded-circle d-flex justify-content-center align-items-center' style={{ width: "50px" }}>{markForReviewQuestionsArray?.length}</h6>
              <p>Marked For Review</p>
            </div>
          </div>
        </div>
      </div>
      <Questionnumber setSelectedQuestionNumber={setSelectedQuestionNumber} selectedQuestionNumber={selectedQuestionNumber} testQuestions={testQuestions} attemptedQuestions={attemptedQuestions} markForReviewQuestionsArray={markForReviewQuestionsArray} viewedQuestionsArray={viewedQuestionsArray} handleFinishTest={handleFinishTest} finishTest={finishTest }/>
    </>
  )
}

export default Questionsummary
