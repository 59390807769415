import React, { useState, useEffect, useRef } from 'react';
// E: \Tech - Xtend\InsightFrontend\Raaz_Work_Insight\src\jsx\QuizComponent\QuestionLayout\QuestionLayout.css
import "./Questionpreview.css";

import { BASE_URL, BASE_URL1 } from '../../../insightConfig/insightConfig';
import ResultPreview from '../../ResultPreview/ResultPreview';
import { FiClock } from "react-icons/fi";
import { ImCross } from "react-icons/im";
import { FaCheck } from "react-icons/fa";
import { RxCross2 } from "react-icons/rx";
import axios from 'axios';


function Questionpreview2({ loadingQuestions, quizQuestions, selectedSection, selectedSubject, setVisitedQuestionIDsArray, setCurrentQuestion, suggestedQuestion, setSuggestedQuestion, setNewSubject, selectedSectionIndex, setSelectedSectionIndex, sectionsLength }) {
  //console.log(notVisitedQuestions)
  console.log(selectedSectionIndex, sectionsLength)

  const [selectedQuestion, setSelectedQuestion] = useState({})
  const [loadNextQuestion, setLoadNextQuestion] = useState(false)
  const [visitedQuestionsIDs, setVisitedQuestionsIDs] = useState([])
  const [selectedQuestionNumber, setSelectedQuestionNumber] = useState(1);
  const [selectedOption, setSelectedOption] = useState('')
  const [showDescription, setShowDescription] = useState(false)
  const uniqueUserId = localStorage.getItem('uniqueUserId');
  // console.log(visitedQuestionsIDs)
  //for getting previous question
  const prevStateRef = useRef();
  // useEffect(() => {
  //   prevStateRef.current = selectedQuestion;
  // }, [selectedQuestion])


  // console.log(prevStateRef, selectedQuestion)

  const markQuestionAsVisitedInDB = async (questionSn) => {
    setVisitedQuestionIDsArray((prev) => [...prev, questionSn])
    try {
      await axios.put(`${BASE_URL1}/markQuestionAsVisitedInDB/${uniqueUserId}/${questionSn}`)
    } catch (error) {
      console.error('Error ', error);
    }
  }

  // console.log(notVisitedQuestions)
  useEffect(() => {
    prevStateRef.current = selectedQuestion;
    let selected;
    if (suggestedQuestion?.Sn) {
      console.log('currentQuestion', suggestedQuestion)
      selected = suggestedQuestion;
    } else {
      console.log('next block')
      let notVisitedQuestions = quizQuestions.filter((question) => !visitedQuestionsIDs.includes(question.Sn))
      console.table(selectedSectionIndex, sectionsLength)
      if (!notVisitedQuestions.length) {
        setNewSubject();
        return
        // if (selectedSectionIndex < sectionsLength) {
        //   setSelectedSectionIndex(selectedSectionIndex + 1);
        // } else {
        //   setNewSubject();
        // }
        // return
      }
      selected = notVisitedQuestions[Math.floor(Math.random() * notVisitedQuestions?.length)]
    }
    if (selected?.Sn) {
      setSelectedQuestion(selected);
      setCurrentQuestion(selected)
      setVisitedQuestionsIDs((prev) => [...prev, selected?.Sn])
      markQuestionAsVisitedInDB(selected?.Sn)
    }

    setSelectedOption('')

  }, [selectedSection, selectedSubject, loadingQuestions, loadNextQuestion, suggestedQuestion])

  // useEffect(() => {
  //   console.log('enetr in use Effect')
  //   if (selectedQuestionNumber && quizQuestions) {
  //     let selectedQuestion = quizQuestions[selectedQuestionNumber - 1]
  //     setSelectedQuestion((preData) => ({ ...preData, ...selectedQuestion }))
  //     if (selectedQuestion?.Sn) {
  //       markQuestionAsVisitedInDB(selectedQuestion?.Sn)
  //     }
  //   }
  //   setSelectedOption('')
  // }, [selectedQuestionNumber, quizQuestions])

  // console.log(selectedQuestionNumber)

  const renderhtml = (paragraphs) => {
    return <i className='renderhtml' dangerouslySetInnerHTML={{ __html: paragraphs }} />;
  };

  // const handleexplain = ()=>{
  //   setExplain(!Explain)
  // }

  console.log(selectedQuestion)


  console.log(selectedQuestion?.Answer, selectedOption)



  const checkCondition = (optionName) => {
    console.log(optionName)
    if (!selectedOption || selectedOption != optionName) return ' p-0 bg-light ps-2 pe-2 ';

    if (selectedOption == optionName) {
      if (selectedOption === selectedQuestion?.Answer) return 'isgreen p-0 bg-light ps-2 pe-2'
      return 'isred p-0 bg-light ps-2 pe-2'
    }
  }

  const selectStatement = (correctAnswer) => {
    if (correctAnswer == 'A') return 1;
    if (correctAnswer == 'B') return 2;
    if (correctAnswer == 'C') return 3;
    return 4;
  }

  const style = {
    color: '#c72323', /* Red color */
    fontFamily: 'Arial, sans-serif', /* Font family */
    fontSize: '18px', /* Font size */
    textAlign: 'center', /* Center alignment */
    backgroundColor: '#f8f8f8', /* Light gray background */
    padding: '10px', /* Padding around the text */
    border: '1px solid #ddd', /* Light gray border */
    borderRadius: '5px', /* Rounded corners */
  };

  const handlePrevQuestion = () => {
    setCurrentQuestion(prevStateRef.current)
    setSelectedQuestion(prevStateRef.current)
  }
  const handleNextQuestion = () => {
    setLoadNextQuestion(!loadNextQuestion);
    setSuggestedQuestion({})
  }

  return (

    <>
      <div style={{ backgroundColor: '#f7e8e3' }}>
        <h5 className='text-danger fw-bold p-2'> <span className='me-2'>{selectedSubject}</span> &rarr;<span className='ms-2'>{selectedSection}</span> </h5>
      </div>
      {
        quizQuestions.length ?
          <div className={(selectedOption && selectedOption === selectedQuestion?.Answer) ? 'card mb-3 questionpreviewpadding winner-list' : 'card mb-3 questionpreviewpadding'} style={{ boxShadow: "0 3px 10px rgba(0,0,0,0.3)" }}>

            <p className='mb-0'><b>Q. {renderhtml(selectedQuestion?.Question)}</b></p>
            <ul className='answer-list mt-0 pt-0'>
              <li className={checkCondition('A')} onClick={() => setSelectedOption('A')} >
                <div className='col-lg-11 d-flex align-items-center'>
                  <input
                    type="radio"
                    className='me-2 mb-1'
                    name='option'
                    style={{ width: "20px", height: "20px" }}
                    id={`option_1`}
                    checked={selectedOption?.includes('A')}

                  />
                  <label className='pt-3' htmlFor={`option_1`}>
                    <b className='d-flex'>(A) {renderhtml(selectedQuestion?.OptionA)}</b>
                  </label>
                </div>
                <div className='col-lg-1 d-flex justify-content-end'>
                  {(selectedOption && selectedOption != selectedQuestion?.Answer && selectedOption === 'A') && (
                    <span className="icon">
                      <ImCross />
                    </span>
                  )}
                  {(selectedOption && selectedOption === selectedQuestion?.Answer && selectedOption === 'A') && (
                    <span className="icon">
                      <FaCheck />
                    </span>
                  )}
                </div>
              </li>
              <li className={checkCondition('B')} onClick={() => setSelectedOption('B')}>
                <div className='col-lg-11 d-flex align-items-center'>
                  <input
                    type="radio"
                    name='option'
                    className='me-2 mb-1'
                    style={{ width: "20px", height: "20px" }}
                    id={`option_2`}
                    checked={selectedOption?.includes('B')}

                  />
                  <label htmlFor={`option_2`} className='pt-3'>
                    <b className='d-flex'>(B) {renderhtml(selectedQuestion?.OptionB)}</b>
                  </label>
                </div>
                <div className='col-lg-1 d-flex justify-content-end'>
                  {(selectedOption && selectedOption != selectedQuestion?.Answer && selectedOption === 'B') && (
                    <span className="icon">
                      <ImCross />
                    </span>
                  )}
                  {(selectedOption && selectedOption === selectedQuestion?.Answer && selectedOption === 'B') && (
                    <span className="icon">
                      <FaCheck />
                    </span>
                  )}
                </div>
              </li>
              <li className={checkCondition('C')} onClick={() => setSelectedOption('C')}>
                <div className='col-lg-11 d-flex align-items-center'>
                  <input
                    type="radio"
                    name='option'
                    className='me-2 mb-1'
                    style={{ width: "20px", height: "20px" }}
                    id={`option_3`}
                    checked={selectedOption?.includes('C')}

                  />
                  <label htmlFor={`option_3`} className='pt-3'>
                    <b className='d-flex'>(C) {renderhtml(selectedQuestion?.OptionC)}</b>
                  </label>
                </div>
                <div className='col-lg-1 d-flex justify-content-end'>
                  {(selectedOption && selectedOption != selectedQuestion?.Answer && selectedOption === 'C') && (
                    <span className="icon">
                      <ImCross />
                    </span>
                  )}
                  {(selectedOption && selectedOption === selectedQuestion?.Answer && selectedOption === 'C') && (
                    <span className="icon">
                      <FaCheck />
                    </span>
                  )}
                </div>
              </li>
              <li className={checkCondition('D')} onClick={() => setSelectedOption('D')}>
                <div className='col-lg-11 d-flex align-items-center'>
                  <input
                    type="radio"
                    name='option'
                    className='me-2 mb-1'
                    style={{ width: "20px", height: "20px" }}
                    id={`option_4`}
                    checked={selectedOption?.includes('D')}

                  />
                  <label htmlFor={`option_4`} className='pt-3'>
                    <b className='d-flex'>(D) {renderhtml(selectedQuestion?.OptionD)}</b>
                  </label>
                </div>
                <div className='col-lg-1 d-flex justify-content-end'>
                  {(selectedOption && selectedOption != selectedQuestion?.Answer && selectedOption === 'D') && (
                    <span className="icon">
                      <ImCross />
                    </span>
                  )}
                  {(selectedOption && selectedOption === selectedQuestion?.Answer && selectedOption === 'D') && (
                    <span className="icon">
                      <FaCheck />
                    </span>
                  )}
                </div>
              </li>

            </ul>





            <div className='p-3 d-flex justify-content-between flex-wrap'>
              <div className='col-lg-4 py-1 col-md-8 col-sm-8 d-flex justify-content-center'>
                <button disabled={!prevStateRef.current.Sn} type="button" className="btn bg-quiz-gray btn-md me-2" onClick={handlePrevQuestion}>Previous</button>
              </div>
              <div className='col-lg-4 py-1 col-md-2 col-sm-4 d-flex justify-content-center'>
                <button type="button" className="btn bg-quiz-green btn-md" onClick={() => setShowDescription(!showDescription)}>Explanation</button>
              </div>
              <div className='col-lg-4 py-1 col-md-2 col-sm-4 d-flex justify-content-center'>
                <button type="button" disabled={selectedQuestionNumber === quizQuestions?.length} className="btn bg-quiz-white px-4 btn-md" onClick={handleNextQuestion}>Next</button>
              </div>
            </div>

            {showDescription && <div className='mt-3 p-3 bg-skyblue'>
              <p><b>Explanation:</b></p>
              <p><b>ANSWER: ({selectedQuestion.Answer})</b></p>
              <p><b>Statement {selectStatement(selectedQuestion.Answer)} is correct</b></p>
              <p className='bg-lightgray p-3'>{renderhtml(selectedQuestion.Description)}</p>
              {/* <p className='bg-lightgray p-3'>The purpose of the sale, according to the government, was mainly to improve financial discipline and facilitate modernisation.</p>


          <p className='bg-lightgray p-3'>It was also envisaged that private capital and managerial capabilities could be effectively utilised to improve the performance of the PSUs.</p>
          <p><b>Statement 2 is not correct</b></p>
          <p className='bg-lightgray p-3'>Privatisation of the public sector enterprises by selling off part of the equity of PSEs to the public is known as <b>disinvestment</b>.</p>
          <p className='bg-lightgray p-3'>The purpose of the sale, according to the government, was mainly to improve financial discipline and facilitate modernisation.</p>
          <p className='bg-lightgray p-3'>It was also envisaged that private capital and managerial capabilities could be effectively utilised to improve the performance of the PSUs.</p> */}

            </div>}
            {/* {(Explain) && <div className='mt-3 p-3 bg-skyblue'>
          <p><b>Explanation:</b></p>
          <p><b>ANSWER: (A)</b></p>
          <p><b>Statement 1 is correct</b></p>
            <p className='bg-lightgray p-3'>Privatisation of the public sector enterprises by selling off part of the equity of PSEs to the public is known as <b>disinvestment</b>.</p>
            <p className='bg-lightgray p-3'>The purpose of the sale, according to the government, was mainly to improve financial discipline and facilitate modernisation.</p>
            <p className='bg-lightgray p-3'>It was also envisaged that private capital and managerial capabilities could be effectively utilised to improve the performance of the PSUs.</p>
          <p><b>Statement 2 is not correct</b></p>
            <p className='bg-lightgray p-3'>Privatisation of the public sector enterprises by selling off part of the equity of PSEs to the public is known as <b>disinvestment</b>.</p>
            <p className='bg-lightgray p-3'>The purpose of the sale, according to the government, was mainly to improve financial discipline and facilitate modernisation.</p>
            <p className='bg-lightgray p-3'>It was also envisaged that private capital and managerial capabilities could be effectively utilised to improve the performance of the PSUs.</p>
         
        </div>} */}

          </div> :
          <>
            {!loadingQuestions && <h4 style={style}>You have visited all the Questions in this section. Please try another one.</h4>}
          </>
      }

      {/* {finish && <ResultPreview queslength={queslength} userstorage={userstorage} unanswereddata={unanswereddata} />} */}
    </>
  );
}

export default Questionpreview2;




