import React from 'react';
import "./CourseDescriptionleft.css";
import { HiPlay } from "react-icons/hi";

function CourseDescriptionleft({courseDescriptionData}) {
   
  return (
    <>
      <div className='Descriptionleft-background'>
        <div className='card DescriptionCard'>
            <div className="d-grid mb-3">
            <button className="btn btn-darkgreen" type="submit">{courseDescriptionData}</button>
            </div>
            <div className='descriptionbottom'>
              <div>
                <p className='fw-bold'>Classes are accessible only through INSIGHT App</p>
              </div>
              <div><HiPlay className='fs-4 text-danger'/></div>
            </div>
        </div>
      </div>
    </>
  )
}

export default CourseDescriptionleft
