import React from 'react';
import "../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection.css";
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function CoursesTable({ courses }) {
  return (
    <table className="table" border="1">
      <thead>
        <tr className='text-center'>
          <th colSpan="4" className='History1-course-heading table-border'>HISTORY OPTIONAL COURSES</th>
        </tr>
      </thead>
      <thead >
        <tr >
          <th className='History2-course-heading table-border'>S.No.</th>
          <th className='History2-course-heading table-border'>Name Of Course</th>
          <th className='History2-course-heading table-border'>Details</th>
        </tr>
      </thead>
      <tbody>
        {courses.map((course, index) => (
          <tr key={index}>
            <td className='table-border'>{index + 1}</td>
            <td className='table-border table-data2'>{course.name}</td>
            <td className='table-border'>
              <Link className='list-group-item' to={course.link}>Click</Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function HistoryOptionalCourses() {
  /* Courses array Start */
  const courses = [
    { name: 'History Optional (Online)', link: '/course/History-Optional-Online' },
    { name: 'History Optional (Offline)', link: '/course/History-Optional-Offline' },
    { name: 'History & Culture', link: '/course/History-and-Culture' },
    { name: 'Art & Culture', link: '/course/Art-and-Culture-Course' },
    { name: 'History Optional Map Classes', link: '/course/history-optional' },
  ];
  /* Courses array End */

  return (
    <>
      <Helmet>
        <title>optionalcourse</title>
      </Helmet>
      {/* History Optional Course Start */}
      <div className='History-background-Courses'>
        <div className='History-heading'>
          <div className='container'>
            <h1>INSIGHT ACADEMY, DELHI - COURSES</h1>
          </div>
        </div>
        <div className='History-container-box'>
          <div className='card History-card container'>
            {/* Course Table Start */}
            <CoursesTable courses={courses} />
            {/* Course Table End */}
          </div>
        </div>
      </div>
      {/* History Optional Course End */}
    </>
  );
}

export default HistoryOptionalCourses;
