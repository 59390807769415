import React, { useState } from 'react';
import { RxDashboard } from "react-icons/rx";
import { FaArrowRight } from "react-icons/fa6";
import { BsArrowRightShort } from "react-icons/bs";
import { IoArrowForwardCircle } from "react-icons/io5"
import "../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection.css";
import { Link } from 'react-router-dom';
// import { Modal } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'animate.css';
import { TbCalendarSearch } from "react-icons/tb";
import CalendarModal from '../Calendartoggle/Calendartoggle';


function CurrentAffair({componentName, onDateSelect}) { 
    const [selectedDate, setSelectedDate] = useState('');
    

    const handleDateChange = (event) => {
        const date1 = event.target.value;
        const date2 = event.target.value;
        setSelectedDate(date1);
       
        onDateSelect(date1,date2); // Pass selected date to parent component
        handleScrollToTop()
        
    };

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };

    // const [isOpen, setIsOpen] = useState(false);
    // const [isOpen1, setIsOpen1] = useState(false);

    // const openModal = () => {
    //     setIsOpen(true);
    // };

    // const closeModal = () => {
    //     setIsOpen(false);
    // };

    // const openModal1 = () => {
    //     setIsOpen1(true);
    // };

    // const closeModal1 = () => {
    //     setIsOpen1(false);
    // };

    return (
        <>
            {componentName === "Blog" ? (
                <div className="card">
                    <div className="card-header list-background Affair-background py-2 fs-5 text-white">
                        {/* <TbCalendarSearch style={{cursor: "pointer"}} className='me-2 mb-1' /> */}
                        <input type="date" value={selectedDate} onChange={handleDateChange} style={{ cursor: "pointer!important", background: "none", width: "30px" , color: "white", border: "none", borderColor: "white"}} className='me-2 mb-1' />
                        {/* <RxDashboard onClick={openModal} style={{cursor: "pointer"}} className='me-2 mb-1' /> */}
                        RECENT POSTS
                    </div>
                    <ul className="list-group list-group-flush">
                        <Link to="/blog/pros-and-cons-of-history-optional-for-upsc" className='civil-link'>
                            <li className="list-group-item d-flex right-list-container civil-list">
                                <span className='right-list-icon rounded-circle me-1'>
                                    <IoArrowForwardCircle />
                                </span>
                                <span>
                                    Pros and Cons of History Optional for UPSC
                                </span>
                            </li>
                        </Link>
                        <Link to="/blog/how-insight-delhi-ias-quiz-makes-you-upsc-ready" className='civil-link'>
                            <li className="list-group-item d-flex right-list-container civil-list">
                                <span className='right-list-icon rounded-circle me-1'>
                                    <IoArrowForwardCircle />
                                </span>
                                <span>
                                    How Insight Delhi IAS Quiz Gets You UPSC-ready!
                                </span>
                            </li>
                        </Link>
                        <Link to="/blog/how-to-prepare-notes-for-history-optional-for-ias-exam" className='civil-link'>
                            <li className="list-group-item right-list-container civil-list">
                                <span className='right-list-icon rounded-circle me-1'>
                                    <IoArrowForwardCircle />
                                </span>
                                <span>
                                    How to Prepare Notes for History Optional for IAS Exam
                                </span>
                            </li>
                        </Link>
                        <Link to="/blog/is-history-a-good-optional-subject-for-ias-exam" className='civil-link'>
                            <li className="list-group-item right-list-container civil-list">
                                <span className='right-list-icon rounded-circle me-1'>
                                    <IoArrowForwardCircle />
                                </span>
                                <span>
                                    Is History a Good Optional Subject for the IAS exam
                                </span>
                            </li>
                        </Link>
                        <Link to="/blog/top-10-must-have-qualities-to-ace-ias-exam" className='civil-link'>
                            <li className="list-group-item right-list-container civil-list">
                                <span className='right-list-icon rounded-circle me-1'>
                                    <IoArrowForwardCircle />
                                </span>
                                <span>
                                    Top 10 Must-Have Qualities to Ace Your IAS Exam
                                </span>
                            </li>
                        </Link>
                    </ul>
                </div>
            ) : (<>
               {componentName === "currentaffairs" && <div className="card">
                    <div className="card-header list-background py-3 fs-5 text-white">
                        {/* <TbCalendarSearch style={{cursor: "pointer"}} className='me-2 mb-1' /> */}
                        <input type="date" value={selectedDate} onChange={handleDateChange} style={{ cursor: "pointer!important", background: "none", width: "30px" , color: "white", border: "none", borderColor: "white"}} className='me-2 mb-1' />
                        {/* <RxDashboard onClick={openModal1} style={{cursor: "pointer"}} className='me-2 mb-1' /> */}
                        Daily Current Affairs
                    </div>
                    <ul className="list-group list-group-flush">
                        <Link to="/readmore/News/COP-27-India-China-Brazil-South-Africa-oppose-carbon-border-tax" className='civil-link'>
                            <li className="list-group-item d-flex right-list-container civil-list">
                                <span className='right-list-icon rounded-circle me-1'>
                                    <IoArrowForwardCircle />
                                </span>
                                <span>
                                    COP27 - India, China, Brazil, South Affrica oppose 'carbon border tax'
                                </span>
                            </li>
                        </Link>
                        <Link to="/readmore/News/Indias-falling-exports-Manufacturing-takes-the-biggest-hit" className='civil-link'>
                            <li className="list-group-item d-flex right-list-container civil-list">
                                <span className='right-list-icon rounded-circle me-1'>
                                    <IoArrowForwardCircle />
                                </span>
                                <span>
                                    India's falling exports: Manufacturing takes the biggest hit
                                </span>
                            </li>
                        </Link>
                        <Link to="/readmore/News/As-Mission-Prarambhs-Vikram-S-rocket-soars-into-space" className='civil-link'>
                            <li className="list-group-item right-list-container civil-list">
                                <span className='right-list-icon rounded-circle me-1'>
                                    <IoArrowForwardCircle />
                                </span>
                                <span>
                                    As Mission Prarambh's Vikram-S rocket soars into space, recalling Vikram Sarabhai, the father Indian space science
                                </span>
                            </li>
                        </Link>
                        <Link to="/readmore/News/What-viral-spillover-risk-means-and-how-it-could-lead-to-new-pandemics" className='civil-link'>
                            <li className="list-group-item right-list-container civil-list">
                                <span className='right-list-icon rounded-circle me-1'>
                                    <IoArrowForwardCircle />
                                </span>
                                <span>
                                    What 'viral spillover risk' means, and how it could lead to new pandemics
                                </span>
                            </li>
                        </Link>
                        <Link to="/readmore/News/Pakistan-is-off-FATFs-grey-list-what-does-that-mean" className='civil-link'>
                            <li className="list-group-item right-list-container civil-list">
                                <span className='right-list-icon rounded-circle me-1'>
                                    <IoArrowForwardCircle />
                                </span>
                                <span>
                                    Pakistan is off FATF's grey list: what does that mean
                                </span>
                            </li>
                        </Link>
                    </ul>
                </div>}
            </>)}
            {componentName === "Cuet" && componentName !== "Blog" && componentName !== "currentaffairs" && <div className="card">
                    <div className="card-header list-background py-2 fs-5 text-white">
                        {/* <TbCalendarSearch style={{cursor: "pointer"}} className='me-2 mb-1' /> */}
                        <RxDashboard style={{cursor: "pointer"}} className='me-2 mb-1' />
                        Free Tests
                    </div>
                    <ul className="list-group list-group-flush">
                        <Link to="/readmore/News/COP-27-India-China-Brazil-South-Africa-oppose-carbon-border-tax" className='civil-link'>
                            <li className="list-group-item d-flex justify-content-between right-list-container fw-normal civil-list">
                                <span>
                                    Common Aptitude Tests
                                </span>
                                <span className='right-list-icon rounded-circle fw-normal me-1'>
                                    <BsArrowRightShort/>
                                </span>
                            </li>
                        </Link>
                        <Link to="/readmore/News/Indias-falling-exports-Manufacturing-takes-the-biggest-hit" className='civil-link'>
                            <li className="list-group-item d-flex justify-content-between right-list-container fw-normal civil-list">
                                <span>
                                    Domain-Specific Tests
                                </span>
                                <span className='right-list-icon rounded-circle me-1'>
                                    <BsArrowRightShort/>
                                </span>
                            </li>
                        </Link>
                    </ul>
                    <div className="card-header list-background py-2 fs-5 text-white">
                        {/* <TbCalendarSearch style={{cursor: "pointer"}} className='me-2 mb-1' /> */}
                        <RxDashboard style={{cursor: "pointer"}} className='me-2 mb-1' />
                        Articles
                    </div>
                    <ul className="list-group list-group-flush">
                        <Link to="/readmore/News/COP-27-India-China-Brazil-South-Africa-oppose-carbon-border-tax" className='civil-link'>
                            <li className="list-group-item d-flex justify-content-between right-list-container fw-normal civil-list">
                                <span>
                                    Pattern of Exam
                                </span>
                                <span className='right-list-icon rounded-circle fw-normal me-1'>
                                <BsArrowRightShort />
                                </span>
                            </li>
                        </Link>
                        <Link to="/readmore/News/Indias-falling-exports-Manufacturing-takes-the-biggest-hit" className='civil-link'>
                            <li className="list-group-item d-flex justify-content-between right-list-container fw-normal civil-list">
                                <span>
                                    Eligibility Criteria
                                </span>
                                <span className='right-list-icon rounded-circle me-1'>
                                <BsArrowRightShort />
                                </span>
                            </li>
                        </Link>
                        <Link to="/readmore/News/Indias-falling-exports-Manufacturing-takes-the-biggest-hit" className='civil-link'>
                            <li className="list-group-item d-flex justify-content-between right-list-container fw-normal civil-list">
                                <span>
                                    Syllabus of CUCET Exam
                                </span>
                                <span className='right-list-icon rounded-circle me-1'>
                                <BsArrowRightShort />
                                </span>
                            </li>
                        </Link>
                        <Link to="/readmore/News/Indias-falling-exports-Manufacturing-takes-the-biggest-hit" className='civil-link'>
                            <li className="list-group-item d-flex justify-content-between right-list-container fw-normal civil-list">
                                <span>
                                    How to Apply?
                                </span>
                                <span className='right-list-icon rounded-circle me-1'>
                                <BsArrowRightShort />
                                </span>
                            </li>
                        </Link>
                    </ul>
                    <div className="card-header list-background py-2 fs-5 text-white">
                        {/* <TbCalendarSearch style={{cursor: "pointer"}} className='me-2 mb-1' /> */}
                        <RxDashboard style={{cursor: "pointer"}} className='me-2 mb-1' />
                        Latest Development
                    </div>
                    <ul className="list-group list-group-flush">
                        <Link to="/readmore/News/COP-27-India-China-Brazil-South-Africa-oppose-carbon-border-tax" className='civil-link'>
                            <li className="list-group-item d-flex justify-content-between right-list-container fw-normal civil-list">
                                <span>
                                    Common Aptitude Tests
                                </span>
                                <span className='right-list-icon rounded-circle fw-normal me-1'>
                                <BsArrowRightShort />
                                </span>
                            </li>
                        </Link>
                    </ul>
                </div>}
            {/* <Modal show={isOpen} centered className="animate__animated animate__fadeIn mobiledownload">
                <CalendarModal closeModal={closeModal} />
            </Modal>
            <Modal show={isOpen1} centered className="animate__animated animate__fadeIn mobiledownload">
                <CalendarModal closeModal={closeModal1} />
            </Modal> */}
        </>
    )
}

export default CurrentAffair;