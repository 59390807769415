import React, { useEffect, useRef, useState } from 'react';
import "./QuestionLayout.css"
import Questionsummary from '../Questionsummary/Questionsummary';
import Questionpreview1 from '../Questionpreview/Questionpreview1';
import ResultPreview from '../../ResultPreview/ResultPreview';
import { Link, useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { finalsubmitcoursetest, finalsubmittest } from '../../DynamicCourse/api';
import swal from 'sweetalert';

function QuestionLayout1({ setcontentToShow, contentToShow, setuserpassword, prevPath, testQuestion, CourseDuration,coursestatus,reattemptedcourse,testDuration, QuestionCount, coursesectionId, positivemarks, negativemarks, coursetopicsn, testpackageid, authtoken, setPositiveScore, setNegativeScore, setcoursecorrectdataarray, setcourseincorrectdataarray, PositiveScore, NegativeScore, correctAnswers, incorrectAnswers }) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(()=>{
    const storedQuestionIndex = localStorage.getItem(`${coursesectionId}currentQuestionIndex`);
    return storedQuestionIndex ? parseInt(storedQuestionIndex, 10) : 0
  }); 
  const [sessionid, setsessionid] = useState(null);
  const [answersheet, setanswersheet] = useState([])
  const alert = useAlert();
  const navigate = useNavigate();
  const [queslength, setqueslength] = useState(() => {
    const storedQuestionArrayString = localStorage.getItem(`${coursetopicsn}questionArray`);
    return storedQuestionArrayString ? JSON.parse(storedQuestionArrayString) : [];
  })

  const [useranswersheet, setAnswersheet] = useState(() => {
    const storedAnswersheet = localStorage.getItem(`${coursesectionId}${authtoken}answersheet`);
    return storedAnswersheet ? JSON.parse(storedAnswersheet) : [];
  });

  // const alert = useAlert()
  const [finish, setfinish] = useState(false);
  const [clearoption, setClearOption] = useState(()=>{
    const storedClearOption = localStorage.getItem(`${coursesectionId}${authtoken}${currentQuestionIndex}clearoption`);
    return storedClearOption ? JSON.parse(storedClearOption) : undefined;
  });

  const [userstorage, setUserstorage] = useState(() => {
    const userdata = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
    return userdata ? JSON.parse(userdata) : [];
  });

  // useEffect(() => {
  //   const storedQuestionIndex = localStorage.getItem(`${coursesectionId}currentQuestionIndex`);
  //   if (storedQuestionIndex !== null) {
  //     setCurrentQuestionIndex(parseInt(storedQuestionIndex, 10));
  //   }
  // }, [coursesectionId]);

  

  // useEffect(() => {
  //   localStorage.setItem(`${coursesectionId}currentQuestionIndex`, currentQuestionIndex);
  // }, [currentQuestionIndex, coursesectionId]);

  useEffect(()=>{
    // const userDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
    // const isoption = localStorage.getItem(`${coursesectionId}${authtoken}${currentQuestionIndex}clearoption`)
    //  (isoption + "bydefault answer")
      // if (isoption) {
        // const userData = JSON.parse(userDataJSON);
        // Find the user's answer for the next question
        // const nextQuestionAnswer = userData.find(data => data.QNo == currentQuestionIndex+1);
        //  (userData[currentQuestionIndex+1].useranswerindex + "Please check this")
        // if (nextQuestionAnswer) {
          //  (userData[currentQuestionIndex+1].useranswerindex + "Please check this1")
          //  ("i am calling for default")
          // Set the clear option to the user's selected option for the next question
          setClearOption(clearoption);
          // localStorage.setItem(`${coursesectionId}${authtoken}clearoption`, JSON.stringify(nextQuestionAnswer.useranswerindex));
        // } else {
        //   //  (userData[currentQuestionIndex+1].useranswerindex + "Please check this2")
        //   // If the user hasn't answered the next question, clear the option
          // setClearOption(null);
        // }
      // }
  },[clearoption,coursesectionId, currentQuestionIndex])

  //  (userstorage)
  const [unanswereddata, setunanswereddata] = useState(() => {
    const nouserdata = localStorage.getItem(`${coursesectionId}${authtoken}nouserdata`);
    return nouserdata ? JSON.parse(nouserdata) : [];
  });

  useEffect(() => {
    // Combine userstorage and unanswereddata into answersheet array
    const updatedAnswersheet = [...userstorage, ...unanswereddata].sort((a, b) => a.questionindex - b.questionindex);
    setAnswersheet(updatedAnswersheet);
    localStorage.setItem(`${coursesectionId}${authtoken}answersheet`, JSON.stringify(updatedAnswersheet));
  }, [userstorage, unanswereddata, coursesectionId, authtoken]);

  //  (unanswereddata)
  //  (markanswereddata)

  const updateUnansweredData = () => {
    const userDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
    const nouserDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}nouserdata`);
    if (userDataJSON && nouserDataJSON) {
      const userData = JSON.parse(userDataJSON);
      const nouserData = JSON.parse(nouserDataJSON);
      const answeredQuestions = userData.map(data => data.questionindex);
      const remainingUnanswered = nouserData.filter(data => !answeredQuestions.includes(data.questionindex));
      setunanswereddata(remainingUnanswered);
      const unuserAnswerData = nouserData.find(userdata => userdata.questionindex === currentQuestionIndex);
      // if (unuserAnswerData) {
      //   setClearOption(null);
      // }
    }
  };

  const noattemedAnswer = (currentQuestionIndex, answer, answerindex, answercheck) => {
    // Retrieve existing user data array from local storage
    const existingUserDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}nouserdata`);
    let existingUserData = [];
    if (existingUserDataJSON) {
      existingUserData = JSON.parse(existingUserDataJSON);
    }

    // Update or append the user's answer for the current question
    const updatedUserData = existingUserData.map(nouserdata => {
      if (nouserdata.questionindex === currentQuestionIndex) {
        return { ...nouserdata, QNo: queslength[currentQuestionIndex].QNo ,question: queslength[currentQuestionIndex].Question, useranswer: answer, useranswerindex: answerindex, useranswercheck: answercheck, options: queslength[currentQuestionIndex].options, description: queslength[currentQuestionIndex].Description };
      }
      return nouserdata;
    });

    // If no user data exists for the current question, append it
    if (!updatedUserData.some(nouserdata => nouserdata.questionindex === currentQuestionIndex)) {
      updatedUserData.push({ questionindex: currentQuestionIndex, QNo: queslength[currentQuestionIndex].QNo, question: queslength[currentQuestionIndex].Question, useranswer: answer, useranswerindex: answerindex, useranswercheck: answercheck, correctanswer: queslength[currentQuestionIndex].Answer, options: queslength[currentQuestionIndex].options, description: queslength[currentQuestionIndex].Description  });
    }

    // Store the updated user data array back into local storage
    localStorage.setItem(`${coursesectionId}${authtoken}nouserdata`, JSON.stringify(updatedUserData));
    setunanswereddata(updatedUserData)
    const userAnswerData = updatedUserData.find(userData => userData.questionindex === currentQuestionIndex);
    if (userAnswerData) {
      setClearOption(userAnswerData.useranswerindex);
      localStorage.setItem(`${coursesectionId}${authtoken}${currentQuestionIndex}clearoption`, userAnswerData.useranswerindex);
    } 
  };

  const handleNextQuestion = () => {
    const isoption = localStorage.getItem(`${coursesectionId}${authtoken}${currentQuestionIndex}clearoption`)
    if (!isoption) {
      noattemedAnswer(currentQuestionIndex, '', null);
    }

    // Check if there are more questions to navigate to
    if (currentQuestionIndex < queslength.length - 1) {
      // setCurrentQuestionIndex(currentQuestionIndex + 1);
      setCurrentQuestionIndex(prevIndex => {
        const newIndex = prevIndex + 1;
        localStorage.setItem(`${coursesectionId}currentQuestionIndex`, newIndex);
        return newIndex;
      });
      const userDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
      if (userDataJSON) {
        const userData = JSON.parse(userDataJSON);
        // Find the user's answer for the next question
        const nextQuestionAnswer = userData.find(data => data.questionindex === currentQuestionIndex + 1);
        if (nextQuestionAnswer) {
          // Set the clear option to the user's selected option for the next question
          setClearOption(nextQuestionAnswer.useranswerindex);
        } else {
          // If the user hasn't answered the next question, clear the option
          setClearOption('');
        }
      }
    } else {
      // If all questions have been answered, redirect to the first question
      // setCurrentQuestionIndex(0);
      const userDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
      if (userDataJSON) {
        const userData = JSON.parse(userDataJSON);
        // Find the user's answer for the first question
        const firstQuestionAnswer = userData.find(data => data.questionindex === 0);
        if (firstQuestionAnswer) {
          // Set the clear option to the user's selected option for the first question
          setClearOption(firstQuestionAnswer.useranswerindex);
        } else {
          // If the user hasn't answered the first question, clear the option
          setClearOption(null);
        }
      }
    }

    if ((currentQuestionIndex + 1) == QuestionCount) {
      alert.show("Test finished");
      // handlecheckanswer()
      setfinish(true)
      // navigate("/studentdashboard")
    }


  };

  const loadPreviousAnswer = () => {
    // Retrieve user data from local storage
    const userDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
    if (userDataJSON) {
      const userData = JSON.parse(userDataJSON);
      setUserstorage(userData);

      // Check if the previous question index is within the valid range
      // const previousIndex = currentQuestionIndex - 1;
      if ((currentQuestionIndex - 1) >= 0) {
        // Check if there is a user answer for the previous question
        const previousUserData = userData.find(data => data.questionindex === currentQuestionIndex - 1);
        if (previousUserData) {
          // If there is a user answer for the previous question, set the clear option
          // const userAnswerIndex = previousUserData.useranswerindex;
          setClearOption(previousUserData.useranswerindex)
          // if (userAnswerIndex !== null && userAnswerIndex !== undefined) {
            // const isoption = localStorage.getItem(`${coursesectionId}${authtoken}${currentQuestionIndex}clearoption`)
            // setClearOption(queslength[previousIndex].options.indexOf(previousUserData.useranswer));
            // setClearOption(userAnswerIndex)
          // } else {
            // setClearOption(null);
          // }
        } else {
          // If there is no user answer for the previous question, clear the selection
          setClearOption(null);
        }
      } else {
        // If the previous question index is out of range, clear the selection
        // setClearOption(null);
      }
    } else {
      // If there is no user data in local storage, clear the selection
      // setClearOption(null);
    }
  };


  const handlePreviousQuestion = () => {
    
    if (currentQuestionIndex > 0) {
      // setCurrentQuestionIndex(currentQuestionIndex - 1);
      setCurrentQuestionIndex(prevIndex => {
        const newIndex = prevIndex - 1;
        localStorage.setItem(`${coursesectionId}currentQuestionIndex`, newIndex);
        return newIndex;
      });
      loadPreviousAnswer()
    }
  };

  const saveUserAnswer = (currentQuestionIndex, answer, answerindex, answercheck) => {
    // Retrieve existing user data array from local storage
    const existingUserDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
    let existingUserData = [];
    if (existingUserDataJSON) {
      existingUserData = JSON.parse(existingUserDataJSON);
    }

    // Update or append the user's answer for the current question
    const updatedUserData = existingUserData.map(userData => {
      if (userData.questionindex === currentQuestionIndex) {
        return { ...userData, QNo: queslength[currentQuestionIndex].QNo, question: queslength[currentQuestionIndex].Question, useranswer: answer, useranswerindex: answerindex, useranswercheck: answercheck, options: queslength[currentQuestionIndex].options, description: queslength[currentQuestionIndex].Description};
      }
      return userData;
    });

    // If no user data exists for the current question, append it
    if (!updatedUserData.some(userData => userData.questionindex === currentQuestionIndex)) {
      updatedUserData.push({ questionindex: currentQuestionIndex, QNo: queslength[currentQuestionIndex].QNo, question: queslength[currentQuestionIndex].Question, useranswer: answer, useranswerindex: answerindex, useranswercheck: answercheck, correctanswer: queslength[currentQuestionIndex].Answer, options: queslength[currentQuestionIndex].options, description: queslength[currentQuestionIndex].Description });
    }

    // Store the updated user data array back into local storage
    localStorage.setItem(`${coursesectionId}${authtoken}userdata`, JSON.stringify(updatedUserData));
    setUserstorage(updatedUserData)
    // Set clearoption based on the user's answer index
    const userAnswerData = updatedUserData.find(userData => userData.questionindex === currentQuestionIndex);
    if (userAnswerData) {
        setClearOption(userAnswerData.useranswerindex);
        localStorage.setItem(`${coursesectionId}${authtoken}${currentQuestionIndex}clearoption`, userAnswerData.useranswerindex);
    }
    updateUnansweredData();
  };



  const handlecheckanswer = async () => {
    // Clear previous scores

    // Retrieve user data from local storage
    const userDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
    const userData = JSON.parse(userDataJSON);

    // Initialize arrays to store correct and incorrect answers
    let CorrectAnswers = [];
    let inCorrectAnswers = [];
    let positiveScore = 0;
    let negativeScore = 0;

    // Calculate scores
    if (userData) {
      userData.forEach(data => {
        const correctAnswer = queslength[data.questionindex].Answer;
        // Check if user answered the question
        if (data.useranswer !== null && data.useranswer !== undefined) {
          if (data.useranswercheck === correctAnswer) {
            positiveScore += positivemarks;
            CorrectAnswers.push({
              questionIndex: data.questionindex,
              QNo: data.QNo,
              question: data.question,
              useranswer: data.useranswer, // Use data.useranswer instead of data.useranswercheck
              useranswercheck: data.useranswercheck, // Use data.useranswercheck
              correctAnswer: correctAnswer
            });
            setcoursecorrectdataarray(CorrectAnswers);
          } else {
            negativeScore += negativemarks;
            inCorrectAnswers.push({
              questionIndex: data.questionindex,
              QNo: data.QNo,
              question: data.question,
              useranswer: data.useranswer, // Use data.useranswer instead of data.useranswercheck
              useranswercheck: data.useranswercheck, // Use data.useranswer instead of data.useranswercheck
              correctAnswer: correctAnswer
            });
            setcourseincorrectdataarray(inCorrectAnswers);
          }
        }
      });
    }
    // setpositivemarks={setpositivemarks} setnegativemarks={setnegativemarks}
    // setpositivemarks(positiveScore);
    // setpositivemarks(parseFloat(negativeScore.toFixed(2)));



    // Store correct and incorrect answers in local storage
    localStorage.setItem(`${coursesectionId}${authtoken}correctAnswers`, JSON.stringify(CorrectAnswers));
    localStorage.setItem(`${coursesectionId}${authtoken}incorrectAnswers`, JSON.stringify(inCorrectAnswers));


    // Store scores in local storage
    // localStorage.setItem('positivemarks', JSON.stringify(positiveScore));
    // localStorage.setItem('negativemarks', JSON.stringify(negativeScore));
    try {
      // email, totalQuestions, attemptedQuestions, correctQuestions, wrongQuestions, correctMarks, wrongMarks
      // sectionId, topicId
      // Call the finalsubmittest function with the necessary parameters
     
      const responseData = await finalsubmitcoursetest({
        email: authtoken,
        totalQuestions: queslength.length,
        attemptedQuestions: userstorage.length,
        correctQuestions: CorrectAnswers.length,
        wrongQuestions: inCorrectAnswers.length,
        correctMarks: positiveScore,
        wrongMarks: negativeScore,
        sectionId: coursesectionId,
        topicId: coursetopicsn,
        answerSheet: JSON.stringify(useranswersheet),
        reattemptedcourse,
        coursestatus
      });

      // Log response data

      // Navigate to the final result page
      navigate('/CourseDescreption/CourseResult');

      // Show success alert
      alert.show('Test submitted successfully');
    } catch (error) {
      // Show error alert if submission fails
      alert.show('Test submission failed. Please try again.');
    }
  };

  const clearAnswer = () => {
    // Retrieve userData array from local storage
    const userDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}userdata`);
    if (userDataJSON) {
      const userData = JSON.parse(userDataJSON);
      // Remove the user's answer for the current question
      const updatedUserData = userData.filter(data => data.questionindex !== currentQuestionIndex);
      // Update the userData array in local storage
      localStorage.setItem(`${coursesectionId}${authtoken}userdata`, JSON.stringify(updatedUserData));
      // Clear the selected option
      setUserstorage(updatedUserData);
      // setClearOption(null);
    }

    // Retrieve nouserData array from local storage
    const nouserDataJSON = localStorage.getItem(`${coursesectionId}${authtoken}nouserdata`);
    if (nouserDataJSON) {
      const nouserData = JSON.parse(nouserDataJSON);
      // Remove the user's answer for the current question
      const updatedUserData1 = nouserData.filter(data => data.questionindex !== currentQuestionIndex);
      // Update the nouserData array in local storage
      localStorage.setItem(`${coursesectionId}${authtoken}nouserdata`, JSON.stringify(updatedUserData1));
      // Clear the selected option
      setunanswereddata(updatedUserData1);
      // setClearOption(null);
    }
  };

  // Function to handle clearing the answer
  const handleClear = () => {
    clearAnswer();
    const isclear = localStorage.removeItem(`${coursesectionId}${authtoken}${currentQuestionIndex}clearoption`);
    setClearOption(isclear)
  };

  function showCustomAlert1() {
    document.getElementById('customAlert1').style.display = 'flex';
  }
  
  function hideCustomAlert1() {
    document.getElementById('customAlert1').style.display = 'none';
  }

  function confirmBtn1(){
    hideCustomAlert1()
    // setfinish(true)
    localStorage.removeItem(`${coursesectionId}${authtoken}userdata`);
    // setUserstorage([])
    // navigate("/")
    // navigate(prevPath)
    setcontentToShow("PT MCQs")
    localStorage.setItem('contentToShow', 'PT MCQs');
    setuserpassword(false)
  }

  function cancelBtn1(){
    hideCustomAlert1()
  }

  const showAlert1 = () => {

    showCustomAlert1()
};

useEffect(() => {
  const handleBeforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = ""; // Modern browsers will show a default message
  };

  window.addEventListener('beforeunload', handleBeforeUnload);

  return () => {
    window.removeEventListener('beforeunload', handleBeforeUnload);
  };
}, []);


  //handle mousemove to restrict student
  const inputRef = useRef(null)
  const [firstWarning, setFirstWarning] = useState(false);
  const [mouseFirstMovement, setMouseFirstMovement] = useState(false);
  const [warning, setWarning] = useState(false);
  const testAreaRef = useRef(null);
  // const navigate = useNavigate();

  console.log(warning)

  useEffect(() => {
    const handleMouseMove = (event) => {
      setMouseFirstMovement(true)
      const testArea = testAreaRef.current;
      if (testArea) {
        const { top, left, width, height } = testArea.getBoundingClientRect();
        const insideTestArea = (
          event.clientX >= left &&
          event.clientX <= left + width &&
          event.clientY >= top &&
          event.clientY <= top + height
        );

        if (!insideTestArea) {
          setWarning(true);
        } else {
          setWarning(false);
        }
      }
    };

    document.addEventListener('mouseover', handleMouseMove);

    return () => {
      document.removeEventListener('mouseover', handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (warning && mouseFirstMovement) {
      swal({
        title: "Are you sure?",
        text: "Do you really want to leave the test for now!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
        closeOnClickOutside: false,
        buttons: ["No, stay Here", "Yes, leave the test"],
        confirmButtonText: "Yes, leave the test"
      }).then((willDelete) => {
        if (willDelete) {
          navigate('/home')
        } else {
          console.log('stay here')
        }
      })
    }
  }, [warning])



  return (
    <>
    <div id="customAlert1" class="custom-alert1" style={{zIndex: "165"}}>
    <div class="custom-alert-content">
      <span class="custom-alert-icon">⚠️</span>
      <p>Are you sure you want to quit the test?</p>
      <div class="custom-alert-buttons">
        <button onClick={confirmBtn1} id="confirmBtn1">Yes</button>
        <button onClick={cancelBtn1} id="cancelBtn1">No</button>
      </div>
    </div>
  </div>
      <div className='container-fluid p-3' ref={testAreaRef}>
        <div className='row'>
          <div className='col-lg-12'>
            <Questionpreview1 contentToShow={contentToShow} setfinish={setfinish} setcontentToShow={setcontentToShow} setuserpassword={setuserpassword} finish={finish} testDuration={testDuration} CourseDuration={CourseDuration} coursetopicsn={coursetopicsn} currentQuestionIndex={currentQuestionIndex} authtoken={authtoken} testpackageid={testpackageid} queslength={queslength} unanswereddata={unanswereddata} setqueslength={setqueslength} clearoption={clearoption} userstorage={userstorage} setClearOption={setClearOption} setUserstorage={setUserstorage} saveUserAnswer={saveUserAnswer} setsessionid={setsessionid} />
            {!finish ? <div className='p-3 d-flex justify-content-between flex-wrap'>
              <div className='col-lg-5 py-3 col-md-12 respons-btn1 col-sm-12 d-flex flex-wrap'>
                {currentQuestionIndex <= 0 ? <></> : <button type="button" className="btn bg-Question-white btn-md me-2 px-5" onClick={handlePreviousQuestion}>Previous</button>}
                <button type="button" className="btn bg-Question-white btn-md me-2 px-5" onClick={handleClear}>Clear Answer</button>
              </div>
              <div className='col-lg-5 py-3 col-md-2 col-sm-12 respons-btn2 d-flex flex-wrap justify-content-end'>
              {/* <button type="button" className="btn bg-Question-white btn-md px-5 me-4" onClick={showAlert1}>Test Quit</button> */}
                <button type="button" className="btn bg-Question-white btn-md px-5" onClick={handleNextQuestion}>{((currentQuestionIndex + 1) == QuestionCount) ? "Final Submit" : "Save & Next"}</button>
              </div>
            </div>: <div className='p-2 d-flex justify-content-around'>
                {/* <Link type="button" className="btn bg-Question-white btn-md" onClick={handlecheckanswer} to="#">Final Submit</Link> */}
                <Link type="button" className="btn bg-Question-white btn-md" onClick={handlecheckanswer} to="#">Final Submit</Link>
              </div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default QuestionLayout1