import React, { useEffect, useState } from 'react'
import CardsPage from '../CardsPage/CardsPage';
import { fetchNotMyTest } from '../DynamicCourse/api';

function Moretest({heading2}) {
 

  const [notmytest, setNotmytest] = useState([]);
  // useEffect(()=>{
  
  // setcoursepayment(0)
  // setcoursenotpayment(1)
  // setispayment(false)
  // localStorage.setItem('ispayment', JSON.stringify(false));
  // },[])
  const authtoken = localStorage.getItem('authtoken');
  useEffect(() => {
    const fetchData = async () => {
        try {           
            const data4 = await fetchNotMyTest(authtoken);          
            setNotmytest(data4);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    fetchData();
}, [authtoken]); 
  return (
    <div>
      <CardsPage cards={notmytest} heading2={heading2}/>
    </div>
  )
}

export default Moretest;