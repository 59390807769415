import React from 'react'
import cuettest1 from "../../Image/cuettest1.png";
import cuettest2 from "../../Image/cuettest2.png";
import cuettest3 from "../../Image/cuettest3.png";
import { Link } from 'react-router-dom';
import "../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection.css";

function ImageComponent1() {
    return (
        <>
        <div>
            <div className="card-body px-0 py-2">
                <Link to="/home" className='img-link'>
                    <img src={cuettest1} className="img-civil-border" alt="..." />
                </Link>
            </div>
            <div className="card-body px-0 py-2">
                <Link to="/home" className='img-link'>
                    <img src={cuettest2} className="img-civil-border" alt="..." />
                </Link>
            </div>
            <div className="card-body px-0 py-2">
                <Link to="/home" className='img-link'>
                    <img src={cuettest3} className="img-civil-border" alt="..." />
                </Link>
            </div>
            </div>
        </>
    )
}

export default ImageComponent1;