import React from 'react';
import { FaYoutube } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";

function Socialpage() {
  return (
    <>
     <div>
        <div className='d-flex'>
            <div className='p-3'>
                {/* <div className='' style={{border: "1px solid white", padding: "2vmax"}}>F</div> */}
                <div className='wow social-icon-instagram animate__animated animate__heartBeat' style={{border: "0px solid white", borderRadius: "50%", boxShadow: "inset 0 3px 15px white" , width: "80px" , height: "80px",display: "flex", justifyContent: "center", alignItems: "center",  padding: "0vmax", cursor: "pointer"}}><FaInstagram className='text-white social-icon-text-instagram fs-1'/></div>
            </div>
            <div className='p-3'>
                {/* <div className='' style={{border: "1px solid white", padding: "2vmax"}}>Y</div> */}
                <div className='wow social-icon-youtube animate__animated animate__heartBeat' style={{border: "0px solid white", borderRadius: "50%", boxShadow: "inset 0 3px 15px white" , width: "80px" , height: "80px",display: "flex", justifyContent: "center", alignItems: "center",  padding: "0vmax", cursor: "pointer"}}><FaYoutube className='text-white social-icon-text-youtube fs-1'/></div>
            </div>
            <div className='p-3'>
                {/* <div className='' style={{border: "1px solid white", padding: "2vmax"}}>I</div> */}
                <div className='wow social-icon-telegram animate__animated animate__heartBeat' style={{border: "0px solid white", borderRadius: "50%", boxShadow: "inset 0 3px 15px white" , width: "80px" , height: "80px",display: "flex", justifyContent: "center", alignItems: "center",  padding: "0vmax", cursor: "pointer"}}><FaTelegramPlane className='text-white social-icon-text-telegram fs-1'/></div>
            </div>
            <div className='p-3'>
                <div className='wow social-icon-facebook animate__animated animate__heartBeat' style={{border: "0px solid white", borderRadius: "50%", boxShadow: "inset 0 3px 15px white" , width: "80px" , height: "80px",display: "flex", justifyContent: "center", alignItems: "center",  padding: "0vmax", cursor: "pointer"}}><FaFacebook className='text-white social-icon-text-facebook fs-1'/></div>
            </div>
        </div>
        </div> 
    </>
  )
}

export default Socialpage;