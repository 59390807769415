import axios from "axios";
import { useState, useEffect, useContext, createContext } from "react";

const AuthContext = createContext();

const QuizGoingOnProvider = ({ children }) => {
    const [isQuizGoingOn, setIsQuizGoingOn] = useState(0)    
    return (
        <AuthContext.Provider value={[isQuizGoingOn, setIsQuizGoingOn]}>
            {children}
        </AuthContext.Provider>
    )
}
//custom hook
const useQuizGoingOn = () => {
    return useContext(AuthContext)
}
export { useQuizGoingOn, QuizGoingOnProvider }

