import React, { useState } from 'react';
import "../Login/Login.css";
import { Link, useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { forgotUser } from '../DynamicCourse/api';
import axios from 'axios';
import swal from "sweetalert";
import { BASE_URL } from '../../insightConfig/insightConfig';

function Forgot() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();
    const alert = useAlert();
    const handleSubmit = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/Forget`, { email })
            if (response.data.statusCode ==200){
                swal(response.data.message, {
                    icon: "success",
                    buttons: {
                        confirm: {
                            text: "OK",
                            value: true,
                            visible: true,
                            closeModal: true
                        }
                    }
                }).then(() => {
                    navigate('/login');
                })
            }
            // await forgotUser({ email });
            // // Reset form fields or show success message

            // // If registration is successful, reset form fields and show success message
            // setEmail('');
            // navigate('/login');
            // // alert("Hello, this is an alert!");
            // alert.show('Forget Password Successfully');
        } catch (error) {
            swal(error.response.data.message, { icon: "error" })
            console.error(error.message);
        }
    };
    return (
        <>
            <div className="forgotCOntainer">
                <div className='loginForm forgotForm'>
                    <div className="card login-card">
                        <div className="card-header m-3 text-center fw-500 text-white login-heading">
                            Enter Registered Email-id to Get Password
                        </div>
                        <div className="card-body">
                            <form>
                                <div className="mb-3">
                                    <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                    <input type="email" className="form-control signup-name" id="exampleInputEmail1" placeholder='Your Email-ID' aria-describedby="emailHelp" value={email} onChange={(e) => setEmail(e.target.value.toLowerCase())} style={{ textDecoration: 'none' }} />
                                </div>
                                <div className="d-grid gap-2">
                                    <button className="btn btn-primary login-btn py-2 border-0" type="button" onClick={handleSubmit}>SUBMIT</button>
                                </div>
                            </form>
                        </div>
                        <div className='m-3 login-bottom '>
                            <div className="card-footer py-3 d-flex justify-content-center login-footer">
                                Already Member..? <Link className='list-group-item px-1' to="/login">Login</Link>
                            </div>
                            <div className="card-footer py-3 d-flex justify-content-center login-footer">
                                New Here? <Link className='list-group-item px-1' to="/Registration">Sign Up</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Forgot;
