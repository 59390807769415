import React, { useEffect, useState } from 'react';
import Civildropdown from './CivildropDown';

const Civil = () => {
 
  const civil = [
    {
      title: 'Home',
      links: [
        { label: 'About Us', url: '/about-us' },
        { label: "Why SRIRAM's IAS", url: '/why-sriram-ias' },
        { label: "Director's Message", url: '/director-message' },
        { label: 'Sample Notes', url: '#' },
        { label: 'FAQs', url: '#' }
      ]
    }
    // {
    //   title: 'GENERAL STUDIES',
    //   links: [
    //     { label: 'GS Foundation Course', url: `#`},
    //     { label: 'GS Prelims Course', url: `#` },
    //     { label: 'GS Main Course', url: `#` },
    //     { label: 'Ethics, Aptitude And Integrity', url: `#` }
    //   ]
    // },
    // {
    //   title: 'HISTORY',
    //   links: [
    //     { label: 'History Optional Online', url: `#` },
    //     { label: 'History Optional Offline', url: `#` },
    //     { label: 'GS History And Culture', url: `#` },
    //     { label: 'Art and Culture', url: `#` },
    //     { label: 'Hist. Opt. Map Classes', url: `#` }
    //   ]
    // }


   
  ];

  const civil1 = [
    // {
    //   title: '',
    //   links: []
    // },
    // {
    //   title: 'Test Series',
    //   links: [
    //     { label: 'GS Prelims Test Series', url: '#' },
    //     { label: 'GS Pre-Cum-Mains', url: '#' },
    //     { label: 'GS Mains Test Series', url: '#' },
    //     { label: 'Hist. Opt. Test Series', url: '#' }
    //   ]
    // },
    // {
    //   title: 'Success Mantra',
    //   links: [
    //     { label: 'PT Exam 2023 - Answer Key', url: '#' },
    //     { label: 'Mains Exam 2023', url: '#' },
    //     { label: 'Topper\'s Strategy', url: '#' },
    //     { label: 'Strategy For Hist. Optional', url: '#' }
    //   ]
    // },
    // {
    //   title: 'Free Course',
    //   links: [
    //     { label: 'UPSC QUIZ', url: '#' },
    //     { label: 'Art And Culture Book', url: '#' },
    //     { label: 'Book List For GS', url: '#' },
    //     { label: 'Book List For Hist. Opt', url: '#' },
    //     { label: 'Imp. Websites', url: '#' }
    //   ]
    // },
    // Add more categories as needed following the same structure
  ]
  //Civil Drop Down Data Section End

  return (
    <div>
      <Civildropdown civil={civil} civil1={civil1} />
    </div>
  );
};

export default Civil;
