import React, { useState, useEffect } from 'react';
import "./Questionpreview.css";
import { BASE_URL } from '../../../insightConfig/insightConfig';
import ResultPreview from '../../ResultPreview/ResultPreview';
import { FiClock } from "react-icons/fi";
import { useNavigate } from 'react-router-dom';


function Questionpreview({ testQuestions }) {

  const [starttimes, setstarttime] = useState();

  const [selectedOption, setSelectedOption] = useState();
  const navigate = useNavigate()

  const [selectedQuestion, setSelectedQuestion] = useState()
  console.log('selected Questions', selectedQuestion)

  useEffect(() => {
    let initialQuestion = testQuestions.find(question => question.QNo == 1)
    setSelectedQuestion(initialQuestion)
  }, [testQuestions])


  const renderhtml = (paragraphs) => {
    return <p className='renderhtml' dangerouslySetInnerHTML={{ __html: paragraphs }} />;
  };

  const renderhtml1 = (paragraphs) => {
    return <b className='renderhtml1' dangerouslySetInnerHTML={{ __html: paragraphs }} />;
  };
  const handleOptionChange = (optionIndex, option, QuestionNumber, string) => {
    setSelectedOption(optionIndex)
    console.log(optionIndex, option, QuestionNumber, string)
  }

  return (
    <>
      <h5 className='bg-warning p-3 d-inline-flex rounded-pill m-2'><FiClock className='me-2' /> Time Remaining: <b className='text-danger mx-2'>time left</b></h5>
      <div className='card questionpreviewpadding'>
        <p><b>{/* {currentQuestionIndex + 1} */}1. {renderhtml(selectedQuestion?.Question)}</b></p>
        <ul className='answer-list'>
          {selectedQuestion?.options.map((option, optionIndex) => (
            <li key={optionIndex} onClick={() => handleOptionChange(optionIndex, option, selectedQuestion.QNo, String.fromCharCode(65 + optionIndex))} className={selectedOption === optionIndex ? 'selected' : ''}>
              <input
                type="radio"
                className='me-2 mb-1'
                style={{ width: "20px", height: "20px" }}
                name={`question_${optionIndex}`}
                id={`option_${optionIndex}_${optionIndex}`}
                checked={selectedOption === optionIndex}
              // onChange={() => handleOptionChange(optionIndex, option, currentQuestionIndex, String.fromCharCode(65 + optionIndex))}
              />
              <label htmlFor={`option_${optionIndex}_${optionIndex}`}>
                <b>({String.fromCharCode(65 + optionIndex)}) {renderhtml1(option)}</b>
              </label>
            </li>
          ))}
        </ul>
      </div>
      {/* <ResultPreview queslength={queslength} userstorage={userstorage} unanswereddata={unanswereddata} /> */}
    </>
  );
}

export default Questionpreview;
