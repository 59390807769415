import React, { useEffect, useState } from 'react';
import "../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection.css";
import CivilTopSection from '../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection';
import userImage from "../../Image/user.png";
import CopImg from "../../Image/civilcard1.jpg";
import CivilrightSection from '../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection';
import Content from '../AllPageContent/AllPageContent';
import ButtonPage from '../ButtonPage/ButtonPage';
import CurrentAffair from '../CurrentAffair/CurrentAffair';
import Tag from '../TagComponent/Tag';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Radium from "radium";
import { BASE_URL } from '../../insightConfig/insightConfig';
import { fetchnewsdetail } from '../DynamicCourse/api';
import Loader from '../Loader/Loader';

function DynamicNews({ handleDateinput }) {
    const { newsurl } = useParams();
    const [newData, setNewData] = useState(null);
    const Buttonname = "Singlebutton";
    const componentName = "currentaffairs";
    const navigate = useNavigate();
    useEffect(() => {
        // if()
        const fetchData = async () => {
            try {
                // const response = await fetch(`${BASE_URL}/readmore/News/${newsurl}`);
                // const data = await response.json();
                const data = await fetchnewsdetail(newsurl);
                setNewData(data[0]);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [newsurl]);
    if (!newData) {
        return <div style={{backgroundColor: "white", zIndex: "180", width: "100%", height: "100vh", position: "fixed" , top: "0"}}><Loader/></div>
    }

    const data = {
        academyName: newData.Title,
        userImage: userImage,
        userName: 'INSIGHT IAS',
        Image: newData.Image
    };

    const paragraphs = newData.Description
    const noteurl = newData.NotePDF

    const SudeoDateClick = (date) => {
        if (date) {
            // setTimeout(()=>{
            handleDateinput(date)
            // },1000)
            // setTimeout(()=>{
            navigate('/daily-current-affairs/News');
            // },2000)
        }
    }

    const NewStyle = {
        before: {
            content: '""',
            width: "100%",
            backgroundImage: `url(${newData.Image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top",
            opacity: "0.5",
            position: "absolute",
            filter: "blur(50px)",
            zIndex: "1"
        }
    }

    return (
        <>
            <Helmet>
                <title>{newData.MetaTitle}</title>
                <meta name="description" content={`${newData.MetaDescription}`}></meta>
            </Helmet>
            {/* Civil Cop Section Start */}
            <section className={`${newsurl} dynamic-news`}>
                <section style={NewStyle.before} className='dynamic-news-before' />
                {/* Civil Top Section Start */}
                <CivilTopSection
                    academyName1={data.academyName}
                    userImage={data.userImage}
                    userName={data.userName}
                    Image={data.Image}
                />
                {/* Civil Top Section End */}
            </section>
            <div className='Civil-section'>
                <div className='civil-row'>
                    <div className='col-lg-8 col-md-12 col-sm-12 me-2'>
                        <Content paragraphs={paragraphs} />
                        <ButtonPage Pagename={Buttonname} noteurl={noteurl} />
                        {/* <Tag /> */}
                    </div>
                    {/* Civil right Section Start */}
                    <div className='col-lg-4 col-md-12 col-sm-12 card'>
                        <div>
                            <CivilrightSection />
                        </div>
                        <div className="card-body">
                            <CurrentAffair componentName={componentName} onDateSelect={SudeoDateClick} />
                        </div>
                    </div>
                    {/* Civil right Section End */}
                </div>
            </div>
            {/* Civil Cop Section End */}
        </>
    )
}

export default Radium(DynamicNews);