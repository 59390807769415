import React, { useEffect, useState } from 'react';
import "../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection.css";
import CivilTopSection from '../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection';
import userImage from "../../Image/user.png";
import Content from '../AllPageContent/AllPageContent';
import ButtonPage from '../ButtonPage/ButtonPage';
import CurrentAffair from '../CurrentAffair/CurrentAffair';
import Tag from '../TagComponent/Tag';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Buttontab from '../Buttontabs/Buttontabs';
import Radium from "radium";
import Likeshare from '../LikeandShareCompoent/Likeshare';
import { fetchblogdetail } from '../DynamicCourse/api';
import Loader from '../Loader/Loader';

function Dynamicblog() {
    const { blogurl } = useParams();
    const [newData, setNewData] = useState(null);
    const [initialViewCount, setInitialViewCount] = useState(null);
    const Buttonname = "Singlebutton";
    const componentName = "Blog";
    const [contentToShow, setContentToShow] = useState('In News');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchblogdetail(blogurl);
                setNewData(data[0]);
                setInitialViewCount(data[0]?.ViewCount);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [blogurl]);
    if (!newData) {
        return <div style={{backgroundColor: "white", zIndex: "180", width: "100%", height: "100vh", position: "fixed" , top: "0"}}><Loader/></div>
    }
    const data = {
        academyName: newData.Title,
        userImage: userImage,
        userName: 'INSIGHT IAS',
        Image: newData.Image,
        like: (newData.LikeCount + newData.PreLikes),
        view: initialViewCount !== null ? initialViewCount : ( newData.ViewCount + newData.preViews),
    };

    const paragraphs = newData.Description
    // const paragraphs = []
    const noteurl = newData.NotePDF

    const blogStyle = {
        before: {
            content: '""',
            width: "100%",
            backgroundImage:`url(${newData.Image})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "top",
            opacity: "0.5",
            position: "absolute",
            filter: "blur(50px)",
            zIndex: "1"
        }
    }
    const t1 = newData.MetaTitle
    const t2 = newData.MetaDescription
    const t3 = newData.Image
    return (
        <>
            <Helmet>
                <title>{newData.MetaTitle}</title>
                {/* <title>Coaching Institute for UPSC/IAS Exam-Insight IAS, Delhi</title> */}
                <meta name="description" content={`${newData.MetaDescription}`}></meta>
                {/* <meta property="og:title" content={newData.MetaTitle} /> */}
                <meta property="og:title" content={t1} />
                <meta property="og:image" content={t3} />
                <meta property="og:image:width" content="1200" />
    <meta property="og:image:height" content="630" />
                <meta property="og:description" content={t2} />
                {/*<meta property="og:image" content={newData.Image} /> */}
            </Helmet>
            {/* Civil Cop Section Start */}
            <section className={`${blogurl} dynamic-blog`}>
            <section style={blogStyle.before} className='dynamic-blog-before' />
                {/* Civil Top Section Start */}
                <CivilTopSection
                    academyName1={data.academyName}
                    userImage={data.userImage}
                    userName={data.userName}
                    Image={data.Image}
                />
                {/* Civil Top Section End */}
            </section>
            <div className='Civil-section'>
                <div className='civil-row'>
                    <div className='col-lg-8 col-md-12 col-sm-12 me-2'>
                        <div className='BlogPage-box2 dynamicPage-box2 d-flex align-items-center flex-wrap justify-content-between'>
                            <div>
                            <Buttontab componentName={componentName} setContentToShow={setContentToShow} />
                            </div>
                            <div>
                            <Likeshare Like={data.like} setNewData={setNewData} Sn={newData.Sn} url={blogurl} view={data.view}/>
                            </div>
                        </div>
                        <Content paragraphs={paragraphs} />
                        {/* <ButtonPage Pagename={Buttonname} noteurl={noteurl} /> */}
                        {/* <Tag /> */}
                    </div>
                    {/* Civil right Section Start */}
                    <div className='col-lg-4 col-md-12 col-sm-12'>
                        <div className='card p-2'>
                            <CurrentAffair componentName={componentName} />
                        </div>
                    </div>
                    {/* Civil right Section End */}
                </div>
            </div>
            {/* Civil Cop Section End */}
        </> 
    )
}

export default Radium(Dynamicblog);