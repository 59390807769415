import React, { useEffect, useState } from 'react';
import CurrentAffair from '../CurrentAffair/CurrentAffair';
import BlogCard from './BlogCard/BlogCard';
import Pagelayout2 from '../EffectonLoad/pagelayout2';
import { BASE_URL } from '../../insightConfig/insightConfig';
import RangeCalendar from '../Calendar/RangeCalendar';
import axios from 'axios';

function BlogPage1({ blogs, categoryId, setBlog, startDate, setStartDate, endDate, setEndDate, setisfilterapply, isloading }) {

  const searchDateWise = async (/* startdate, enddate, tag */) => {
    const start = new Date(startDate).toISOString().split('T')[0]; // Extracting only the date part
    const end = new Date(endDate).toISOString().split('T')[0]; // Extracting only the date part
    try {
      let response = await axios.get(BASE_URL + `/blogs/${categoryId}/${start}/${end}`)
      console.log(response.data.data)
      setBlog(response.data.data)
      setisfilterapply(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  const handleDateinput = async (date1, date2) => {
    // setSelectedDate(date)
    //  (date)
    const originalDate1 = new Date(date1);
    const originalDate2 = new Date(date2);
    const formattedDate1 = originalDate1.toISOString().split('T')[0]; // Extracting only the date part
    const formattedDate2 = originalDate2.toISOString().split('T')[0]; // Extracting only the date part

    try {
      // setisfilterapply(true);
      const response = await fetch(`${BASE_URL}/blogs/blog/${formattedDate1}/${formattedDate2}`);
      const data = await response.json();
      // setFilteredData(data.data);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };


  return (
    <>
      <div className='Civil-section blog1-section'>
        <div className='civil-row white blog-margin-top'>
          <div className='col-lg-8 col-md-12 col-sm-12'>
            {/* Content Component Start */}
            {/* <CivilCard CardData={CardData} /> */}
            <RangeCalendar searchDateWise={searchDateWise} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
            {(blogs.length == 0 && !isloading) && <><h3 className='fw-bold text-center p-5'>No Result Found!</h3></>}
            {(isloading && blogs.length === 0) ? <Pagelayout2 /> : <BlogCard CardData={blogs} />}

            {/* Content Component End */}
            {/* <ButtonPage /> */}
          </div>
          {/* Civil right Section Start */}
          <div className='col-lg-4 col-md-12 col-sm-12'>
            <div className='card p-2'>
              {/* <CivilrightSection /> */}
              <CurrentAffair /* onDateSelect={handleDateinput} */ componentName='Blog' />
            </div>
          </div>
          {/* Civil right Section End */}
        </div>
      </div>
    </>
  )
}

export default BlogPage1;