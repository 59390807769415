import React from 'react'
import { FaRegCircleCheck } from "react-icons/fa6";
import { Link } from 'react-router-dom';

function PaymentSuccesstest({ paymentdetail, TestTitle1 }) {

    function convertToTitleCase(hyphenatedString) {
        // Replace hyphens with spaces
        let spacedString = hyphenatedString.replace(/-/g, ' ');
        // Convert to title case
        let titleCaseString = spacedString.split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ');
        return titleCaseString;
    }
    return (
        <>
            <div className='p-5 d-flex justify-content-center align-items-center' style={{ background: "white", width: "100%", height: "100vh", position: "fixed", top: "0", zIndex: "180" }}>
                <div className='text-black text-center'>
                    <FaRegCircleCheck className='text-orange' style={{ fontSize: "15vmax" }} />
                    <h1 className='py-2 fw-bold'>Payment Successful</h1>
                    <h6>{convertToTitleCase(TestTitle1)}</h6>
                    {/* <h6>{paymentdetail[0]?.CourseID}</h6> */}

                    {/* <h6>Payment ID:</h6> */}
                    <h6>Payment ID: {paymentdetail[0]?.TranscationID}</h6>
                    <div className='p-3 d-flex justify-content-center'>
                        <Link to="/StudentDashboard" style={{ textDecoration: "none" }} className='bg-orange text-white py-2 rounded-pill fs-6 px-4' >
                            done
                        </Link>
                    </div>
                </div>

            </div>
        </>
    )
}

export default PaymentSuccesstest;
