import React, { useEffect, useState } from 'react';
import "./Contact.css";
import { FaUser } from "react-icons/fa";
import { TfiEmail } from "react-icons/tfi";
import { FaPhoneAlt } from "react-icons/fa";
import { HiOutlineDocumentPlus } from "react-icons/hi2";
import { LuPenSquare } from "react-icons/lu";
import SocialComponent from '../SocialComponent/SocialComponent';
import { PiUserCircleFill } from "react-icons/pi";
import { BsFillTelephoneForwardFill } from "react-icons/bs";
import { FaLocationDot } from "react-icons/fa6";
import { useAlert } from 'react-alert';
import { BASE_URL } from '../../insightConfig/insightConfig';

function Contact({ authtoken }) {
  const [userData, setUserData] = useState({});
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [subject, setSubject] = useState('');
  const [querymessage, setQuerymessage] = useState('');
  const [error, setError] = useState('');
  const alert = useAlert();

  useEffect(() => {
    if (authtoken) {
      fetch(`${BASE_URL}/studentdashboard/${authtoken}`)
        .then(response => response.json())
        .then(data => {
          setUserData(data.data);
           
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
    }
  }, [authtoken]); // Include authtoken in dependency array to re-fetch data when it changes

  useEffect(() => {
    // Set form fields with user data after fetching
    setName(userData[0]?.FirstName || '');
    setEmail(userData[0]?.EmailId || '');
    setMobile(userData[0]?.MobileNo || '');
  }, [userData]); // Update form fields when userData changes

  const handleSubmitquery = async () => {
    if (!email.trim()) {
      alert.show('Email address is required');
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/contactus`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ name, email, mobile, subject, querymessage }),
      });

      if (!response.ok) {
        throw new Error('Query message failed');
      }

      // Reset form fields and state
      setName('');
      setEmail('');
      setMobile('');
      setSubject('');
      setQuerymessage('');
      // Show success message
      alert.show('Query message sent successfully');
    } catch (error) {
      setError('Failed to send query message. Internal Server Error');
    }
  };

  return (
    <div className='contactPage'>
      <div className='enquiry-background py-3'>
        <div className='container'>
          <div className='row'>
            <h1>Enquiry</h1>
          </div>
        </div>
      </div>
      <div className='container-fluid contactenquiry-background'>
        <div className='row'>
          <div className='col-lg-8'>
            <div className='Contact-background'>
              <h4>Submit your details to get a call back from Insight Delhi</h4>
              <div>
                <div className='Contact-box'>
                  <div className="mb-3 col-sm-12">
                    <span><FaUser /></span>
                    <input type="text" className="form-control ps-5" value={name} onChange={(e) => setName(e.target.value)} placeholder="Name" />
                  </div>
                  <div className="mb-3 col-sm-12">
                    <span><FaPhoneAlt /></span>
                    <input type="tel" className="form-control ps-5" value={mobile} onChange={(e) => setMobile(e.target.value)} placeholder="Mobile" />
                  </div>
                </div>
                <div className='Contact-box'>
                  <div className="mb-3 col-sm-12">
                    <span><TfiEmail /></span>
                    <input type="email" className="form-control ps-5" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
                  </div>
                  <div className="mb-3 col-sm-12">
                    <span><HiOutlineDocumentPlus /></span>
                    <input type="text" className="form-control ps-5" value={subject} onChange={(e) => setSubject(e.target.value)} placeholder="Subject" />
                  </div>
                </div>
              </div>
              <div className="mb-3 col-sm-12 textarea-box">
                <span><LuPenSquare /></span>
                <textarea className="form-control ps-5" value={querymessage} onChange={(e) => setQuerymessage(e.target.value)} rows="3" placeholder='Type Your enquiry here!'></textarea>
              </div>
              <div className='mb-3'>
                <button type="button" className="btn btn-submit" onClick={handleSubmitquery}>SUBMIT</button>
              </div>
            </div>
          </div>
          <div className='col-lg-4 card contactenquiryleft my-4'>
            <div className='Touch-info p-2 py-1'>
              <div className='touch-heading'>
                <h3><PiUserCircleFill className='me-1' /> Get In Touch</h3>
              </div>
              <div className='touch-detail'>
                <div className='detail1'>
                  <h5><BsFillTelephoneForwardFill className='me-1' /> Office Contact:</h5>
                  <p>011-45090051, 9818333201</p>
                </div>
                <div className='detail2'>
                  <h5><FaLocationDot className='me-1' /> Office Address:</h5>
                  <p>73, Second Floor, Bada Bazar Rd, near Popular Juice Corner, old Rajinder Nagar, New Delhi, Delhi 110060</p>
                </div>
              </div>
            </div>
            
              <SocialComponent />
            
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
