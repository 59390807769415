import React from 'react'
import { FaRegCircleCheck } from "react-icons/fa6";
import { Link, useLocation } from 'react-router-dom';

function PaymentSuccess() {
    const location = useLocation();
    const { transactionId, productTitle, moduleName, TitleHeading } = location.state || {};
    console.log(moduleName)
    return (
        <>
            <div className='p-5 d-flex justify-content-center align-items-center' style={{ background: "white", width: "100%", height: "100vh", position: "fixed", top: "0", zIndex: "180" }}>
                <div className='text-black text-center'>
                    <FaRegCircleCheck className='text-orange' style={{ fontSize: "15vmax" }} />
                    <h1 className='py-2 fw-bold text-success'>Payment Successful</h1>
                    <h6 > <span style={{ fontWeight: "700" }}>Title</span> : {productTitle}</h6>
                    <h6 ><span style={{ fontWeight: "700" }}>Payment ID</span> : {transactionId}</h6>

                    {/* <h6>Payment ID:</h6> */}
                    {/* <h6>{paymentdetail[0].TranscationID}</h6> */}
                    <div className='p-3 d-flex justify-content-center'>
                        <Link to={moduleName == 'course' ? `/course/${TitleHeading}` : `/testseries/${TitleHeading}`} state={{ moduleName }} style={{ textDecoration: "none" }} className='bg-orange text-white py-2 rounded-pill fs-6 px-4' >
                            done
                        </Link>
                    </div>
                </div>

            </div>
        </>
    )
}

export default PaymentSuccess
