import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Banner from '../Banner/Banner';
import ContentCard from '../ContentCard/ContentCard';
import Achievements from '../Achivements/Achievements';
import SlickSlider from '../Slider/SlickSlider';
import Expert from '../Expert/Expert';
import Subscribe from '../Subscribe/Subscribe';
import HomeCourse from '../HomeCourse/HomeCourse';
import HomeAbout from '../HomeAbout/HomeAbout';

function Home({setPrevPath, uniqueId, QuizSectionid, QuizSubjectid, QuizQuestionurl}) {
    useEffect(() => {
        setPrevPath(null);
      }, []);
    return (
        <>
            <Helmet>
                <title>Best Coaching Institute for UPSC/IAS Exam-Insight IAS, Delhi</title>
            </Helmet>
            <Banner QuizQuestionurl={QuizQuestionurl}/>
            <HomeCourse/>
            <HomeAbout/>
            <ContentCard />
            {/* <Achievements /> */}
            <SlickSlider />
            {/* <Expert /> */}
            {/* <Subscribe /> */}
        </>
    )
}

export default Home;