import React from 'react';
import "./CardsPage.css";
import { Link } from 'react-router-dom';
import { BsJournals } from "react-icons/bs";
import { FaUserFriends } from "react-icons/fa";
import aboutimg from "../../Image/aboutimg.png"
import { IoCalendar } from "react-icons/io5";
import { MdOutlineLanguage } from "react-icons/md";
import { HiOutlineCurrencyRupee } from "react-icons/hi";

function CardsPage({ cards, heading, heading2 }) {
    
    function isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }


    return (
        <>
            {heading === 'CIVILS COURSES' && <div className='AllCardBackground'>
                <h1 className='AllCard_heading fw-bold fs-4'>{heading}</h1>
                <div className='AllCard_Box'>
                    {cards.map((card, index) => (
                        <div className="card Allcard" key={index}>
                            <Link to={`/course/${card.TitleHeading}`} /* state={{ isEnrolled: card.isEnrolled }} key={index} */

                            >
                                <img src={isValidUrl(card.image) ? card.image : aboutimg} className="card-img-top" alt={card.CourseTitle} style={{ height: "250px" }} />
                            </Link>
                            <div className="card-body All-bg">
                                <Link to={`/course/${card.title}`} /* state={{ isEnrolled: card.isEnrolled }} key={index} */ className='text-decoration-none text-black'>
                                    {card.title ? <h6 className="fw-bold">{card.title}</h6> : <h6 className="fw-bold fs-5">{card.Title}</h6>}
                                </Link>
                                <h6 className='d-flex align-items-center'><IoCalendar className='fs-5 me-1'/>{card.time}</h6>
                                <h6 className='d-flex align-items-center'><MdOutlineLanguage className='fs-5 me-1'/>{card.language}</h6>
                                {/* <h6 className='d-flex align-items-center'><IoCalendar className='fs-5 me-1'/>{card.time}</h6> */}
                                <hr />
                                <div className="d-flex justify-content-between align-items-center">
                                    <Link to="#" className="card-link list-group-item">
                                    <h6 className='d-flex align-items-center'>{`${card.discountedPrice}`} <span className='ms-2 text-danger' style={{textDecoration: "line-through"}}>{`${card.originalPrice}`}</span></h6>
                                        {/* {card.TopicCount ? <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{`${card.TopicCount} LESSONS`}</h6> : <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{`${card.QuizCount} Tests`}</h6>} */}
                                        {/* {card.SubscriberCount && <h6 className='d-flex align-items-center'><FaUserFriends className='me-1' /> {card.SubscriberCount}</h6>} */}
                                    </Link>
                                    <Link to={`/course/${card.TitleHeading}`} /* state={{ isEnrolled: card.isEnrolled }} key={index} */ className="btn-start list-group-item">View Details</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
            {heading === 'TEST SERIES' && <div className='AllCardBackground'>
                <h1 className='AllCard_heading fw-bold fs-4'>{heading}</h1>
                <div className='AllCard_Box'>
                    {cards.map((card, index) => (
                        <div className="card Allcard" key={index}>
                            <Link to={`/testseries/${card.TitleHeading}`} className='text-decoration-none text-black'>
                                <img src={isValidUrl(card.Image) ? card.Image : aboutimg} className="card-img-top" alt={card.CourseTitle} style={{ height: "250px" }} />
                            </Link>
                            <div className="card-body All-bg">
                                <Link to={`/testseries/${card.TitleHeading}`} className='text-decoration-none text-black'>
                                    {card.CourseTitle ? <h6 className="fw-bold fs-5">{card.CourseTitle}</h6> : <h6 className="fw-bold fs-5">{card.Title}</h6>}
                                </Link>
                                <hr />
                                <div className="d-flex justify-content-between align-items-center">
                                    <Link to="#" className="card-link list-group-item">
                                        {card.TopicCount ? <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{`${card.TopicCount} LESSONS`}</h6> : <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{`${card.QuizCount} Tests`}</h6>}
                                        {card.Subscriber && <h6 className='d-flex align-items-center'><FaUserFriends className='me-1' /> {card.Subscriber} SUBSCRIBERS</h6>}
                                    </Link>
                                    <Link to={`/testseries/${card.TitleHeading}`} state={{ isEnrolled: card.isEnrolled }} className="btn-start list-group-item">START</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
            {heading === '' && <div className='AllCardBackground'>
                <h1 className='AllCard_heading fw-bold fs-4'>{heading}</h1>
                <div className='AllCard_Box'>
                    {cards.map((card, index) => (
                        <div className="card Allcard AllDashboard" key={index}>
                            <Link to={`/course/${card.TitleHeading}`} /* state={{ isEnrolled: card.isEnrolled }} key={index} */ className='text-decoration-none text-black'>
                                <img src={isValidUrl(card.Image) ? card.Image : aboutimg} className="card-img-top" alt={card.CourseTitle} style={{ height: "200px" }} />
                            </Link>
                            <div className="card-body All-bg">
                                <Link to={`/course/${card.TitleHeading}`} /* state={{ isEnrolled: card.isEnrolled }} key={index} */ className='text-decoration-none text-black'>
                                    {card.CourseTitle ? <h6 className="fw-bold overflow-hidden" style={{ height: "20px" }}>{card.CourseTitle}</h6> : <h6 className="fw-bold overflow-hidden" style={{ height: "20px" }}>{card.Title}</h6>}
                                </Link>
                                <hr />
                                <div className="d-flex justify-content-between align-items-center">
                                    <Link to="#" className="card-link list-group-item">
                                        {card.TopicCount ? <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{`${card.TopicCount} LESSONS`}</h6> : <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{`${card.QuizCount} Tests`}</h6>}
                                        {card.Subscriber && <h6 className='d-flex align-items-center'><FaUserFriends className='me-1' /> {card.Subscriber} SUBSCRIBERS</h6>}
                                    </Link>
                                    <Link to={`/course/${card.TitleHeading}`} /* state={{ isEnrolled: card.isEnrolled }} key={index} */ className="btn-start list-group-item">START</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
            {heading2 === '' && <div className='AllCardBackground'>
                <h1 className='AllCard_heading fw-bold fs-4'>{heading}</h1>
                <div className='AllCard_Box'>
                    {cards.map((card, index) => (
                        <div className="card Allcard AllDashboard" key={index}>
                            <Link to={`/testseries/${card.TitleHeading}`} /* state={{ isEnrolled: card.isEnrolled }} */ className='text-decoration-none text-black'>
                                <img src={isValidUrl(card.Image) ? card.Image : aboutimg} className="card-img-top" alt={card.CourseTitle} style={{ height: "200px" }} />
                            </Link>
                            <div className="card-body All-bg">
                                <Link to={`/testseries/${card.TitleHeading}`} /* state={{ isEnrolled: card.isEnrolled }} */ className='text-decoration-none text-black'>
                                    {card.CourseTitle ? <h6 className="fw-bold overflow-hidden" style={{ height: "20px" }}>{card.CourseTitle}</h6> : <h6 className="fw-bold overflow-hidden" style={{ height: "20px" }}>{card.Title}</h6>}
                                </Link>
                                <hr />
                                <div className="d-flex justify-content-between align-items-center">
                                    <Link to="#" className="card-link list-group-item">
                                        {card.TopicCount ? <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{`${card.TopicCount} LESSONS`}</h6> : <h6 className='d-flex align-items-center'><BsJournals className='me-1' />{`${card.QuizCount} Tests`}</h6>}
                                        {card.SubscriberCount && <h6 className='d-flex align-items-center'><FaUserFriends className='me-1' /> {card.SubscriberCount} SUBSCRIBERS</h6>}
                                    </Link>
                                    <Link to={`/testseries/${card.TitleHeading}`} /* state={{ isEnrolled: card.isEnrolled }} */ className="btn-start list-group-item">START</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>}
        </>
    )
}

export default CardsPage;