import React, { useEffect, useState } from 'react';
import "./QuestionLayout.css"
import Questionsummary from '../Questionsummary/Questionsummary';
import Questionpreview from '../Questionpreview/Questionpreview';
import ResultPreview from '../../ResultPreview/ResultPreview';
import { Link, useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { finalsubmittest } from '../../DynamicCourse/api';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { RiErrorWarningLine } from "react-icons/ri";

function QuestionLayoutTestseriesContinue({ testQuestion, prevPath, teststatus, sessionid,testDuration, setsessionid,testSn, testpackageid, authtoken, setPositiveScore, setNegativeScore, setcorrectdataarray, setincorrectdataarray, PositiveScore, NegativeScore, correctAnswers, incorrectAnswers}) {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
 
  const [useranswersheet, setAnswersheet] = useState(() => {
    const storedAnswersheet = localStorage.getItem(`${testSn}${authtoken}answersheet`);
    return storedAnswersheet ? JSON.parse(storedAnswersheet) : [];
  });
  const alert = useAlert();

  
  // const handle = useFullScreenHandle();
  //   useEffect(() => {
  //       handle.enter();
  //       const preventRightClick = (event) => {
  //           if (event.button === 2) {
  //               event.preventDefault();
  //           }
  //       };

  //       document.addEventListener('contextmenu', preventRightClick);

  //       return () => {
  //           document.removeEventListener('contextmenu', preventRightClick);
  //       };
  //   }, []);

  const navigate = useNavigate();
  const [queslength, setqueslength] = useState(() => {
    const storedQuestionArrayString = localStorage.getItem(`${testSn}${authtoken}questionArray`);
    return storedQuestionArrayString ? JSON.parse(storedQuestionArrayString) : [];
  })
  
  const [finish, setfinish] = useState(false);
  const [clearoption, setClearOption] = useState(() => {
    const storedClearOption = localStorage.getItem(`${testSn}${authtoken}${currentQuestionIndex}clearoption`);
    return storedClearOption ? JSON.parse(storedClearOption) : null;
  }); 
  const [userstorage, setUserstorage] = useState(() => {
    const userdata = localStorage.getItem(`${testSn}${authtoken}userdata`);
    return userdata ? JSON.parse(userdata) : [];
  });

  //  (userstorage)
  const [unanswereddata, setunanswereddata] = useState(() => {
    const nouserdata = localStorage.getItem(`${testSn}${authtoken}nouserdata`);
    return nouserdata ? JSON.parse(nouserdata) : [];
  });

  //  (unanswereddata)
  const [markanswereddata, setmarkanswereddata] = useState(() => {
    const markuserdata = localStorage.getItem(`${testSn}${authtoken}markuserdata`);
    return markuserdata ? JSON.parse(markuserdata) : [];
  });
  //  (markanswereddata)

  useEffect(() => {
    // Combine userstorage and unanswereddata into answersheet array
    const updatedAnswersheet = [...userstorage, ...unanswereddata].sort((a, b) => a.questionindex - b.questionindex);
    setAnswersheet(updatedAnswersheet);
    localStorage.setItem(`${testSn}${authtoken}answersheet`, JSON.stringify(updatedAnswersheet));
  }, [userstorage, unanswereddata, testSn, authtoken]);

  const updateUnansweredData = () => {
    const userDataJSON = localStorage.getItem(`${testSn}${authtoken}userdata`);
    const nouserDataJSON = localStorage.getItem(`${testSn}${authtoken}nouserdata`);
    if (userDataJSON && nouserDataJSON) {
      const userData = JSON.parse(userDataJSON);
      const nouserData = JSON.parse(nouserDataJSON);
      const answeredQuestions = userData.map(data => data.questionindex);
      const remainingUnanswered = nouserData.filter(data => !answeredQuestions.includes(data.questionindex));
      setunanswereddata(remainingUnanswered);
      const unuserAnswerData = nouserData.find(userdata => userdata.questionindex === currentQuestionIndex);
      if (unuserAnswerData) {
        setClearOption(null);
      }
    }
  };

  const noattemedAnswer = (currentQuestionIndex, answer, answerindex, answercheck) => {
    // Retrieve existing user data array from local storage
    const existingUserDataJSON = localStorage.getItem(`${testSn}${authtoken}nouserdata`);
    let existingUserData = [];
    if (existingUserDataJSON) {
      existingUserData = JSON.parse(existingUserDataJSON);
    }

    // Update or append the user's answer for the current question
    const updatedUserData = existingUserData.map(nouserdata => {
      if (nouserdata.questionindex === currentQuestionIndex) {
        return { ...nouserdata, QNo: queslength[currentQuestionIndex].QNo, question: queslength[currentQuestionIndex].Question, useranswer: answer, useranswerindex: answerindex, useranswercheck: answercheck, options: queslength[currentQuestionIndex].options, description: queslength[currentQuestionIndex].Description };
      }
      return nouserdata;
    });

    // If no user data exists for the current question, append it
    if (!updatedUserData.some(nouserdata => nouserdata.questionindex === currentQuestionIndex)) {
      updatedUserData.push({ questionindex: currentQuestionIndex, QNo: queslength[currentQuestionIndex].QNo, question: queslength[currentQuestionIndex].Question, useranswer: answer, useranswerindex: answerindex, useranswercheck: answercheck, correctanswer: queslength[currentQuestionIndex].Answer, options: queslength[currentQuestionIndex].options, description: queslength[currentQuestionIndex].Description });
    }

    // Store the updated user data array back into local storage
    localStorage.setItem(`${testSn}${authtoken}nouserdata`, JSON.stringify(updatedUserData));
    setunanswereddata(updatedUserData)

    const userAnswerData = updatedUserData.find(userData => userData.questionindex === currentQuestionIndex);
    if (userAnswerData) {
      setClearOption(userAnswerData.useranswerindex);
      localStorage.setItem(`${testSn}${authtoken}${currentQuestionIndex}clearoption`, userAnswerData.useranswerindex);
    }
  }; 

  // const handleNextQuestion = () => {
  //   if (!clearoption) {
  //     noattemedAnswer(currentQuestionIndex, '', null)
  //   }

  //   if (currentQuestionIndex < queslength.length - 1) {
  //     setCurrentQuestionIndex(currentQuestionIndex + 1);
  //     const userDataJSON = localStorage.getItem('userdata');
  //     if (userDataJSON) {
  //       const userData = JSON.parse(userDataJSON);
  //       //  ("next " + userstorage)
  //       userData.map(item =>
  //         //  ("nextindex  " + item[currentQuestionIndex].useranswerindex)
  //         setClearOption(queslength[item[currentQuestionIndex].questionindex - 1].options.indexOf(item[currentQuestionIndex - 1].useranswer))
  //       )
  //     }
  //     // setClearOption(null)
  //   }

  // };


  const handleNextQuestion = () => {
    const isoption = localStorage.getItem(`${testSn}${authtoken}${currentQuestionIndex}clearoption`)
    if (!isoption) {
      noattemedAnswer(currentQuestionIndex, '', null);
    }

    // Check if there are more questions to navigate to
    if (currentQuestionIndex < queslength.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      const userDataJSON = localStorage.getItem(`${testSn}${authtoken}userdata`);
      if (userDataJSON) {
        const userData = JSON.parse(userDataJSON);
        // Find the user's answer for the next question
        const nextQuestionAnswer = userData.find(data => data.questionindex === currentQuestionIndex + 1);
        if (nextQuestionAnswer) {
          // Set the clear option to the user's selected option for the next question
          setClearOption(nextQuestionAnswer.useranswerindex);
        } else {
          // If the user hasn't answered the next question, clear the option
          setClearOption(null);
        }
      }
    } else {
      // If all questions have been answered, redirect to the first question
      // setCurrentQuestionIndex(0);
      const userDataJSON = localStorage.getItem(`${testSn}${authtoken}userdata`);
      if (userDataJSON) {
        const userData = JSON.parse(userDataJSON);
        // Find the user's answer for the first question
        const firstQuestionAnswer = userData.find(data => data.questionindex === 0);
        if (firstQuestionAnswer) {
          // Set the clear option to the user's selected option for the first question
          setClearOption(firstQuestionAnswer.useranswerindex);
        } else {
          // If the user hasn't answered the first question, clear the option
          setClearOption(null);
        }
      }
    }


  };


  const markAnswer = (currentQuestionIndex, answer, answerindex) => {
    // Retrieve existing user data array from local storage
    const existingUserDataJSON = localStorage.getItem(`${testSn}${authtoken}markuserdata`);
    let existingUserData = [];
    if (existingUserDataJSON) {
      existingUserData = JSON.parse(existingUserDataJSON);
    }

    // Update or append the user's answer for the current question
    const updatedUserData = existingUserData.map(markuserdata => {
      if (markuserdata.questionindex === currentQuestionIndex) {
        return { ...markuserdata, useranswer: answer, useranswerindex: answerindex };
      }
      return markuserdata;
    });

    // If no user data exists for the current question, append it
    if (!updatedUserData.some(markuserdata => markuserdata.questionindex === currentQuestionIndex)) {
      updatedUserData.push({ questionindex: currentQuestionIndex, useranswer: answer, useranswerindex: answerindex });
    }

    // Store the updated user data array back into local storage
    localStorage.setItem(`${testSn}${authtoken}markuserdata`, JSON.stringify(updatedUserData));
    setmarkanswereddata(updatedUserData)
  };

  const handleMarkQuestion = () => {
    // Check if the current question has been answered
    // const isOptionSelected = userstorage.some(data => data.questionindex === currentQuestionIndex);
    const isoption = localStorage.getItem(`${testSn}${authtoken}${currentQuestionIndex}clearoption`)
    if (!isoption) {
      //  ("Answer1 i am not selected answer" + !isOptionSelected)
      noattemedAnswer(currentQuestionIndex, '', null);
      markAnswer(currentQuestionIndex, '', null)
      // const nouserDataJSON = localStorage.getItem('nouserdata');
      // const nouserData = JSON.parse(nouserDataJSON);
      // const isDifferentIndex = nouserData.map(data => data);
      //  ("array1   " + isDifferentIndex)
      // const updatedMarkedData = [...markanswereddata, isDifferentIndex[currentQuestionIndex]] || [];
      // localStorage.setItem('markuserdata', JSON.stringify(updatedMarkedData));
      // setmarkanswereddata(updatedMarkedData);
      //  ("Question marked");
    }

    // If the question has been answered, update the markanswereddata
    if (isoption) {
      //  ("Answer1 i am selected answer" + isOptionSelected)
      const userDataJSON = localStorage.getItem(`${testSn}${authtoken}userdata`);
      const userData = JSON.parse(userDataJSON);
      // const isDifferentIndex2 = userData.map(data => data);
      if (userDataJSON) {
        userData.map(item => {
          // setClearOption(item.useranswerindex)
          if (item.questionindex === currentQuestionIndex) {
            markAnswer(item.questionindex, item.useranswer, item.useranswerindex)
          }
        });
      }
      // const updatedMarkedData2 = [...markanswereddata, isDifferentIndex2[currentQuestionIndex]] || [];
      // localStorage.setItem('markuserdata', JSON.stringify(updatedMarkedData2));
      // setmarkanswereddata(updatedMarkedData2);
    }
    if (currentQuestionIndex < queslength.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    }
  };

  const loadPreviousAnswer = () => {
    // Retrieve user data from local storage
    const userDataJSON = localStorage.getItem(`${testSn}${authtoken}userdata`);
    if (userDataJSON) {
      const userData = JSON.parse(userDataJSON);
      setUserstorage(userData);

      // Check if the previous question index is within the valid range
      const previousIndex = currentQuestionIndex - 1;
      if (previousIndex >= 0) {
        // Check if there is a user answer for the previous question
        const previousUserData = userData.find(data => data.questionindex === previousIndex);
        if (previousUserData) {
          // If there is a user answer for the previous question, set the clear option
          const userAnswerIndex = previousUserData.useranswerindex;
          if (userAnswerIndex !== null && userAnswerIndex !== undefined) {
            setClearOption(userAnswerIndex);
          } else {
            setClearOption(null);
          }
        } else {
          // If there is no user answer for the previous question, clear the selection
          setClearOption(null);
        }
      } else {
        // If the previous question index is out of range, clear the selection
        setClearOption(null);
      }
    } else {
      // If there is no user data in local storage, clear the selection
      setClearOption(null);
    }
  };


  const handlePreviousQuestion = () => {
    loadPreviousAnswer()

    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const saveUserAnswer = (currentQuestionIndex, answer, answerindex, answercheck) => {
    // Retrieve existing user data array from local storage
    const existingUserDataJSON = localStorage.getItem(`${testSn}${authtoken}userdata`);
    let existingUserData = [];
    if (existingUserDataJSON) {
      existingUserData = JSON.parse(existingUserDataJSON);
    }

    // Update or append the user's answer for the current question
    const updatedUserData = existingUserData.map(userData => {
      if (userData.questionindex === currentQuestionIndex) {
        return { ...userData, QNo: queslength[currentQuestionIndex].QNo, question: queslength[currentQuestionIndex].Question, useranswer: answer, useranswerindex: answerindex, useranswercheck: answercheck , options: queslength[currentQuestionIndex].options, description: queslength[currentQuestionIndex].Explanation };
      }
      return userData;
    });

    // If no user data exists for the current question, append it
    if (!updatedUserData.some(userData => userData.questionindex === currentQuestionIndex)) {
      updatedUserData.push({ questionindex: currentQuestionIndex, QNo: queslength[currentQuestionIndex].QNo, question: queslength[currentQuestionIndex].Question, useranswer: answer, useranswerindex: answerindex, useranswercheck: answercheck, correctanswer: queslength[currentQuestionIndex].Answer, options: queslength[currentQuestionIndex].options, description: queslength[currentQuestionIndex].Explanation });
    }

    // Store the updated user data array back into local storage
    localStorage.setItem(`${testSn}${authtoken}userdata`, JSON.stringify(updatedUserData));
    setUserstorage(updatedUserData)
    // Set clearoption based on the user's answer index
    const userAnswerData = updatedUserData.find(userData => userData.questionindex === currentQuestionIndex);
    if (userAnswerData) {
      setClearOption(userAnswerData.useranswerindex);
      localStorage.setItem(`${testSn}${authtoken}${currentQuestionIndex}clearoption`, userAnswerData.useranswerindex);
    }
    updateUnansweredData();
  };

  

  const handlecheckanswer = async() => {
    // Clear previous scores
  
    // Retrieve user data from local storage
    const userDataJSON = localStorage.getItem(`${testSn}${authtoken}userdata`);
    const userData = JSON.parse(userDataJSON);
  
    // Initialize arrays to store correct and incorrect answers
    let CorrectAnswers = [];
    let inCorrectAnswers = [];
    let positiveScore = 0;
    let negativeScore = 0;
  
    // Calculate scores
    if (userData) {
      userData.forEach(data => {
        const correctAnswer = queslength[data.questionindex].Answer;
        // Check if user answered the question
        if (data.useranswer !== null && data.useranswer !== undefined) {
          if (data.useranswercheck === correctAnswer) {
            positiveScore += 2;
            CorrectAnswers.push({
              questionIndex: data.questionindex,
              QNo: data.QNo,
              question: data.question,
              useranswer: data.useranswer, // Use data.useranswer instead of data.useranswercheck
              useranswercheck: data.useranswercheck, // Use data.useranswercheck
              correctAnswer: correctAnswer
            });
            setcorrectdataarray(CorrectAnswers);
          } else {
            negativeScore += 0.67;
            inCorrectAnswers.push({
              questionIndex: data.questionindex,
              QNo: data.QNo,
              question: data.question,
              useranswer: data.useranswer, // Use data.useranswer instead of data.useranswercheck
              useranswercheck: data.useranswercheck, // Use data.useranswer instead of data.useranswercheck
              correctAnswer: correctAnswer
            });
            setincorrectdataarray(inCorrectAnswers);
          }
        }
      });
    }
    setPositiveScore(positiveScore);
    setNegativeScore(parseFloat(negativeScore.toFixed(2)));
    
     
  
    // Store correct and incorrect answers in local storage
    localStorage.setItem(`${testSn}correctAnswers`, JSON.stringify(CorrectAnswers));
    localStorage.setItem(`${testSn}incorrectAnswers`, JSON.stringify(inCorrectAnswers));
  
    // Store scores in local storage
    localStorage.setItem(`${testSn}positiveScore`, JSON.stringify(positiveScore));
    localStorage.setItem(`${testSn}negativeScore`, JSON.stringify(negativeScore));
    try {
      // Call the finalsubmittest function with the necessary parameters
      const responseData = await finalsubmittest({
        // attempted: userstorage.length,
        // correct: CorrectAnswers.length,
        // answersheet: JSON.stringify(userstorage),
        attemptedQuestions: userstorage.length,
        correctQuestions: CorrectAnswers.length,
        wrongQuestions: inCorrectAnswers.length,
        correctMarks: positiveScore,
        wrongMarks: negativeScore,
        answerSheet: JSON.stringify(useranswersheet),
        testsession: sessionid,
        testpackageid,
        teststatus, 
        authtoken
      });
  
      // Log response data
      // clg
      // Navigate to the final result page
      navigate('/testseries/finalresult');
  
      // Show success alert
      alert.show('Test submitted successfully');
    } catch (error) {
      // Show error alert if submission fails
      alert.show('Test submission failed. Please try again.');
    }
  };
  
  
  

  const clearAnswer = () => {
    // Retrieve userData array from local storage
    const userDataJSON = localStorage.getItem(`${testSn}${authtoken}userdata`);
    if (userDataJSON) {
      const userData = JSON.parse(userDataJSON);
      // Remove the user's answer for the current question
      const updatedUserData = userData.filter(data => data.questionindex !== currentQuestionIndex);
      // Update the userData array in local storage
      localStorage.setItem(`${testSn}${authtoken}userdata`, JSON.stringify(updatedUserData));
      // Clear the selected option
      setUserstorage(updatedUserData);
      // setClearOption(null);
    }

    // Retrieve nouserData array from local storage
    const nouserDataJSON = localStorage.getItem(`${testSn}${authtoken}nouserdata`);
    if (nouserDataJSON) {
      const nouserData = JSON.parse(nouserDataJSON);
      // Remove the user's answer for the current question
      const updatedUserData1 = nouserData.filter(data => data.questionindex !== currentQuestionIndex);
      // Update the nouserData array in local storage
      localStorage.setItem(`${testSn}${authtoken}nouserdata`, JSON.stringify(updatedUserData1));
      // Clear the selected option
      setunanswereddata(updatedUserData1);
      // setClearOption(null);
    }

    // Retrieve markuserData array from local storage
    const markuserDataJSON = localStorage.getItem(`${testSn}${authtoken}markuserdata`);
    if (markuserDataJSON) {
      const markuserData = JSON.parse(markuserDataJSON);
      // Remove the user's answer for the current question
      const updatedUserData3 = markuserData.filter(data => data.questionindex !== currentQuestionIndex);
      // Update the markuserData array in local storage
      localStorage.setItem(`${testSn}${authtoken}markuserdata`, JSON.stringify(updatedUserData3));
      // Clear the selected option
      setmarkanswereddata(updatedUserData3);
      // setClearOption(null);
    }
  };

  // Function to handle clearing the answer
  const handleClear = () => {
    clearAnswer();
    const isclear = localStorage.removeItem(`${testSn}${authtoken}${currentQuestionIndex}clearoption`);
    setClearOption(isclear)
  };

  function showCustomAlert() {
    document.getElementById('customAlert').style.display = 'flex';
  }
  
  function hideCustomAlert() {
    document.getElementById('customAlert').style.display = 'none';
  }

  function confirmBtn(){
    hideCustomAlert()
    // setfinish(true)
    localStorage.removeItem(`${testSn}${authtoken}userdata`);
    setUserstorage([])
    navigate(prevPath)
  }

  function cancelBtn(){
    hideCustomAlert()
  }

  const showAlert = () => {

    showCustomAlert()
};


  return ( 
    <>
     {/* <FullScreen handle={handle}> */}
     <div id="customAlert" class="custom-alert" style={{zIndex: "165"}}>
    <div class="custom-alert-content">
      <span class="custom-alert-icon">⚠️</span>
      <p>Are you sure you want to quit the test?</p>
      <div class="custom-alert-buttons">
        <button onClick={confirmBtn} id="confirmBtn">Yes</button>
        <button onClick={cancelBtn} id="cancelBtn">No</button>
      </div>
    </div>
  </div>

      {/* {queslength.length === 0 ? <>
          <div className='container-fluid d-flex justify-content-center align-items-center p-5'>
              <div className='row d-flex flex-column justify-content-cneter align-items-center' style={{width: "80%"}}>
              <RiErrorWarningLine className='text-danger' style={{fontSize: "12vmax"}} />
              <h4 className='text-center'>No Question</h4>
              </div>
          </div>
      
       </>: */}
       <div className='container-fluid p-3' style={{position: "absolute", top: "0", background: "white", zIndex: "100"}}> 
        <div className='row' style={{height: "100vh"}}>
          <div className='col-lg-8'>
            <Questionpreview prevPath={prevPath} finish={finish} testSn={testSn} testDuration={testDuration} currentQuestionIndex={currentQuestionIndex} authtoken={authtoken} testpackageid={testpackageid} queslength={queslength} unanswereddata={unanswereddata} setqueslength={setqueslength} clearoption={clearoption} userstorage={userstorage} setClearOption={setClearOption} setUserstorage={setUserstorage} saveUserAnswer={saveUserAnswer} setsessionid={setsessionid}/>
            {!finish ? <div className='p-3 d-flex justify-content-betweenn flex-wrap'>
              <div className='col-lg-8 py-1 pe-3 col-md-8 col-sm-8 d-flex align-items-start flex-nowrap'>
                {currentQuestionIndex <= 0 ? <></> : <button type="button" className="btn bg-Question-white btn-md me-3" onClick={handlePreviousQuestion}>Previous</button>}
                <button type="button" className="btn bg-Question-white btn-md" onClick={handleClear}>clear</button>
                <button type="button" className="btn bg-Question-white btn-md ms-3" style={{whiteSpace: "nowrap"}} onClick={handleMarkQuestion}>Mark for Review</button>
                {/* <button type="button" className="btn bg-Question-white btn-md ms-2">Mark for Review</button> */}
              </div>
              <div className='col-lg-4 py-1 col-md-2 col-sm-4 d-flex align-items-start justify-content-end'>
              <button type="button" className="btn bg-Question-white btn-md me-4" onClick={showAlert}>Test Quit</button>
              <button type="button" className={(currentQuestionIndex + 1) === queslength.length ? "btn disable bg-Question-white btn-md" : "btn bg-Question-white btn-md"} onClick={handleNextQuestion}>Save & Next</button>
              </div>
            </div> :
              <div className='p-2 d-flex justify-content-around'>
                <Link type="button" className="btn bg-Question-white btn-md" onClick={handlecheckanswer} to="#">Final Submit</Link>
              </div>}
            {/* correct answer: -{PositiveScore}<br /> */}
            {/* wrong answer: -{NegativeScore} */}
          </div>
          <div className='col-lg-4 p-3 card'>
            <Questionsummary testQuestion={testQuestion} setfinish={setfinish} queslength={queslength} selectedanswer={userstorage} unanswereddata={unanswereddata} markanswereddata={markanswereddata} setCurrentQuestionIndex={setCurrentQuestionIndex} currentQuestionIndex={currentQuestionIndex} setClearOption={setClearOption} />
          </div>
        </div>
      </div>
      {/* } */}
      {/* </FullScreen> */}
    </>
  )
}

export default QuestionLayoutTestseriesContinue;