import React from 'react';
import { Helmet } from 'react-helmet-async';
import "../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection.css";
import CivilTopSection from '../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection';
import userImage from "../../../Image/user.png";
import aboutImage from "../../../Image/aboutbuilding.jpg";
import CivilrightSection from '../../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection';
import Content from '../../AllPageContent/AllPageContent';
import ButtonPage from '../../ButtonPage/ButtonPage';
import CurrentAffair from '../../CurrentAffair/CurrentAffair';

function Civilabout() {
  const Buttonname = "Multiplebutton";
  const componentName = "currentaffairs";

  // Banner Content For CivilAbout Page
  const data = {
    subacademy: 'ABOUT US',
    academyName: 'ABOUT US',
    userImage: userImage,
    userName: 'Priya Rathore',
    Image: aboutImage
  };

  // Content For CivilAbout Page
  const paragraphstatic0 = [
    {
      text: "Note: Founded nearly four decades ago by SRIRAM Sir, SRIRAM’s IAS has established itself as one of India’s premier institutes for Civil Services preparation. With a steadfast vision to become a Centre of Excellence, we are dedicated to our mission of developing knowledgeable and innovative leaders.",
      styles: [
        { word: 'Note: Founded nearly four decades ago by SRIRAM Sir, SRIRAM’s IAS has established itself as one of India’s premier institutes for Civil Services preparation. With a steadfast vision to become a Centre of Excellence, we are dedicated to our mission of developing knowledgeable and innovative leaders.', style: 'font-weight: bold; color: #db261f;' },
        { word: 'SRIRAM’s IAS', style: 'font-weight: bold; color: #26156d;' }
      ]
    }
  ];

  // Content For CivilAbout Page
  const paragraphstatic = [
    {
      text: "For over 39 years, we've been mentoring candidates preparing for the Civil Services Examination, guiding them on their journey to prestigious government roles. Our experienced team understands the dynamic nature of UPSC Civil Services examination as we continuously innovate to adapt to the changing demands of the exam. ",
      styles: [
        { word: 'Shri S. Baliyan', style: 'font-weight: bold;' },
        { word: 'History and Art and Culture', style: 'font-weight: bold;' },
        { word: '‘A Compendium of Indian Art & Culture’', style: 'font-weight: bold; color: #C00000;' }
      ]
    }
  ];

  // Content For CivilAbout Page
  const paragraphstaticcopy = [
    {
      text: "Sriram’s IAS recognizes the critical role that faculty play in shaping future leaders. Our faculty team is not only highly experienced but also deeply knowledgeable, with a strong command of the UPSC pattern and trends.",
      styles: [
        { word: 'Shri S. Baliyan', style: 'font-weight: bold;' },
        { word: 'History and Art and Culture', style: 'font-weight: bold;' },
        { word: '‘A Compendium of Indian Art & Culture’', style: 'font-weight: bold; color: #C00000;' }
      ]
    }
  ];

  const paragraphstatic1 = [
    {
      text: "Most of our faculty consists of in-house experts, complemented by distinguished professors and experienced public servants from Civil and Defence background. This combination provides our students with a robust academic foundation coupled with practical and real-world insights.",
      styles: [
        { word: 'Shri S. Baliyan', style: 'font-weight: bold;' },
        { word: 'History and Art and Culture', style: 'font-weight: bold;' },
        { word: '‘A Compendium of Indian Art & Culture’', style: 'font-weight: bold; color: #C00000;' }
      ]
    }
  ]

  const paragraphstatic1copy = [
    {
      text: "Each year, we get the privilege of engaging with thousands of talented young minds. This continuous interaction enables us to continually refine our approach, ensuring that we consistently enhance our methods and remain at the forefront of educational excellence.",
      styles: [
        { word: 'Shri S. Baliyan', style: 'font-weight: bold;' },
        { word: 'History and Art and Culture', style: 'font-weight: bold;' },
        { word: '‘A Compendium of Indian Art & Culture’', style: 'font-weight: bold; color: #C00000;' }
      ]
    },
    {
      text: "Join us at Sriram’s IAS and start your journey from an aspiring student to a successful leader serving India as a Civil Servant. ",
      styles: [
        { word: 'Shri S. Baliyan', style: 'font-weight: bold;' },
        { word: 'History and Art and Culture', style: 'font-weight: bold;' },
        { word: '‘A Compendium of Indian Art & Culture’', style: 'font-weight: bold; color: #C00000;' }
      ]
    }
  ]

  const paragraphstatic2 = [
    {
      text: "The strategy of SRIRAM's IAS for making students benefit immensely by their association with us is",
      styles: [{
        word: 'The strategy of SRIRAMs IAS for making students benefit immensely by their association with us is', style: 'font-weight: bold;'
      },
      {
        word: "SRIRAM's IAS", style: 'font-weight: bold; color: #db261f;'
      }],
      lists: [
        ' Exam-oriented classroom coaching.',
        'Study material that is indispensable.',
        'Relevant information in the form of Q&As, MCQs and other channels.',
        'No dumbing down in the classroom because learning is interactive and accountable process.',
        'Every teacher everywhere has answers but SRIRAMs IAS teachers have CORRECT ANSWERS.',
        'Our notice board is an effective way of briefing the students on the most important current events and analysis - selected from all top newspapers from the country like The Indian Express, Times of India, Hindustan Times and some pink papers like Economic Times.',
        'We are the only institution that gives a three page digital handout everyday. Analyzing all current events contained in The Hindu newspaper Delhi edition.',
        'Everday our students are familiarized with MCQs so that their preparation in the Preliminary Examination is on a sure footing.',
        'Our Test Series is of immense value as thousands of discerning student would vouch for.',
        'Our students are given AAA study material for atleast 50 GENERAL ESSAYS in the course of the coaching programme.',
        'Our monthly current affairs magazine adds decisive value to the aspirants preparation.',
        'Everyday students are given practice question which they answer for evaluation by our experienced team.',
        'Our research team consists of young students from prestigious universities who have ample experience with the examination process including the Personality Test.',
      ]
    }
  ]
  const paragraphstatic3 = [
    {
      text: "Students are continously interacting with the teachers before and after the class; by email; and in the faculty room and on WhatsApp.",
      styles: [{
        word: 'SRIRAMs IAS is at your service till you are in Civil Service.', style: 'font-weight: bold;'
      }],
    },
    {
      text: "SRIRAMs IAS is at your service till you are in Civil Service.",
      styles: [{
        word: 'SRIRAMs IAS is at your service till you are in Civil Service.', style: 'font-weight: bold;'
      }],
    }
  ]

  const paragraphstatic4 = [
    {
      text: "",
      styles: [{
        word: 'Why Sriram’s IAS', style: 'font-weight: bold;'
      }],
      lists: [
        'Dedicated and Experienced Faculties',
        'Comprehensive Coverage of UPSC Syllabus',
        'Well structured and updated Class Notes and books',
        'Personalized one-to-one Mentoring',
        'Recorded lectures availability for future reference and revision',
        'Hand Written Notes for ready reference'
      ]
    }
  ]

  const paragraphstatic5 = [
    {
      text: "",
      styles: [{
        word: 'Why Sriram’s IAS', style: 'font-weight: bold;'
      }],
      lists: [
        'Regular Workshops with UPSC Toppers’ and retired Bureaucrats',
        'Continuous assessments through Prelims and Mains Test Series',
        'Exclusive sessions for doubt clearance',
        'Weekly Current Affairs Session',
        'Monthly Current Affairs Magazine',
        'Proven track record with consistent top results'
      ]
    }
  ]

  return (
    <>
      <Helmet>
        <title>About SRIRAMs IAS Coaching Institute, Delhi</title>
        <meta name="description" content="SRIRAM’s IAS has emerged as one of the leading institutes for UPSC in India with the vision to be the Centre of Excellence and the mission to develop IAS, IPS"></meta>
        <meta name="keywords" content="SRIRAM’s IAS,SRIRANGAM SRIRAM, sriramias, sriram ias, shriram ias, shriramias, shri ram ias"></meta>
      </Helmet>
      {/* Civil About Section Start */}
      <section className='civilabout'>
        {/* Civil Top Section Start */}
        <CivilTopSection
          subacademy={data.subacademy}
          academyName={data.academyName}
          userImage={data.userImage}
          userName={data.userName}
          Image={data.Image}
        />
        {/* Civil Top Section End */}
      </section>
      <div className='Civil-section bg-light' style={{ backgroundImage: "" }}>
        <div className='civil-row'>
          <div className='col-lg-12 col-md-12 col-sm-12 me-2'>
            {/* Content Component Start */}
            <div className='about-note wow animate__animated animate__fadeInDown animate__bounceOut animate__slow'>
              <Content paragraphstatic={paragraphstatic0} />
            </div>
            <div className='d-flex'>
              <div className='col-lg-8'>
                <div className='about-main-info'>
                  <Content paragraphstatic={paragraphstatic} />
                </div>
                <div className='about-main-info'>
                  <Content paragraphstatic={paragraphstaticcopy} />
                </div>
                <div className=''>
                  {/* <img src={aboutImage} style={{height: "305px", width: "450px"}} alt=''/> */}
                </div>
             
              <div className='about-main-info'>
                <Content paragraphstatic={paragraphstatic1} />
              </div>
              <div className='about-main-info'>
                <Content paragraphstatic={paragraphstatic1copy} />
              </div>
              </div>
              <div className='d-flex col-lg-4 flex-column'>
                <div className='p-4'>
                <img src='https://sriramsias.com/images/about1.jpg' style={{width: "100%"}} alt=''/>
                </div>
                <div className='p-4'>
                <img src='https://sriramsias.com/images/about2.jpg' style={{width: "100%"}} alt=''/>
                </div>
                <div className='p-4'>
                <img src='https://sriramsias.com/images/about2.jpg' style={{width: "100%"}} alt=''/>
                </div>
              </div>
              </div>
              {/* <div className='about-pointer'> */}
              {/* <Content paragraphstatic={paragraphstatic2} /> */}
              {/* </div> */}
              {/* <Content paragraphstatic={paragraphstatic3} /> */}
              {/* Content Component End */}
              {/* <ButtonPage Pagename={Buttonname} /> */}
              {/* <div className='continer-fluid'> */}
              {/* <div className='row'>
                <div className='col-lg-6 p-3'>
                    <div className='card aboutus-card-blue p-4 pb-5' style={{background: "#26156d", color: "white"}}>
                      <h1 className='fw-bold'>OUR VISION</h1>
                      <p>To be the Center of Excellence to ensure our students are successful and that achieve their aspirations within a time frame.</p>
                    </div>
                </div>
                <div className='col-lg-6 p-3'>
                  <div className='card aboutus-card-red p-4' style={{background: "#db261f", color: "white"}}>
                    <h1 className='fw-bold'>OUR MISSION</h1>
                    <p>To empower aspirants of Civil Services to excel by Imparting to them right and relevant information and perspective. The aim is to help them acquire appropriate analytical skills to be on their own.</p>
                  </div>
                </div>
              </div> */}
              <div className="row bg-light py-3">
                <div className="col-lg-6 col-md-12 col-sm-12 p-3">
                  <div className="card2">
                    <img src="https://sriramsias.com/images/about1.jpg" alt="Profile Image" />
                    <div className="content">
                      {/* <div className='card p-4' style={{background: "#db261f", color: "white"}}> */}
                      <h1 className='fw-bold'>OUR MISSION</h1>
                      <p>To empower aspirants of Civil Services to excel by Imparting to them right and relevant information and perspective. The aim is to help them acquire appropriate analytical skills to be on their own.</p>
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 p-3">
                  <div className="card2">
                    <img src="https://sriramsias.com/images/about2.jpg" alt="Profile Image" />
                    <div className="content">
                      <h1 className='fw-bold'>OUR MISSION</h1>
                      <p>To empower aspirants of Civil Services to excel by Imparting to them right and relevant information and perspective. The aim is to help them acquire appropriate analytical skills to be on their own.</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}


              {/* <div className='container'> */}
              <div className='row'>
                <div className='col-lg-3 col-md-6 col-sm-12 py-3'>
                  <div className='text-center about-bottom-container wow animate__animated animate__fadeInDown'>
                    <img src='https://sriramsias.com/images/about1.jpg' style={{ width: "100%" }} alt='' />
                    <h6>RECEPTION</h6>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 py-3'>
                  <div className='text-center about-bottom-container wow animate__animated animate__fadeInLeft'>
                    <img src='https://sriramsias.com/images/about2.jpg' style={{ width: "100%" }} alt='' />
                    <h6>COUNSELLING</h6>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 py-3'>
                  <div className='text-center about-bottom-container wow animate__animated animate__fadeInRight'>
                    <img src='https://sriramsias.com/images/about3.jpg' style={{ width: "100%" }} alt='' />
                    <h6>CLASSROOM</h6>
                  </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12 py-3'>
                  <div className='text-center about-bottom-container wow animate__animated animate__rubberBand'>
                    <img src='https://sriramsias.com/images/about4.jpg' style={{ width: "100%" }} alt='' />
                    <h6>TEACHERS</h6>
                  </div>
                </div>
                {/* </div> */}
              </div>
            </div>
            {/* Civil right Section Start */}
            {/* <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} />
              </div>
          </div> */}
            {/* Civil right Section End */}
          </div>
        </div>
        {/* Civil About Section End */}
      </>
      )
}

      export default Civilabout;