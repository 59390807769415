import React, { useEffect } from 'react';
import "./Slider.css";
import logo1 from "../../Image/GAURAV-AGRAWAL.jpg";
import logo2 from "../../Image/FAIZ-AHMAD-MUMTAZ.jpg";
import logo3 from "../../Image/MALLIKA SUDHIR.jpg";
import logo4 from "../../Image/PRADEEP-SINGH.png";
import logo5 from "../../Image/SHWETA-CHAUHAN.jpg";

function Slider() {
    // function for multiple card
    useEffect(() => {
        const Doctoritems = document.querySelectorAll('.carousel-laptop .item-laptop');
        Doctoritems.forEach((el) => {
            const minPerSlide = 4;
            let next = el.nextElementSibling;
            for (let i = 1; i < minPerSlide; i++) {
                if (!next) {
                    // wrap carousel by using first child
                    next = Doctoritems[0];
                }
                let cloneChild = next.cloneNode(true);
                el.appendChild(cloneChild.children[0]);
                next = next.nextElementSibling;
            }
        });
    }, []);
    return (
        <>
            {/* Carsousel Section Start */}
            <div className='Carsousel_Container'>
                <div className='Carousel_heading'>
                    <h1 className='text-white'>Join Our Online Live Classes</h1>
                </div>
                <div className='Carousel_Slider'>
                    <div id="carouselExampleControl" className="carousel slide carousel-laptop" data-bs-ride="carousel">
                        <div className="carousel-inner inner-laptop">
                            <div className="carousel-item item-laptop active Carousel-box me-3">
                                <div className='col-md-4 '>
                                    <div className="card text-center Slider-Container">
                                        <img src="https://sriramsias.com/images/live-class.png" className="card-img-top rounded-circle img-size" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">Live/Recorded Lectures</h5>
                                            {/* <p className="card-text">Rank 1, IAS-2013</p> */}
                                            <p className="card-text" style={{fontSize: "0.9vmax", textAlign: "justify"}}>We offer both live and recorded lectures for CSAT, which makes us a great option for those who want to learn at their own pace or who can't attend the live lectures. The quality of the lectures is very high, and cover all the important topics that you need to know for the exam.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item item-laptop Carousel-box me-3">
                                <div className='col-md-4 '>
                                    <div className="card text-center Slider-Container">
                                        <img src="https://sriramsias.com/images/online-test-icon.png" className="card-img-top rounded-circle img-size" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">Online Test Series</h5>
                                            {/* <p className="card-text">Rank 1, IAS-2014</p> */}
                                            <p className="card-text" style={{fontSize: "0.9vmax", textAlign: "justify"}}>
                                            We offers in house test series as per latest pattern for UPSC entrance exam to help aspirants prepare for the civil services examination. The UPSC online test series is known for its quality content, detailed reports, and boost your final scores in the exam.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item item-laptop Carousel-box me-3">
                                <div className='col-md-4'>
                                    <div className="card text-center Slider-Container">
                                        <img src="https://sriramsias.com/images/study-material-icon.png" className="card-img-top rounded-circle img-size" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">Updated Study Notes</h5>
                                            {/* <p className="card-text">Rank 8, IAS-2016</p> */}
                                            <p className="card-text" style={{fontSize: "0.9vmax", textAlign: "justify"}}>SRIRAM's IAS study notes are considered to be one of the best resources for IAS exam preparation. The study notes are prepared by experts and offer comprehensive coverage of the syllabus. The notes are updated regularly and are available with the admission in a course.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item item-laptop Carousel-box me-3">
                                <div className='col-md-4'>
                                    <div className="card text-center Slider-Container">
                                        <img src="https://sriramsias.com/images/ias-fee-icon.png" className="card-img-top rounded-circle img-size" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">Easy Fees Plans                                            </h5>
                                            {/* <p className="card-text">Rank 1, IAS-2019</p> */}
                                            <p className="card-text" style={{fontSize: "0.9vmax", textAlign: "justify"}}>
                                            We offers affordable coaching with easy EMI installments. Start preparing for an IAS officer now. Admission can be taken by the student either Online or Offline. The payment methods accepted are – UPI, IMPS, Demand Draft/s, Debit Card, Credit Card, and Net Banking.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="carousel-item item-laptop Carousel-box me-3">
                                <div className='col-md-4'>
                                    <div className="card text-center Slider-Container">
                                        <img src="https://sriramsias.com/images/syllabus-icon.png" className="card-img-top rounded-circle img-size" alt="..." />
                                        <div className="card-body">
                                            <h5 className="card-title">Timely Syllabus Completion                                            </h5>
                                            {/* <p className="card-text">Rank 26, IAS-2016</p> */}
                                            <p className="card-text" style={{fontSize: "0.9vmax", textAlign: "justify"}}>
                                            We are known for completing the civil services syllabus on time. This is a huge benefit for students who prepare for the IAS exam with us, as it can be a very challenging and time-consuming process. We offers a wide range of resources and support to help students succeed.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControl" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControl" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
            {/* Carsousel Section End */}
        </>
    )
}

export default Slider;

