import React from 'react';
import "./Loader.css";

function Loader() {
  return (
    <>
      {/* <div className="section-center">
  <div className="section-path">
    <div className="globe">
      <div className="wrapper">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
 </div> */}
    </>
  )
}

export default Loader
