import React from 'react';

const ArticleCard = ({ image, mainTitle, articles }) => {
    return (
        <div style={styles.card}>
            <img src={image} alt="Article" style={styles.image} />
            <h3 style={styles.mainTitle}>{mainTitle}</h3>
            <div style={styles.articleList}>
                {articles.map((article, index) => (
                    <div key={index} style={styles.articleItem}>
                        <p style={styles.title}>{article.title}</p>
                        <div style={styles.dateContainer}>
                            <i className="fa fa-calendar" style={styles.calendarIcon}></i>
                            <span style={styles.date}>{article.date}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const styles = {
    card: {
        border: '1px solid #d3d3d3',
        padding: '15px',
        borderRadius: '8px',
        backgroundColor: '#f9f9f9',
        maxWidth: '350px',
        margin: '20px auto',
    },
    image: {
        width: '100%',
        height: 'auto',
        borderRadius: '8px',
    },
    mainTitle: {
        color: '#d52727',
        fontSize: '18px',
        margin: '15px 0 10px',
    },
    articleList: {
        marginTop: '10px',
    },
    articleItem: {
        marginBottom: '15px',
    },
    title: {
        color: '#333',
        fontSize: '14px',
        marginBottom: '5px',
    },
    dateContainer: {
        display: 'flex',
        alignItems: 'center',
        color: '#888',
    },
    calendarIcon: {
        marginRight: '5px',
    },
    date: {
        fontSize: '12px',
    },
};

export default ArticleCard;
