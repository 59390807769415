import { BASE_URL, BASE_URL1 } from "../../insightConfig/insightConfig";

async function fetchWithCache(url) {
  // const cachedData = sessionStorage.getItem(url);
  // if (cachedData) {
  //   return JSON.parse(cachedData);
  // } else {
  const response = await fetch(url);
  const data = await response.json();
  // sessionStorage.setItem(url, JSON.stringify(data));
  // Schedule removal from sessionStorage after 2 minutes
  // setTimeout(() => {
  //   sessionStorage.removeItem(url);
  // },2 * 60 * 1000); // 1 minutes in milliseconds
  return data;

}
//Raaz working here
// Function to make a GET request to fetch course data
export async function fetchCourseData(coursename, authtoken, isEnrolled/* , coursepayment, coursenotpayment, ispayment */) {

  let url;
  if (isEnrolled == 'enrolled') {
    url = `${BASE_URL1}/course/${coursename}/${authtoken}`;
  }
  else {
    url = `${BASE_URL1}/course/${coursename}`;
  }
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response.data
    return data[0]; // Assuming you expect the first item of the response array
  } catch (error) {
    console.error('Error fetching course data:', error);
    throw error; // Rethrow the error for handling in the component
  }
}

// Function to make a GET request to fetch course data
export async function fetchcuetdetails() {
  let url = `${BASE_URL1}/getCUETDescription`;
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response.data
    return data[0]; // Assuming you expect the first item of the response array
  } catch (error) {
    console.error('Error fetching course data:', error);
    throw error; // Rethrow the error for handling in the component
  }
}

// Function to make a GET request to fetch lesson data for a course
export async function fetchCourseCard() {
  const authtoken = localStorage.getItem('authtoken');
  const isLoggedIn = localStorage.getItem('isLoggedIn');

  let url;

  if (authtoken) {
    url = `${BASE_URL1}/ExamBody/civils/${authtoken}`;
  } else {
    url = `${BASE_URL1}/ExamBody/civils`;
  }

  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response
    return data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}

// Function to make a GET request to fetch lesson data for a course
export async function fetchtestCard() {
  const authtoken = localStorage.getItem('authtoken');
  const isLoggedIn = localStorage.getItem('isLoggedIn');

  let url;

  if (authtoken) {
    url = `${BASE_URL1}/ExamBodytest/civils/${authtoken}`;
  } else {
    url = `${BASE_URL1}/ExamBodytest/civils`;
  }

  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response
    return data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}

// Function to make a GET request to fetch lesson data for a course
export async function fetchaboutdetail(abouturl) {
  const url = `${BASE_URL}/about/${abouturl}`;
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response.data
    return data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}

// Function to make a GET request to fetch lesson data for a course
export async function fetchnewsdetail(newsurl) {
  const url = `${BASE_URL1}/readmore/News/${newsurl}`;
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response.data
    return data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}

// Function to make a GET request to fetch lesson data for a course
export async function fetchblogdetail(blogurl, todayurl) {
  let url;
  if (blogurl) {
    url = `${BASE_URL1}/blog/${blogurl}`;
  }
  else {
    url = `${BASE_URL1}/blog/${todayurl}`;
  }
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response.data
    return data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}

// Function to make a GET request to fetch lesson data for a course
export async function fetchCourseDescription(sectionid) {
  const url = `${BASE_URL}/CourseDescription/${sectionid}`;
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response.data
    return data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}

// Function to make a GET request to fetch lesson data for a course
export async function fetchCurrentaffair1() {
  let url = `${BASE_URL1}/current-affairs/News`;
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response
    return data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}
export async function fetchCurrentaffair2() {
  const url = `${BASE_URL1}/current-affairs/Editorial`;
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response
    return data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}

// Function to make a GET request to fetch lesson data for a course
export async function fetchblog1() {
  let url = `${BASE_URL1}/blogs/blog`;
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response
    return data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}
export async function fetchblog2() {
  const url = `${BASE_URL1}/blogs/today`;
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response
    return data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}

// Function to make a GET request to fetch lesson data for a course
export async function fetchCourseSubjects(courseID) {
  const url = `${BASE_URL1}/course-subjects/${courseID}`;
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response.data
    return data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}

export async function fetchtestData(testseriesname, isEnrolled, authtoken, coursepayment, coursenotpayment, ispayment) {
  let url;

  if (isEnrolled == 'enrolled') {
    url = `${BASE_URL1}/testseries/${testseriesname}/${authtoken}`;
  }
  else {
    url = `${BASE_URL1}/testseries/${testseriesname}`;
  }

  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response.data
    return data[0]; // Assuming you expect the first item of the response array
  } catch (error) {
    console.error('Error fetching course data:', error);
    throw error; // Rethrow the error for handling in the component
  }
}

export async function fetchTestSeriesData(courseID, authtoken) {
  const url = `${BASE_URL1}/course-test/${courseID}/${authtoken}`;
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response.data
    return data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}

export async function fetchCurrentNewsData() {
  const url = `${BASE_URL1}/current-affairs/News`;
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response
    return data.data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}

export async function fetchUserDetails(authtoken) {
  const url = `${BASE_URL}/studentdashboard/${authtoken}`;
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response
    return data.data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}

export async function fetchMyCourse(authtoken) {
  const url = `${BASE_URL}/mycourse/${authtoken}`;
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response
    return data.data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}

export async function fetchNotMyCourse(authtoken) {
  const url = `${BASE_URL}/notmycourse/${authtoken}`;
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response
    return data.data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}

export async function fetchMyTest(authtoken) {
  const url = `${BASE_URL}/mytestseries/${authtoken}`;
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response
    return data.data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}

export async function fetchNotMyTest(authtoken) {
  const url = `${BASE_URL}/notmytestseries/${authtoken}`;
  try {
    const response = await fetchWithCache(url);
    // const data = await response.json();
    const data = response
    return data.data;
  } catch (error) {
    console.error('Error fetching lesson data:', error);
    throw error;
  }
}

export async function SignupUser({ name, email, contact, password, examType, city }) {
  try {
    const response = await fetch(`${BASE_URL}/signup`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name, email, contact, password, examType, city }),
    });

    // Check if response is successful
    if (!response.ok) {
      throw new Error('Registration failed');
    }

    // If registration is successful, return the response data
    return await response.json();
  } catch (error) {
    // If an error occurs, throw it
    throw error;
  }
};

export async function loginUser({ email, password }) {
  try {
    const response = await fetch(`${BASE_URL}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
      throw new Error('Login failed');
    }
    // If registration is successful, return the response data
    return await response.json();
  } catch (error) {
    // If an error occurs, throw it
    throw error;
  }
};

export async function forgotUser({ email }) {
  try {
    const response = await fetch(`${BASE_URL}/Forget`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      throw new Error('Failed to send reset password instructions');
    }
    // If registration is successful, return the response data
    return await response.json();
  } catch (error) {
    // If an error occurs, throw it
    throw error;
  }
};

export async function finalsubmittest({ attemptedQuestions, correctQuestions, wrongQuestions, correctMarks, wrongMarks, answerSheet, testsession, testpackageid, teststatus, authtoken }) {
  try {
    let url;
    if (teststatus === "Incomplete") {
      url = `${BASE_URL1}/updatetest/${testpackageid}/${authtoken}`
    } else {
      url = `${BASE_URL1}/update-reattempted-test/${testpackageid}/${authtoken}`
    }
    // /update-reattempted-test/:packageId/:email
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      /*
      {
    "attemptedQuestions": "51",
    "correctMarks": 5.879999999999978,
    "correctQuestions": "15",
    "wrongMarks": 83.12000000000002,
    "wrongQuestions": 3,
    "answerSheet": "[]"
}
      */
      body: JSON.stringify({ attemptedQuestions: attemptedQuestions, correctMarks: correctMarks, correctQuestions: correctQuestions, wrongMarks: wrongMarks, wrongQuestions: wrongQuestions, answerSheet }),
    });

    // Check if response is successful
    if (!response.ok) {
      throw new Error('Registration failed');
    }

    // If registration is successful, return the response data
    return await response.json();
  } catch (error) {
    // If an error occurs, throw it
    throw error;
  }
};

// email, totalQuestions, attemptedQuestions, correctQuestions, wrongQuestions, correctMarks, wrongMarks
// sectionId, topicId
export async function finalsubmitcoursetest({ email, totalQuestions, attemptedQuestions, correctQuestions, wrongQuestions, correctMarks, wrongMarks, sectionId, topicId, answerSheet, reattemptedcourse, coursestatus }) {
  try {
    // const response = await fetch(`${BASE_URL}/save-lesson-quiz-response/${topicId}/${sectionId}`, {
    // /save-reattempted-lesson-quiz-response/:topicId/:sectionId
    let url;
    if (coursestatus === 'Incomplete') {
      url = `${BASE_URL}/save-lesson-quiz-response/${topicId}/${sectionId}`
    }
    //  else if (coursestatus === 'Complete') {
      // url = `${BASE_URL}/save-reattempted-lesson-quiz-response/${topicId}/${sectionId}`
    // }
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, totalQuestions, attemptedQuestions, correctQuestions, wrongQuestions, correctMarks, wrongMarks, answerSheet }),
    });

    // Check if response is successful
    if (!response.ok) {
      throw new Error('Course Test failed');
    }

    // If registration is successful, return the response data
    return await response.json();
  } catch (error) {
    // If an error occurs, throw it
    throw error;
  }
};  