import React from 'react';
import { Helmet } from 'react-helmet-async';
import "../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection.css";
import CivilTopSection from '../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection';
import userImage from "../../../Image/user.png";
import aboutImage from "../../../Image/aboutbuilding.jpg";
import CivilrightSection from '../../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection';
import Content from '../../AllPageContent/AllPageContent';
import ButtonPage from '../../ButtonPage/ButtonPage';
import CurrentAffair from '../../CurrentAffair/CurrentAffair';

function career() { 
  const Buttonname = "Multiplebutton";
  const componentName = "currentaffairs";

  // Banner Content For CivilAbout Page
  const data = {
    academyName: 'Career',
    userImage: userImage,
    userName: 'Priya Rathore',
    Image: aboutImage
  };

  // Content For CivilAbout Page
  const paragraphstatic = [
    {
      text: "Since its inception, Sriram’s IAS has made remarkable progress time and again in its 35 years of journey. However, we are not stopping here. This time we’re in search of exceptional individuals to help us revolutionize this industry permanently. We invite you to join us, if you're fervent about writing, teaching, and aiding aspiring candidates.",
      styles: [
        { word: 'Founded by SRIRANGAM SRIRAM more than 3 decades ago, SRIRAM’s IAS has emerged as one of the leading institutes in India with the vision to be the Centre of Excellence and the mission to develop knowledgeable and innovative leaders.', style: 'font-weight: bold; color: #26156d;' },
        { word: 'SRIRAM’s IAS', style: 'font-weight: bold; color: red;' }
      ]
    },
    {
      text: "Passion for your work is imperative. It should narrate a tale and authentically represent who you are. Your creations will be seen by thousands of students every day. They should be able to resonate with you, forging an immediate connection.Being open to pushing your boundaries is essential—question your work, contemplate on ways to enhance it, refine language, and structure, among other aspects. Many of our previous members have demonstrated these traits, and it's these very attributes that have propelled them to success in various examinations.",
      styles: [
        { word: 'Shri S. Baliyan', style: 'font-weight: bold;' },
        { word: 'History and Art and Culture', style: 'font-weight: bold;' },
        { word: '‘A Compendium of Indian Art & Culture’', style: 'font-weight: bold; color: #C00000;' }
      ]
    },
    {
      text: "We assess you based on your potential, not just your past achievements.",
      styles: [
        { word: 'Our High Success Rate', style: 'font-weight: bold; color: #26156d;' },
        { word: 'topped the UPSC Civil Services Examination', style: 'font-weight: bold;' }
      ]
    },
    {
      text: "If you've previously worked with other institutions, chances are you've encountered a transactional setup where you deliver your service and receive a fixed compensation. It's often about catering to the preferences of your superiors and making adjustments to your work at their behest. But with us you will learn to produce your own quality work with your signature style. The art and style that you can call your own.",
      styles: [
        { word: 'holistic learning approach', style: 'font-weight: bold;' },
        { word: 'Maximum attention', style: 'font-weight: bold;' },
        { word: 'build solid foundations', style: 'font-weight: bold;' },
        { word: 'concept building', style: 'font-weight: bold;' },
        { word: 'regular interaction', style: 'font-weight: bold;' }
      ]
    },
    {
      text: "We're currently evaluating candidates for all positions. Those aspiring to leverage their knowledge and hone further skills should fill out the form below.",
      styles: [{
        word: 'The strategy of SRIRAMs IAS for making students benefit immensely by their association with us is', style: 'font-weight: bold;'
      }]
    },
    {
      text: "https://forms.gle/CouNjvYAJDUbWp296",
      styles: [{
        word: 'https://forms.gle/CouNjvYAJDUbWp296', style: 'font-weight: bold;'
      }],
    }
  ];
 
  return (
    <>
      <Helmet>
        <title>About Us | Insight IAS, Delhi | Best IAS Coaching in Delhi</title>
        <meta name="description" content="Insight Academy Delhi, is the leading platform in India that provides coaching for candidates preparing for UPSC, IAS, CUET, NEET, UGC-NET examinations."></meta>
        <meta name="keywords" content="about insight ias, about us, in sight ias, insight ias, Baliyan Sir, balyan sir, baliyan sir history, history optional by baliyan sir, baliyan sir notes, history notes, insight ias delhi, insight ias india, insightsonindia, insights IAS, insight delhi, insight ias test series, insight test series, insight ias main test series, insight prelims test series, upsc coaching in delhi, civil services coaching in delhi, neet coaching center in delhi, ugc net coaching in delhi, CUET coaching in delhi, CUET form 2022, how to apply for CUET, CUET 2022, CUET Exam, Pattern of CUET Exam, Syllabus of CUET Exam, NEET Practice Tests."></meta>
        <meta name="subject" content="Insight Academy Delhi, is the leading platform in India that provides coaching for candidates preparing for UPSC, IAS, CUET, NEET, UGC-NET examinations."></meta>
      </Helmet>
      {/* Civil About Section Start */}
      <section className='civilabout'>
        {/* Civil Top Section Start */}
        <CivilTopSection
          academyName={data.academyName}
          userImage={data.userImage}
          userName={data.userName}
          Image={data.Image}
        />
        {/* Civil Top Section End */}
      </section>
      <div className='Civil-section'>
        <div className='civil-row'>
          <div className='col-lg-12 col-md-12 col-sm-12 me-2'>
            {/* Content Component Start */}
            <Content paragraphstatic={paragraphstatic} />
            {/* Content Component End */}
            {/* <ButtonPage Pagename={Buttonname} /> */}
            {/* <div className='continer-fluid'> */}
              {/* <div className='row'>
                <div className='col-lg-6 p-3'>
                    <div className='card p-4 pb-5' style={{background: "#26156d", color: "white"}}>
                      <h1 className='fw-bold'>OUR VISION</h1>
                      <p>To be the Center of Excellence to ensure our students are successful and that achieve their aspirations within a time frame.</p>
                    </div>
                </div>
                <div className='col-lg-6 p-3'>
                  <div className='card p-4' style={{background: "#db261f", color: "white"}}>
                    <h1 className='fw-bold'>OUR MISSION</h1>
                    <p>To empower aspirants of Civil Services to excel by Imparting to them right and relevant information and perspective. The aim is to help them acquire appropriate analytical skills to be on their own.</p>
                  </div>
                </div>
              </div> */}
            {/* </div> */}
            {/* <div className='container'> */}
              {/*  */}
          </div>
          {/* Civil right Section Start */}
          {/* <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} />
              </div>
          </div> */}
          {/* Civil right Section End */}
        </div>
      </div>
      {/* Civil About Section End */}
    </>
  )
}

export default career;