import React, { useEffect, useState } from 'react';
import ShareComponent from '../ShareComponent/ShareComponent';
import { FaHeart } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa6";
import { FaEye } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { BASE_URL } from '../../insightConfig/insightConfig';

function Likeshare({Like, view, Sn, url, setNewData}) {
    const [like, setLike] = useState("unlike")
    const [likeCount, setLikeCount] = useState(Like);
    const [isSubmitting, setIsSubmitting] = useState(false); // State to track if an API call is in progress
    const alert = useAlert();
    // const navigate = useNavigate();

    useEffect(() => {
        const storedLike = localStorage.getItem('unlike');
        if (storedLike) {
            setLike(storedLike);
        }
    }, []);

    const handleLike = async () => {
        if (isSubmitting) return; // Prevent multiple clicks while an API call is in progress
        setIsSubmitting(true); // Set submitting state to true to disable the button
        try {
            const likedPosts = JSON.parse(localStorage.getItem('likedPosts')) || [];
            let action;
    
            if (!likedPosts.includes(Sn)) {
                action = 'like';
                // navigate(`/blog/${url}`)
            } else {
                action = 'unlike';
            }
            
            // const response = await fetch(`http://localhost:5000/blog/${action}/${Sn}`, {
            const response = await fetch(`${BASE_URL}/blog/${action}/${Sn}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                }
            });

            const updatelike = await response.json();
            setLikeCount(updatelike.likeCount);
            
            if (!response.ok) {
                throw new Error(`Failed to ${action} the blog post`);
            }
            
            // Update likedPosts in localStorage based on the action
            const updatedLikedPosts = action === 'like' ? [...likedPosts, Sn] : likedPosts.filter(post => post !== Sn);
            localStorage.setItem('likedPosts', JSON.stringify(updatedLikedPosts));
            
            // Toggle like state
            setLike(action === 'like' ? 'unlike' : 'like');
            // alert.show(`${action === 'like' ? 'Liked' : 'Unliked'} successfully`);
            // navigate(`/blogs`)
        } catch (error) {
            console.error('Error:', error);
        } finally {
            setIsSubmitting(false); // Reset submitting state after API call is done
        }
    };

  return (
    <>
    <div className='d-flex flex-row align-item-center flex-wrap justify-content-evenly'>
     <Link to="#" className={`list-group-item p-2 px-4 d-flex me-2 justify-content-center align-items-center border border-0 rounded-1 shadow-lg bg-body rounded border-secondary bg-light ${isSubmitting ? 'disabled' : ''}`} onClick={handleLike}>{like !== "like" ? <FaHeart className='text-danger me-1' /> : <FaRegHeart className='me-1'/>} Like {likeCount}</Link>
     <Link to="#" className='list-group-item p-2 px-4 d-flex me-2 justify-content-center align-items-center border border-0 rounded-1 shadow-lg bg-body rounded border-secondary bg-light'><FaEye className='text-primary me-1' /> Views {view}</Link>
      {/* <ShareComponent url={url} setNewData={setNewData} className="me-2"/> */}
    </div>
    {/* <div className='d-flex flex-row flex-wrap align-item-center justify-content-evenly phone-view'>
     <Link to="#" className={`list-group-item p-2 px-4 d-flex me-2 justify-content-center align-items-center border border-0 rounded-1 shadow-lg bg-body rounded border-secondary bg-light ${isSubmitting ? 'disabled' : ''}`} onClick={handleLike}>{like !== "like" ? <FaHeart className='text-danger me-1' /> : <FaRegHeart className='me-1'/>} Like {likeCount}</Link>
     <Link to="#" className='list-group-item p-2 px-4 d-flex me-2 justify-content-center align-items-center border border-0 rounded-1 shadow-lg bg-body rounded border-secondary bg-light'><FaEye className='text-primary me-1' /> Views {view}</Link>
      <ShareComponent url={url} className="me-2"/>
    </div> */}
    </>
  )
}

export default Likeshare;
