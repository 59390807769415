import React, { useEffect, useState, useRef } from 'react';
import CivilrightSection from '../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection';
import CivilCard from '../ReusableComponent/CIVIL/CivilCard/CivilCard';
import Calendars from '../Calendar/Calendar';
import '../Calendar/Calendar.css';
// import 'react-calendar/dist/Calendar.css';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import listPlugin from '@fullcalendar/list';
import '../../../node_modules/@fullcalendar/core/main.css';
import '../../../node_modules/@fullcalendar/daygrid/main.css';
import '../../../node_modules/@fullcalendar/timegrid/main.css';
import '../../../node_modules/@fullcalendar/list/main.css';
import CurrentAffair from '../CurrentAffair/CurrentAffair';
import Pagelayout from '../EffectonLoad/pagelayout';
import { FaAnglesRight, FaAnglesLeft } from "react-icons/fa6";
import Calendar from 'react-calendar';
import { BASE_URL, BASE_URL1 } from '../../insightConfig/insightConfig';
import 'react-calendar/dist/Calendar.css';
import RangeCalendar from '../Calendar/RangeCalendar';
import axios from 'axios';

function Current1({ news, categoryId, setNews, startDate, setStartDate, endDate, setEndDate, selectedTag, setSelectedTag, newsubcateogry , isloading,  setisfilterapply }) {



  const searchDateWise = async (startdate, enddate, tag) => {   
    const startDate = new Date(startdate).toISOString().split('T')[0]; // Extracting only the date part
    const endDate = new Date(enddate).toISOString().split('T')[0]; // Extracting only the date part
    try {
      let response = await axios.get(BASE_URL + `/current-affairs-date-filter/${categoryId}/${tag}/${startDate}/${endDate}`)
     // console.log(response.data.data)
      setNews(response.data.data)
      setisfilterapply(true);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }


  // const handleYearChange = (delta) => {
  //   const newYear = selectedYear + delta;
  //   setSelectedYear(newYear);
  //   calendarRef.current.getApi().gotoDate(`${newYear}-0${selectedMonth}-01`);
  // };

  // const handleDateinput = async (date1, date2) => {
  //   // setSelectedDate(date)
  //   //  (date)
  //   const originalDate1 = new Date(date1);
  //   const originalDate2 = new Date(date2);
  //   const formattedDate1 = originalDate1.toISOString().split('T')[0]; // Extracting only the date part
  //   const formattedDate2 = originalDate2.toISOString().split('T')[0]; // Extracting only the date part

  //   try {
  //     setisfilterapply(true);
  //     if (datefilter) {
  //       setisfilterapply(datefilter)
  //     }
  //     const response = await fetch(`${BASE_URL1}/current-affairs-date-filter/${contentToShow}/All/${formattedDate1}/${formattedDate2}`);
  //     const data = await response.json();
  //     setFilteredData(data.data);
  //     if (datefilteredData) {
  //       setFilteredData(datefilteredData)
  //     }
  //   } catch (error) {
  //     console.error('Error fetching data:', error);
  //   }
  // };


  // const selectUIToShow = () => {
  //   if (CardData.length){
  //     return
  //   }
  // }
  return (
    <>
      <div className='Civil-section blog1-section'>
        <div className='civil-row blog-margin-top white'>
          <div className='col-lg-8 col-md-12 col-sm-12'>
            <Calendars searchDateWise={searchDateWise} startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} selectedTag={selectedTag} setSelectedTag={setSelectedTag} newsubcateogry={newsubcateogry} 
          />
            {/* {selectUIToShow()} */}
            {news.length === 0 && <><h3 className='fw-bold text-center p-5'>No Result Found!</h3></>}
            {isloading && news.length === 0 ? <Pagelayout /> : <CivilCard CardData={news} />}

          </div>  {/* Civil right Section Start */}
          <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              <div className="card-body">
                <CurrentAffair /* onDateSelect={handleDateinput} */ componentName='currentaffairs' />
              </div>
            </div>
          </div>
          {/* Civil right Section End */}
        </div>
      </div>
    </>
  );
}

export default Current1;

