import React, { useState } from 'react';
import userImg from "../../Image/aboutimg.png"
import "../Dashborad/dashboard.css";
import { FaNoteSticky } from "react-icons/fa6";
import { FaUserFriends } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { BsFillCloudArrowDownFill } from "react-icons/bs";
import { Link, useNavigate } from 'react-router-dom';
import { Modal, Spinner } from 'react-bootstrap';
import { FaRupeeSign } from "react-icons/fa";
import 'bootstrap/dist/css/bootstrap.min.css';
import CivilModal from '../ReusableComponent/CIVIL/CivilModal/CivilModal';
import 'animate.css';
import axios from 'axios';
import { BASE_URL } from '../../insightConfig/insightConfig';
import swal from 'sweetalert';
function Dashboardleft2({ testSeriesData, reloadTestData, setReloadTestData }) {
    const [open, setOpen] = useState(false);
    const [modalBox, setModalBox] = useState(false)


    console.log(testSeriesData)
    const storedAuthToken = localStorage.getItem('authtoken');
    const navigate = useNavigate()

    const testSeriesPrice = testSeriesData?.Price.toLocaleString('en-IN');
    const priceAfterDiscount = testSeriesData.Price - (testSeriesData.Price * (testSeriesData.Discount / 100))
    const payableAmount = Math.ceil(priceAfterDiscount)

    const handleEnroll = async () => {
        let data = { courseid: testSeriesData.CourseID, email: storedAuthToken, batchid: testSeriesData.BatchId, amount: 0, transactionid: "Free Test", status: 'Success' }
        if (payableAmount > 0) {
            setOpen(true)

        } else {
            if (storedAuthToken) {
                const response = await axios.post(`${BASE_URL}/${'payordertest'}`, data);
                if (response.data.statusCode == 200) {
                    setReloadTestData(!reloadTestData)
                    swal('Enrolled Test Series Successfully', {
                        icon: "success",
                        buttons: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                closeModal: true
                            }
                        }
                    })

                }
            } else {
                navigate('/login');
            }
        }


    }
    return (
        <>
            <div className='dashboardleft-background'>
                <div className='dash1'>
                    <img src={testSeriesData.Image} alt={"..."} className='dash2-img' />
                    {testSeriesData.isEnrolled != 'enrolled' && <div className='d-flex justify-content-between w-100 my-2'>
                        <button type="button" className="btn btn-darkblue"><FaNoteSticky className='me-1 fs-6' /> {testSeriesData.QuizCount} TESTS</button>
                        <button type="button" className="btn btn-darkblue"><FaUserFriends className='me-1 fs-6' /> {testSeriesData.SubscriberCount + testSeriesData.Subscriber} SUBS.</button>
                    </div>}
                </div>
                <div className='dash2'>
                    <div className="d-grid gap-2">
                        <Link to={testSeriesData.PDFUrl} className="btn d-flex align-item-center justify-content-center btn-orange fw-bold py-3" target='_blank' type="button"><BsFillCloudArrowDownFill className='me-1 fs-5' /> DOWNLOAD SCHEDULE</Link>

                        {
                            testSeriesData.isEnrolled != 'enrolled' ?
                                <>
                                    <button onClick={handleEnroll} className="btn btn-orange py-2" type="button">
                                        <h6 className='text-decoration-line-through'>FEE <FaRupeeSign className='fs-6' /> {testSeriesPrice}</h6>
                                        <h6 className=''>PAY JUST <FaRupeeSign className='fs-6' /> {payableAmount.toLocaleString('en-IN')}</h6>
                                        <hr className='my-1' />
                                        <h6 className=''>DISCOUNT {testSeriesData.Discount}%</h6>
                                    </button>
                                    <button className="btn btn-orange py-3" onClick={handleEnroll} type="button"><FaRupeeSign className='fs-6' /> {payableAmount.toLocaleString('en-IN')} {payableAmount < 1 ? 'ENROLL FOR FREE' : 'ENROLL'}</button>
                                </> :
                                <button className="btn btn-orange py-3" type="button">ENROLLED</button>
                        }

                        <Link to="https://api.whatsapp.com/send?phone=91981833320" target='_blank' className="btn d-flex align-item-center justify-content-center bg-darkgreen fw-bold py-3" type="button"><FaWhatsapp className='me-1 fs-5' /> WHATSAPP 9818333201</Link>
                    </div>
                </div>
            </div>

            <Modal show={open} centered className="animate__animated animate__fadeIn mobileyoutube">
                <CivilModal paymentFor='testseries' payamount={payableAmount} BatchID={testSeriesData.BatchId} authtoken={storedAuthToken} CourseID={testSeriesData.CourseID} setOpen={setOpen} setModalBox={setModalBox}/>
            </Modal>
            {/* <Modal show={isOpen4} centered className="animate__animated animate__fadeIn mobileyoutube">
                <CivilModal paymentFor='testseries' payamount={payableAmount} BatchID={testSeriesData.BatchId} authtoken={storedAuthToken} CourseID={testSeriesData.CourseID} closeModal={closeModal4} />
            </Modal> */}
            <Modal show={modalBox} top backdrop="static" dialogClassName="custom-modal-width">
                <div className="modal-content" style={{ backgroundColor: "#f0f8ff", padding: "2rem", borderRadius: "10px" }}>
                    <div className="modal-body" style={{ padding: "2rem" }}>
                        <div className="mb-3 d-block text-center">
                            <Spinner
                                animation="border"
                                className="mt-5 mb-3"
                                size="xl"
                                style={{ width: "200px", height: "200px", color: "#ff2f00" }}

                            />
                            <h5
                                className="modal-title mb-5"
                                id="exampleModalLabel"
                                style={{ color: "#17a2b8", fontFamily: "Arial, sans-serif" }}
                            >
                                Saving Order Details. This may take a moment...
                            </h5>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Dashboardleft2; 