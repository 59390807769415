import React from 'react';
import { BsFiletypePdf } from "react-icons/bs";
import "./ButtonPage.css";
import { Link } from 'react-router-dom';

function ButtonPage({Pagename, noteurl}) {

  return (
    <div className='Buttonpage_background'>
      {Pagename === "Singlebutton" ? (
        <Link className="btn btnpagebackground1 m-2" to={noteurl} target='_blank' role="button">
          <BsFiletypePdf /> DOWNLOAD PDF
        </Link>
      ) : (
        <>
          <Link className="btn btnpagebackground1 m-2" to={noteurl} target='_blank'  role="button">
            <BsFiletypePdf /> DOWNLOAD PDF
          </Link>
          <Link className="btn btnpagebackground2 m-2" to="/contact-us" role="button">
            CLICK HERE FOR ENQUIRY
          </Link>
        </>
      )}
    </div>
  );
}

export default ButtonPage;
