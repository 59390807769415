import React from 'react';
import "./classquestion.css";
import Content from '../AllPageContent/AllPageContent';

function ClassQuestion({ paragraphs }) {
    return ( 
        <>
            <div className='p-4'>
                <div className='card classquestion-card px-5 py-4'>
                    <Content paragraphs={paragraphs} />
                </div>
            </div>
        </>
    )
}

export default ClassQuestion
