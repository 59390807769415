import React from 'react';
import "./ResultPreview.css";

function ResultPreview({ testQuestions, attemptedQuestions, viewedQuestionsArray }) {
    return (
        <>
            <div className='container-fluid p-4 mt-2 rounded-3 bg-cream'>
                <div className='row'>
                    <div className='col-lg-4 col-md-6 col-sm-12 p-2 d-flex flex-column justify-content-center'>
                        <div className='d-flex text-center flex-column justify-content-center border-white card rounded-2 px-4 py-3 shadow'>
                            <h4>{testQuestions.length}</h4>
                            <hr className='my-2' />
                            <b className='text-darkblue'>TOTAL QUESTIONS</b>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 p-2 d-flex flex-column justify-content-center'>
                        <div className='d-flex text-center flex-column justify-content-center border-white card rounded-2 px-4 py-3 shadow'>
                            <h4>{attemptedQuestions.length}</h4>
                            <hr className='my-2' />
                            <b className='text-darkblue'>TOTAL ATTEMPTED</b>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 p-2 d-flex flex-column justify-content-center'>
                        <div className='d-flex text-center flex-column justify-content-center border-white card rounded-2 px-4 py-3 shadow'>
                            <h4>{testQuestions?.length - attemptedQuestions?.length}</h4>
                            <hr className='my-2' />
                            <b className='text-darkblue'>NOT ANSWERED</b>
                        </div>
                    </div>
                    {/* <div className='col-lg-4 col-md-6 col-sm-12 p-2 d-flex flex-column justify-content-center'>
                        <div className='d-flex text-center flex-column justify-content-center border-white card rounded-2 px-4 py-3 shadow'>
                            <h4>{testQuestions?.length - viewedQuestionsArray?.length}</h4>
                            <hr className='my-2' />
                            <b className='text-darkblue'>UN - ATTEMPTED</b>
                        </div>
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default ResultPreview;