import React from 'react';
import { Helmet } from 'react-helmet-async';
import "../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection.css";
import CivilTopSection from '../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection';
import userImage from "../../../Image/user.png";
import aboutImage from "../../../Image/aboutbuilding.jpg";
import CivilrightSection from '../../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection';
import Content from '../../AllPageContent/AllPageContent';
import ButtonPage from '../../ButtonPage/ButtonPage';
import CurrentAffair from '../../CurrentAffair/CurrentAffair';

function Video() { 
  const Buttonname = "Multiplebutton";
  const componentName = "currentaffairs";

  // Banner Content For CivilAbout Page
  const data = {
    academyName: 'Video',
    userImage: userImage,
    userName: 'Priya Rathore',
    Image: aboutImage
  };

  // Content For CivilAbout Page
  const paragraphstatic = [
    {
      text: "Founded by SRIRANGAM SRIRAM more than 3 decades ago, SRIRAM’s IAS has emerged as one of the leading institutes in India with the vision to be the Centre of Excellence and the mission to develop knowledgeable and innovative leaders.",
      styles: [
        { word: 'Founded by SRIRANGAM SRIRAM more than 3 decades ago, SRIRAM’s IAS has emerged as one of the leading institutes in India with the vision to be the Centre of Excellence and the mission to develop knowledgeable and innovative leaders.', style: 'font-weight: bold; color: #26156d;' },
        { word: 'SRIRAM’s IAS', style: 'font-weight: bold; color: red;' }
      ]
    },
    {
      text: "We are a premier institute dedicated to training candidates appearing for Civil Services Examination. We have 35 years of experience in guiding candidates successfully to prestigious government positions.Our faculty is predominantly in house though we do borrow services of distinguished professors and public servants (Civil and Defence).We have developed techniques and methods for cutting short the learning curve because year on year we get to interact with tens of thousands of fine young minds.We hesitate to brag about our record of success because much of the work is that of the students.Rest assured our success rate has been the highest.",
      styles: [
        { word: 'Shri S. Baliyan', style: 'font-weight: bold;' },
        { word: 'History and Art and Culture', style: 'font-weight: bold;' },
        { word: '‘A Compendium of Indian Art & Culture’', style: 'font-weight: bold; color: #C00000;' }
      ]
    },
    {
      text: "Our High Success Rate",
      images: [{src: aboutImage}],
      styles: [
        { word: 'Our High Success Rate', style: 'font-weight: bold; color: #26156d;' },
        { word: 'topped the UPSC Civil Services Examination', style: 'font-weight: bold;' }
      ]
    },
    {
      text: "Our experienced and dynamic team of experts and teachers is convinced that the method of teaching should be completely oriented on examination lines. Previous question papers, spanning decades are the basis of our approach. As the examination trends change, so does our emphasis. In fact quite often the trends are predicted in the classroom. That explains our high success rate.",
      styles: [
        { word: 'holistic learning approach', style: 'font-weight: bold;' },
        { word: 'Maximum attention', style: 'font-weight: bold;' },
        { word: 'build solid foundations', style: 'font-weight: bold;' },
        { word: 'concept building', style: 'font-weight: bold;' },
        { word: 'regular interaction', style: 'font-weight: bold;' }
      ]
    },
    {
      text: "The strategy of SRIRAMs IAS for making students benefit immensely by their association with us is",
      styles: [{
        word: 'The strategy of SRIRAMs IAS for making students benefit immensely by their association with us is', style: 'font-weight: bold;'
      }],
      lists: [
        ' Exam-oriented classroom coaching.',
        'Study material that is indispensable.',
        'Relevant information in the form of Q&As, MCQs and other channels.',
        'No dumbing down in the classroom because learning is interactive and accountable process.',
        'Every teacher everywhere has answers but SRIRAMs IAS teachers have CORRECT ANSWERS.',
        'Our notice board is an effective way of briefing the students on the most important current events and analysis - selected from all top newspapers from the country like The Indian Express, Times of India, Hindustan Times and some pink papers like Economic Times.',
        'We are the only institution that gives a three page digital handout everyday. Analyzing all current events contained in The Hindu newspaper Delhi edition.',
         'Everday our students are familiarized with MCQs so that their preparation in the Preliminary Examination is on a sure footing.',
         'Our Test Series is of immense value as thousands of discerning student would vouch for.',
         'Our students are given AAA study material for atleast 50 GENERAL ESSAYS in the course of the coaching programme.',
         'Our monthly current affairs magazine adds decisive value to the aspirants preparation.',
         'Everyday students are given practice question which they answer for evaluation by our experienced team.',
         'Our research team consists of young students from prestigious universities who have ample experience with the examination process including the Personality Test.',
      ] 
    },
    {
      text: "Students are continously interacting with the teachers before and after the class; by email; and in the faculty room and on WhatsApp.",
      styles: [{
        word: 'SRIRAMs IAS is at your service till you are in Civil Service.', style: 'font-weight: bold;'
      }],
    },
    {
      text: "SRIRAMs IAS is at your service till you are in Civil Service.",
      styles: [{
        word: 'SRIRAMs IAS is at your service till you are in Civil Service.', style: 'font-weight: bold;'
      }],
    }
  ];
 
  return (
    <>
      <Helmet>
        <title>About Us | Insight IAS, Delhi | Best IAS Coaching in Delhi</title>
        <meta name="description" content="Insight Academy Delhi, is the leading platform in India that provides coaching for candidates preparing for UPSC, IAS, CUET, NEET, UGC-NET examinations."></meta>
        <meta name="keywords" content="about insight ias, about us, in sight ias, insight ias, Baliyan Sir, balyan sir, baliyan sir history, history optional by baliyan sir, baliyan sir notes, history notes, insight ias delhi, insight ias india, insightsonindia, insights IAS, insight delhi, insight ias test series, insight test series, insight ias main test series, insight prelims test series, upsc coaching in delhi, civil services coaching in delhi, neet coaching center in delhi, ugc net coaching in delhi, CUET coaching in delhi, CUET form 2022, how to apply for CUET, CUET 2022, CUET Exam, Pattern of CUET Exam, Syllabus of CUET Exam, NEET Practice Tests."></meta>
        <meta name="subject" content="Insight Academy Delhi, is the leading platform in India that provides coaching for candidates preparing for UPSC, IAS, CUET, NEET, UGC-NET examinations."></meta>
      </Helmet>
      {/* Civil About Section Start */}
      <section className='civilabout'>
        {/* Civil Top Section Start */}
        <CivilTopSection
          academyName={data.academyName}
          userImage={data.userImage}
          userName={data.userName}
          Image={data.Image}
        />
        {/* Civil Top Section End */}
      </section>
      <div className='Civil-section'>
        <div className='civil-row'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12'>
             <h3><b>Mock Interview of Toppers</b></h3>
              </div>
            </div>
          <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             </div>
             <p>UPSC Mock Interview of Utsav Anand AIR 26</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/tJi2ZtWTjTQ" title="SHASHVAT SANGWAN AIR 34 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             </div>
             <p>UPSC Mock Interview of Shashvat Saghwan AIR 34</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/f-oW3Aw_PvM" title="RAVI KUMAR | AIR 38 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             </div>
             <p>UPSC Mock Interview of Ravi Kumar AIR 38</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/1uw2zQVA0c8" title="Anjali Shrotriya AIR 44 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/bVJeK5fFl7s" title="Shubham Shukla AIR 43 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             </div>
             <p>UPSC Mock Interview of Shubham Shulka AIR 43</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/1uw2zQVA0c8" title="Anjali Shrotriya AIR 44 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             </div>
             <p>UPSC Mock Interview of Anjali Shrotriya AIR 44</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/fBjZQFsbs64" title="NAMRATA CHOUBEY | AIR 73 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             </div>
             <p>UPSC Mock Interview of Namrata Choubey AIR 73</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/sa2Da5H2paM" title="SANKET KUMAR | AIR 105 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             </div>
             <p>UPSC Mock Interview of Sanket Kumar AIR 105</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/Y0J6q6DgRiA" title="Shantanu Malani | AIR 113 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             </div>
             <p>UPSC Mock Interview of Shantanu Malani AIR 113</p>
          </div>
          </div>
          </div>
          {/* Civil right Section Start */}
          {/* <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} />
              </div>
          </div> */}
          {/* Civil right Section End */}
        </div>
      </div>
      <div className='Civil-section'>
        <div className='civil-row'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12'>
             <h3><b>UPSC CSE - GENERAL STUDIES MAINS 2021 - EXPLANATION
             </b></h3>
              </div>
            </div>
          <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/hVVEHiehv0I" title="General Studies Mains 2021 Paper - I : History Explanation/ SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             </div>
             <p>General Studies Mains 2021 Paper - I : History Explanation/ SRIRAM's IAS</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/tJi2ZtWTjTQ" title="SHASHVAT SANGWAN AIR 34 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/-EYWcQb2j-E" title="General Studies Mains 2021 Paper - II : Indian Polity Explanation/ SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             </div>
             <p>General Studies Mains 2021 Paper - II : Indian Polity Explanation/ SRIRAM's IAS</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/f-oW3Aw_PvM" title="RAVI KUMAR | AIR 38 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/vqwiptjvc5Q" title="General Studies Mains 2021 Paper - II : International Relations Explanation/ SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             </div>
             <p>General Studies Mains 2021 Paper - II : International Relations Explanation/ SRIRAM's IAS</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/1uw2zQVA0c8" title="Anjali Shrotriya AIR 44 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/bVJeK5fFl7s" title="Shubham Shukla AIR 43 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/_POxFkBJOEs" title="General Studies Mains 2021 Paper - IV : Ethics Explanation/ SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             </div>
             <p>General Studies Mains 2021 Paper - IV : Ethics Explanation/ SRIRAM's IAS</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/1uw2zQVA0c8" title="Anjali Shrotriya AIR 44 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/C9Ui2SAhvFo" title="General Studies Mains 2021 Paper - III : Indian Economy Explanation/ SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             </div>
             <p>General Studies Mains 2021 Paper - III : Indian Economy Explanation/ SRIRAM's IAS</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/fBjZQFsbs64" title="NAMRATA CHOUBEY | AIR 73 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/reDBZtpodL8" title="General Studies Mains 2021 Paper - III : Ecology and Environment  Explanation/ SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             </div>
             <p>General Studies Mains 2021 Paper - III : Ecology & Environment Explanation/ SRIRAM's IAS</p>
          </div>
          </div>
          </div>
          {/* Civil right Section Start */}
          {/* <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} />
              </div>
          </div> */}
          {/* Civil right Section End */}
        </div>
      </div>
      <div className='Civil-section'>
        <div className='civil-row'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12'>
             <h3><b>Toppers Talk
             </b></h3>
              </div>
            </div>
          <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/Wh6gAT4DQFI" title="AIR 30 (UPSC 2020) - Mr. Divyanshu Choudhary interacts with our students" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             </div>
             <p>AIR 30 (UPSC 2020) - Mr. Divyanshu Choudhary interacts with our students</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/tJi2ZtWTjTQ" title="SHASHVAT SANGWAN AIR 34 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/64ZQcYkyygI" title="AIR 32 (UPSC 2020) - Mr. Jagat Sai interacts with our students" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             </div>
             <p>AIR 32 (UPSC 2020) - Mr. Jagat Sai interacts with our students</p>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/f-oW3Aw_PvM" title="RAVI KUMAR | AIR 38 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/uQwqF6lHUOI" title="AIR 43 (UPSC 2020) - Mr. Kanishka Sharma interacts with our students" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             </div>
             <p>AIR 43 (UPSC 2020) - Mr. Kanishka Sharma interacts with our students</p>
          </div>
          </div>
          </div>
          {/* Civil right Section Start */}
          {/* <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} />
              </div>
          </div> */}
          {/* Civil right Section End */}
        </div>
      </div>
      <div className='Civil-section'>
        <div className='civil-row'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12'>
             <h3><b>PUBLIC POLICY CONVERSATIONS
             </b></h3>
              </div>
            </div>
          <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/CYi7aW62p3k" title="SRIRAM&#39;s IAS - Public Policy Conversations - Episode 1 with Dr. Pronob Sen" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             </div>
             {/* <p>UPSC Mock Interview of Utsav Anand AIR 26</p> */}
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/tJi2ZtWTjTQ" title="SHASHVAT SANGWAN AIR 34 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/__xil1hoi0Y" title="SRIRAM&#39;s IAS - Public Policy Conversations - Episode 2 with General Deependra Singh Hooda" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             </div>
             {/* <p>UPSC Mock Interview of Shashvat Saghwan AIR 34</p> */}
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/f-oW3Aw_PvM" title="RAVI KUMAR | AIR 38 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/iY9x_0Gf05Q" title="SRIRAM&#39;s IAS - Public Policy Conversations - Episode 3 with Mr. Mahesh Vyas" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             </div>
             {/* <p>UPSC Mock Interview of Ravi Kumar AIR 38</p> */}
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/1uw2zQVA0c8" title="Anjali Shrotriya AIR 44 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/bVJeK5fFl7s" title="Shubham Shukla AIR 43 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/qqz7w3oS1Eg" title="SRIRAM&#39;s IAS - Public Policy Conversations - Episode 4 with Mr. Shyam Saran" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             </div>
             {/* <p>UPSC Mock Interview of Shubham Shulka AIR 43</p> */}
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/1uw2zQVA0c8" title="Anjali Shrotriya AIR 44 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/H4Tl9O56SZQ" title="SRIRAM&#39;s IAS - Public Policy Conversations - Episode 5 with Mr. Biswajit Dhar" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             </div>
             {/* <p>UPSC Mock Interview of Anjali Shrotriya AIR 44</p> */}
          </div>
          </div>
          </div>
          {/* Civil right Section Start */}
          {/* <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} />
              </div>
          </div> */}
          {/* Civil right Section End */}
        </div>
      </div>
      <div className='Civil-section'>
        <div className='civil-row'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12'>
             <h3><b>ONLINE CLASS - GLIMPSES
             </b></h3>
              </div>
            </div>
          <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/UvkPu7fePqA" title="Introduction to Indian Polity - Online Class" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             </div>
             {/* <p>UPSC Mock Interview of Utsav Anand AIR 26</p> */}
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/tJi2ZtWTjTQ" title="SHASHVAT SANGWAN AIR 34 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/rIJuz3x7LNQ" title="Online class - CSAT (5th July)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             </div>
             {/* <p>UPSC Mock Interview of Shashvat Saghwan AIR 34</p> */}
          </div>
          </div>
          </div>
          {/* Civil right Section Start */}
          {/* <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} />
              </div>
          </div> */}
          {/* Civil right Section End */}
        </div>
      </div>
      <div className='Civil-section'>
        <div className='civil-row'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12'>
             <h3><b>WEBINAR
             </b></h3>
              </div>
            </div>
          <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/UvkPu7fePqA" title="Introduction to Indian Polity - Online Class" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/0HQsQWgbTlk" title="Webinar on Preparation for General Studies in Civil Services Examination with Focus on Economics." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             </div>
             {/* <p>UPSC Mock Interview of Utsav Anand AIR 26</p> */}
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/tJi2ZtWTjTQ" title="SHASHVAT SANGWAN AIR 34 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/rIJuz3x7LNQ" title="Online class - CSAT (5th July)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/p4-BpQVSIJw" title="Civils: AP, Telangana యువత IAS, IPS అవ్వడం ఎలా? - Srirangam Sriramతో Interview | BBC Telugu" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             </div>
             {/* <p>UPSC Mock Interview of Shashvat Saghwan AIR 34</p> */}
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/tJi2ZtWTjTQ" title="SHASHVAT SANGWAN AIR 34 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/rIJuz3x7LNQ" title="Online class - CSAT (5th July)" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/f4jjRTMJ9LM" title="Indian Polity (Shankari Prasad | Golakhnath Case )" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             </div>
             {/* <p>UPSC Mock Interview of Shashvat Saghwan AIR 34</p> */}
          </div>
          </div>
          </div>
          {/* Civil right Section Start */}
          {/* <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} />
              </div>
          </div> */}
          {/* Civil right Section End */}
        </div>
      </div>
      <div className='Civil-section'>
        <div className='civil-row'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12'>
             <h3><b>UPSC CSE PRE 2020 GS (PAPER - 1) ANSWERS ALONG WITH EXPLANATIONS (SET – A)
             </b></h3>
              </div>
            </div>
          <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="539" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/iPcj8wg6BgA" title="Answers &amp; Explanations of UPSC Civil Services Preliminary Examination, 2020 General Studies Paper 1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             </div>
             {/* <p>UPSC Mock Interview of Utsav Anand AIR 26</p> */}
          </div>
          </div>
          </div>
          {/* Civil right Section Start */}
          {/* <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} />
              </div>
          </div> */}
          {/* Civil right Section End */}
        </div>
      </div>
      <div className='Civil-section'>
        <div className='civil-row'>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-lg-12'>
             <h3><b>OFFLINE SAMPLE CLASSES
             </b></h3>
              </div>
            </div>
          <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="539" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/Km8vL7f9Sgs" title="Mr. Sri Ram, SRIRAM’s IAS, on Economic Survey 2020; Budget 20-21 &amp; 15th Finance Commission." frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             </div>
             {/* <p>UPSC Mock Interview of Utsav Anand AIR 26</p> */}
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12 p-3 text-center'>
             <div>
             {/* <iframe width="544" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/tJi2ZtWTjTQ" title="SHASHVAT SANGWAN AIR 34 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             <iframe width="539" height="300" className='iframe-responsive' src="https://www.youtube.com/embed/aBB8bz4llvE" title="The Partition of India | SRIRAMs IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
             {/* <iframe width="544" height="300" src="https://www.youtube.com/embed/GKnNa9uYGow" title="Utsav Anand AIR 26 | UPSC CSE 2021 IAS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe> */}
             </div>
             {/* <p>UPSC Mock Interview of Shashvat Saghwan AIR 34</p> */}
          </div>
          </div>
          </div>
          {/* Civil right Section Start */}
          {/* <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} />
              </div>
          </div> */}
          {/* Civil right Section End */}
        </div>
      </div>
      {/* Civil About Section End */}
    </>
  )
}

export default Video;