import React, { useEffect, useState } from 'react'
import "./ResultPreview.css";

function ResultPreview1({ totalQuestions, correctQuestions, wrongQuestions, notVisitedQuestions, correctMarks, wrongMarks }) {

    return (
        <>
            <div className='container-fluid p-4 mt-4 rounded-3 card'>
                <div className='row'>
                    <div className='col-lg-4 col-md-6 col-sm-12  d-flex flex-column justify-content-center p-2'>
                        <div className='d-flex text-center flex-column justify-content-center border-white card rounded-2 px-4 py-3 shadow'>
                            <h4>{correctQuestions}</h4>
                            <hr className='my-0' />
                            <b className='text-darkblue'>CORRECT</b>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 d-flex flex-column justify-content-center p-2'>
                        <div className='d-flex text-center flex-column justify-content-center border-white card rounded-2 px-4 py-3 shadow'>
                            <h4>{wrongQuestions}</h4>
                            <hr className='my-0' />
                            <b className='text-darkblue'>INCORRECT</b>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 d-flex flex-column justify-content-center p-2'>
                        <div className='d-flex text-center flex-column justify-content-center border-white card rounded-2 px-4 py-3 shadow'>
                            <h4>{totalQuestions - (correctQuestions + wrongQuestions)}</h4>
                            <hr className='my-0' />
                            <b className='text-darkblue'>UNATTEMPTED</b>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 d-flex flex-column justify-content-center p-2'>
                        <div className='d-flex text-center flex-column justify-content-center border-white card rounded-2 px-4 py-3 shadow'>
                            <h4>{notVisitedQuestions}</h4>
                            <hr className='my-0' />
                            <b className='text-darkblue'>NOT VISITED</b>
                        </div>
                    </div>
                          <div className='col-lg-4 col-md-6 col-sm-12 d-flex flex-column justify-content-center p-2'>
                    <div className='d-flex text-center flex-column justify-content-center border-white card rounded-2 px-4 py-3 shadow'>
                            <h4>{correctMarks?.toFixed(2) }</h4>
                        <hr className='my-0'/>
                        <b className='text-darkblue'>POSITIVE SCORE</b>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 col-sm-12 d-flex flex-column justify-content-center p-2'>
                    <div className='d-flex text-center flex-column justify-content-center border-white card rounded-2 px-4 py-3 shadow'>
                            <h4>{wrongMarks?.toFixed(2)}</h4>
                        <hr className='my-0'/>
                        <b className='text-darkblue'>NEGATIVE MARKS</b>
                        </div>
                    </div> 
                </div>
            </div>
        </>
    )
}

export default ResultPreview1
