import React, { useEffect, useState } from 'react';
import "../CurrentPage/Current.css";
import Buttontabs from '../Buttontabs/Buttontabs';
import InfiniteScroll from 'react-infinite-scroll-component'; // Importing InfiniteScroll
import Current1 from '../CurrentAffairsComponent/Current1';
import CurrentAffair from '../CurrentAffair/CurrentAffair';
import ImageComponent1 from '../ImageComponent/ImageComponent1';
import { fetchcuetdetails } from '../DynamicCourse/api';
import Content from '../AllPageContent/AllPageContent';

function Cuet() {
    const [contentToShow, setContentToShow] = useState('CUET');
    // const [news, setNews] = useState([]);
    const [cuetdetails, setcuetdetails] = useState('')
    const componentName = "Cuet"; 
    // const [hasMore, setHasMore] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // const response = await fetch(`http://68.178.172.24:8001/coursewise-news`);
                // const data = await response.json();
                // setNews(data.data);

                const data = await fetchcuetdetails();
                setcuetdetails(data.Description)
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    const paragraphs = cuetdetails || [];
    // const fetchMoreData = async () => {
    //     try {
    //         const response = await fetch(`http://68.178.172.24:8001/blogs`);
    //         const newData = await response.json();
    //         const newarraydata = newData.data
    //           console.log(newData.data)
    //         // Check if newData is iterable (an array)
    //         if (Array.isArray(newarraydata)) {
    //             setNews(prevNews => [...prevNews, ...newarraydata]);
    //             // Update hasMore state based on whether there is more data to fetch
    //             setHasMore(newData.length > 0);
    //         } else {
    //             // If newData is not iterable, it might indicate an error or unexpected response
    //             console.error('Error: Unexpected response format:', newData);
    //             setHasMore(false); // Assuming there's no more data to fetch in case of unexpected response
    //         }
    //     } catch (error) {
    //         console.error('Error fetching more data:', error);
    //     }
    // };

    // const data2 = news;
    // console.log(data2)
    // const data3 = news;

    return (
        <>
            <div className='Cuet-background'>
                <div className='current-main-box'>
                    <h1>Common University Entrance Test (CUET) »»</h1>
                    <Buttontabs componentName={componentName} setContentToShow={setContentToShow} />
                </div>
            </div>
            {/* Display Information Section Start
            <InfiniteScroll
                dataLength={news.length} // This is important to prevent infinite loop when the content is updated
                next={fetchMoreData}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
                endMessage={
                    <p style={{ textAlign: 'center' }}>
                        <b>No more data to show</b>
                    </p>
                }
            // Below is an example of how you can adjust the height for the scrolling container
            // height={400}
            >
           Display Information Section Start 
             {contentToShow === 'In News'
                    && news &&
                    <>
                        <Current1 CardData={data2} componentName={componentName} />
                    </>}
                {contentToShow === 'Editorial'
                    && news &&
                    <>
                        <Current1 CardData={data3} componentName={componentName} />
                    </>}
                {contentToShow === 'Yojana' && <div></div>}
                {contentToShow === 'Big Picture' && <div></div>}
                {contentToShow === 'Insight Radio' && <div></div>}
            </InfiniteScroll> */}
            <div className='container-fluid cuet-width py-4'>
                <div className='row'>
                <div className='col-lg-8 col-md-12 col-sm-12'>
                   {/* <h4>About CUET</h4>
                   <p>The <b style={{color: "red"}}>National Test Agency</b> (NTA) is entrusted with the responsibility of <b>conducting the Undergraduate entrance tests for all the Central Universities (CUs)</b> for the academic session 2022-2023. CUET will provide a <b>single window opportunity to students to seek admission in any of the Central Universities (CUs) across the country.</b></p> 
                   <p>The National Testing Agency (NTA) is <b>established by Ministry of Education,</b> MoE (Earlier known as Ministry of Human Resource Development-MHRD) as an independent/ autonomous, self-reliant and self-sustained premier testing organization.</p>
                   <p>This year, the <b style={{color: "red"}}>CUET (UG) - 2022</b> will be conducted in <b>Computer Based Test (CBT) Mode.</b> The aspirants who desire to take <b>admission into the Undergraduate Programmes,</b> have to appear for this test. The aspirants must also refer to the Information Bulletin for admission to <b>Undergraduate programmes</b> of the desired <b>Central Universities (CUs)</b> for the same.</p>
                   <p><b>The details of the programmes offered by Central Universities (CUs) is available on their respective websites.</b></p>
                   <p>The Application Forms will be made available Online from 02nd April 2022, and the link for the same will be made available on the official website, that is, https://cuet.samarth.ac.in/ . The Candidates who desire to appear for CUET (UG) – 2022 are advised to go through the required eligibility of the desired University/ Universities for selecting the options from UG Programmes being offered.</p>
                   <p>The <b style={{color: "red"}}>National Test Agency</b> (NTA) is entrusted with the responsibility of <b>conducting the Undergraduate entrance tests for all the Central Universities (CUs)</b> for the academic session 2022-2023. CUET will provide a <b>single window opportunity to students to seek admission in any of the Central Universities (CUs) across the country.</b></p> 
                   <p>The National Testing Agency (NTA) is <b>established by Ministry of Education,</b> MoE (Earlier known as Ministry of Human Resource Development-MHRD) as an independent/ autonomous, self-reliant and self-sustained premier testing organization.</p>
                   <p>This year, the <b style={{color: "red"}}>CUET (UG) - 2022</b> will be conducted in <b>Computer Based Test (CBT) Mode.</b> The aspirants who desire to take <b>admission into the Undergraduate Programmes,</b> have to appear for this test. The aspirants must also refer to the Information Bulletin for admission to <b>Undergraduate programmes</b> of the desired <b>Central Universities (CUs)</b> for the same.</p>
                   <p><b>The details of the programmes offered by Central Universities (CUs) is available on their respective websites.</b></p>
                   <p>The Application Forms will be made available Online from 02nd April 2022, and the link for the same will be made available on the official website, that is, https://cuet.samarth.ac.in/ . The Candidates who desire to appear for CUET (UG) – 2022 are advised to go through the required eligibility of the desired University/ Universities for selecting the options from UG Programmes being offered.</p>
                   <p>The <b style={{color: "red"}}>National Test Agency</b> (NTA) is entrusted with the responsibility of <b>conducting the Undergraduate entrance tests for all the Central Universities (CUs)</b> for the academic session 2022-2023. CUET will provide a <b>single window opportunity to students to seek admission in any of the Central Universities (CUs) across the country.</b></p> 
                   <p>The National Testing Agency (NTA) is <b>established by Ministry of Education,</b> MoE (Earlier known as Ministry of Human Resource Development-MHRD) as an independent/ autonomous, self-reliant and self-sustained premier testing organization.</p>
                   <p>This year, the <b style={{color: "red"}}>CUET (UG) - 2022</b> will be conducted in <b>Computer Based Test (CBT) Mode.</b> The aspirants who desire to take <b>admission into the Undergraduate Programmes,</b> have to appear for this test. The aspirants must also refer to the Information Bulletin for admission to <b>Undergraduate programmes</b> of the desired <b>Central Universities (CUs)</b> for the same.</p>
                   <p><b>The details of the programmes offered by Central Universities (CUs) is available on their respective websites.</b></p>
                   <p>The Application Forms will be made available Online from 02nd April 2022, and the link for the same will be made available on the official website, that is, https://cuet.samarth.ac.in/ . The Candidates who desire to appear for CUET (UG) – 2022 are advised to go through the required eligibility of the desired University/ Universities for selecting the options from UG Programmes being offered.</p> */}
                   <Content paragraphs={paragraphs} />
                </div>
                <div className='col-lg-4 card px-0 col-md-12 col-sm-12'>
                    <CurrentAffair componentName={componentName} />
                    <ImageComponent1/>
                </div>
                </div>
            </div>
        </>
    );
}

export default Cuet;