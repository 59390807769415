import React from 'react';
import { Helmet } from 'react-helmet-async';
import "../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection.css";
import CivilTopSection from '../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection';
import userImage from "../../../Image/user.png";
import aboutImage from "../../../Image/aboutbuilding.jpg";
import CivilrightSection from '../../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection';
import Content from '../../AllPageContent/AllPageContent';
import ButtonPage from '../../ButtonPage/ButtonPage';
import CurrentAffair from '../../CurrentAffair/CurrentAffair';

function Currentvideo() { 
  const Buttonname = "Multiplebutton";
  const componentName = "currentaffairs";

  // Banner Content For CivilAbout Page
  const data = {
    academyName: 'Current Affairs Videos',
    userImage: userImage,
    userName: 'Priya Rathore',
    Image: aboutImage
  };

  // Content For CivilAbout Page
  // const paragraphstatic = [
  //   {
  //     text: "Founded by SRIRANGAM SRIRAM more than 3 decades ago, SRIRAM’s IAS has emerged as one of the leading institutes in India with the vision to be the Centre of Excellence and the mission to develop knowledgeable and innovative leaders.",
  //     styles: [
  //       { word: 'Founded by SRIRANGAM SRIRAM more than 3 decades ago, SRIRAM’s IAS has emerged as one of the leading institutes in India with the vision to be the Centre of Excellence and the mission to develop knowledgeable and innovative leaders.', style: 'font-weight: bold; color: #26156d;' },
  //       { word: 'SRIRAM’s IAS', style: 'font-weight: bold; color: red;' }
  //     ]
  //   },
  //   {
  //     text: "We are a premier institute dedicated to training candidates appearing for Civil Services Examination. We have 35 years of experience in guiding candidates successfully to prestigious government positions.Our faculty is predominantly in house though we do borrow services of distinguished professors and public servants (Civil and Defence).We have developed techniques and methods for cutting short the learning curve because year on year we get to interact with tens of thousands of fine young minds.We hesitate to brag about our record of success because much of the work is that of the students.Rest assured our success rate has been the highest.",
  //     styles: [
  //       { word: 'Shri S. Baliyan', style: 'font-weight: bold;' },
  //       { word: 'History and Art and Culture', style: 'font-weight: bold;' },
  //       { word: '‘A Compendium of Indian Art & Culture’', style: 'font-weight: bold; color: #C00000;' }
  //     ]
  //   },
  //   {
  //     text: "Our High Success Rate",
  //     images: [{src: aboutImage}],
  //     styles: [
  //       { word: 'Our High Success Rate', style: 'font-weight: bold; color: #26156d;' },
  //       { word: 'topped the UPSC Civil Services Examination', style: 'font-weight: bold;' }
  //     ]
  //   },
  //   {
  //     text: "Our experienced and dynamic team of experts and teachers is convinced that the method of teaching should be completely oriented on examination lines. Previous question papers, spanning decades are the basis of our approach. As the examination trends change, so does our emphasis. In fact quite often the trends are predicted in the classroom. That explains our high success rate.",
  //     styles: [
  //       { word: 'holistic learning approach', style: 'font-weight: bold;' },
  //       { word: 'Maximum attention', style: 'font-weight: bold;' },
  //       { word: 'build solid foundations', style: 'font-weight: bold;' },
  //       { word: 'concept building', style: 'font-weight: bold;' },
  //       { word: 'regular interaction', style: 'font-weight: bold;' }
  //     ]
  //   },
  //   {
  //     text: "The strategy of SRIRAMs IAS for making students benefit immensely by their association with us is",
  //     styles: [{
  //       word: 'The strategy of SRIRAMs IAS for making students benefit immensely by their association with us is', style: 'font-weight: bold;'
  //     }],
  //     lists: [
  //       ' Exam-oriented classroom coaching.',
  //       'Study material that is indispensable.',
  //       'Relevant information in the form of Q&As, MCQs and other channels.',
  //       'No dumbing down in the classroom because learning is interactive and accountable process.',
  //       'Every teacher everywhere has answers but SRIRAMs IAS teachers have CORRECT ANSWERS.',
  //       'Our notice board is an effective way of briefing the students on the most important current events and analysis - selected from all top newspapers from the country like The Indian Express, Times of India, Hindustan Times and some pink papers like Economic Times.',
  //       'We are the only institution that gives a three page digital handout everyday. Analyzing all current events contained in The Hindu newspaper Delhi edition.',
  //        'Everday our students are familiarized with MCQs so that their preparation in the Preliminary Examination is on a sure footing.',
  //        'Our Test Series is of immense value as thousands of discerning student would vouch for.',
  //        'Our students are given AAA study material for atleast 50 GENERAL ESSAYS in the course of the coaching programme.',
  //        'Our monthly current affairs magazine adds decisive value to the aspirants preparation.',
  //        'Everyday students are given practice question which they answer for evaluation by our experienced team.',
  //        'Our research team consists of young students from prestigious universities who have ample experience with the examination process including the Personality Test.',
  //     ] 
  //   },
  //   {
  //     text: "Students are continously interacting with the teachers before and after the class; by email; and in the faculty room and on WhatsApp.",
  //     styles: [{
  //       word: 'SRIRAMs IAS is at your service till you are in Civil Service.', style: 'font-weight: bold;'
  //     }],
  //   },
  //   {
  //     text: "SRIRAMs IAS is at your service till you are in Civil Service.",
  //     styles: [{
  //       word: 'SRIRAMs IAS is at your service till you are in Civil Service.', style: 'font-weight: bold;'
  //     }],
  //   }
  // ];
 
  return (
    <>
      <Helmet>
        <title>About Us | Insight IAS, Delhi | Best IAS Coaching in Delhi</title>
        <meta name="description" content="Insight Academy Delhi, is the leading platform in India that provides coaching for candidates preparing for UPSC, IAS, CUET, NEET, UGC-NET examinations."></meta>
        <meta name="keywords" content="about insight ias, about us, in sight ias, insight ias, Baliyan Sir, balyan sir, baliyan sir history, history optional by baliyan sir, baliyan sir notes, history notes, insight ias delhi, insight ias india, insightsonindia, insights IAS, insight delhi, insight ias test series, insight test series, insight ias main test series, insight prelims test series, upsc coaching in delhi, civil services coaching in delhi, neet coaching center in delhi, ugc net coaching in delhi, CUET coaching in delhi, CUET form 2022, how to apply for CUET, CUET 2022, CUET Exam, Pattern of CUET Exam, Syllabus of CUET Exam, NEET Practice Tests."></meta>
        <meta name="subject" content="Insight Academy Delhi, is the leading platform in India that provides coaching for candidates preparing for UPSC, IAS, CUET, NEET, UGC-NET examinations."></meta>
      </Helmet>
      {/* Civil About Section Start */}
      <section className='civilabout'>
        {/* Civil Top Section Start */}
        <CivilTopSection
          academyName={data.academyName}
          userImage={data.userImage}
          userName={data.userName}
          Image={data.Image}
        />
        {/* Civil Top Section End */}
      </section>
      <div className='Civil-section'>
        <div className='civil-row'>
          <div className='col-lg-12 col-md-12 col-sm-12 me-2'>
            {/* Content Component Start */}
            {/* <Content paragraphstatic={paragraphstatic} /> */}
            {/* Content Component End */}
            {/* <ButtonPage Pagename={Buttonname} /> */}
            {/* <div className='continer-fluid'> */}
              {/* <div className='row'>
                <div className='col-lg-6 p-3'>
                    <div className='card p-4 pb-5' style={{background: "#26156d", color: "white"}}>
                      <h1 className='fw-bold'>OUR VISION</h1>
                      <p>To be the Center of Excellence to ensure our students are successful and that achieve their aspirations within a time frame.</p>
                    </div>
                </div>
                <div className='col-lg-6 p-3'>
                  <div className='card p-4' style={{background: "#db261f", color: "white"}}>
                    <h1 className='fw-bold'>OUR MISSION</h1>
                    <p>To empower aspirants of Civil Services to excel by Imparting to them right and relevant information and perspective. The aim is to help them acquire appropriate analytical skills to be on their own.</p>
                  </div>
                </div>
              </div> */}
            {/* </div> */}
            {/* <div className='container'> */}
              {/*  */}
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <div>
                    <img src='https://sriramsias.com/images/upsc-daily-current-affairs-banner.jpg' style={{width: "100%"}} alt=''/>
                  </div>
                </div>
              </div>
          </div>
          {/* Civil right Section Start */}
          {/* <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} />
              </div>
          </div> */}
          {/* Civil right Section End */}
        </div>
      </div>
      {/* Civil About Section End */}
    </>
  )
}

export default Currentvideo;