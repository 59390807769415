import React, { useEffect, useState } from 'react';
import "./QuestionLayout.css"
import Questionsummary from '../Questionsummary/Questionsummary';
import Questionpreview2 from '../Questionpreview/Questionpreview2';
import ResultPreview from '../../ResultPreview/ResultPreview';
import { Link, useNavigate } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { finalsubmittest } from '../../DynamicCourse/api';
import axios from 'axios';
import { BASE_URL1 } from '../../../insightConfig/insightConfig';

function QuestionLayout2({ QuizSectionid, QuizSubjectid, selectedSection, selectedSubject, setNewSubject, selectedSectionIndex, setSelectedSectionIndex, sectionsLength }) {
  const [quizQuestions, setQuizQuestions] = useState([])
  // const [notVisitedQuestions, setNotVisitedQuestions] = useState([])
  const [suggestedQuestions, setSuggestedQuestions] = useState([])
  const [loadingQuestions, setLoadingQuestions] = useState(true)
  const [visitedQuestionIDsArray, setVisitedQuestionIDsArray] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState({})
  const [suggestedQuestion, setSuggestedQuestion] = useState({})
  // console.log(quizQuestions, QuizSubjectid, selectedSection, selectedSubject)
  // console.log(currentQuestion)

  const uniqueUserId = localStorage.getItem('uniqueUserId');
  console.log(visitedQuestionIDsArray)

  useEffect(() => {
    if (QuizSectionid && QuizSubjectid) {
      axios.get(`${BASE_URL1}/getQuizQuestions/${QuizSubjectid}/${QuizSectionid}/${uniqueUserId}`)
        .then(response => {
          setQuizQuestions(response.data.data)
         
          setLoadingQuestions(false)
          // setqueslength(response.data.data.questionsArray);
          // setsubjectname(response.data.data.subject);
          // setsectionname(response.data.data.section); 
          // setQuizQuestionurl(response.data.data.questionsArray[currentQuestionIndex].titleURL)
        })
        .catch(error => {
          setLoadingQuestions(false)
          console.error('Error fetching data:', error);
        });
    }
  }, [QuizSubjectid, QuizSectionid]);


  useEffect(() => {
    let notVisitedQuestions = quizQuestions.filter((question) => question.Sn !== currentQuestion.Sn && !visitedQuestionIDsArray.includes(question.Sn))
    // setNotVisitedQuestions(notVisitedQuestions)
    if (notVisitedQuestions.length > 10) {
      setSuggestedQuestions(notVisitedQuestions.slice(0, 10))
    } else {
      setSuggestedQuestions(notVisitedQuestions)
    }
  }, [visitedQuestionIDsArray])

  // console.log(suggestedQuestions)




  // return (
  //   <h1>Woring</h1>
  // )

  //   const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  //   const [sessionid, setsessionid] = useState(null);
  //   // const [iscorrect, setiscorrect] = useState(null);
  //   const alert = useAlert();
  //   const navigate = useNavigate();
  //   const [queslength, setqueslength] = useState(() => {
  //     const storedQuestionArrayString = localStorage.getItem('QuizquestionArray');
  //     return storedQuestionArrayString ? JSON.parse(storedQuestionArrayString) : [];
  //   })

  //   const [finish, setfinish] = useState(false);
  //   const [time, settime] = useState(false);
  //   const [clearoption, setClearOption] = useState(null)
  //   const [userstorage, setUserstorage] = useState(() => {
  //     const userdata = localStorage.getItem('userdata');
  //     return userdata ? JSON.parse(userdata) : [];
  //   });

  //   //  (userstorage)
  //   const [unanswereddata, setunanswereddata] = useState(() => {
  //     const nouserdata = localStorage.getItem('nouserdata');
  //     return nouserdata ? JSON.parse(nouserdata) : [];
  //   });

  //   //  (unanswereddata)
  //   const [markanswereddata, setmarkanswereddata] = useState(() => {
  //     const markuserdata = localStorage.getItem('markuserdata');
  //     return markuserdata ? JSON.parse(markuserdata) : [];
  //   });
  //   //  (markanswereddata)

  //   const updateUnansweredData = () => {
  //     const userDataJSON = localStorage.getItem('userdata');
  //     const nouserDataJSON = localStorage.getItem('nouserdata');
  //     if (userDataJSON && nouserDataJSON) {
  //       const userData = JSON.parse(userDataJSON);
  //       const nouserData = JSON.parse(nouserDataJSON);
  //       const answeredQuestions = userData.map(data => data.questionindex);
  //       const remainingUnanswered = nouserData.filter(data => !answeredQuestions.includes(data.questionindex));
  //       setunanswereddata(remainingUnanswered);
  //       const unuserAnswerData = nouserData.find(userdata => userdata.questionindex === currentQuestionIndex);
  //       if (unuserAnswerData) {
  //         setClearOption(null);
  //       }
  //     }
  //   };

  //   const noattemedAnswer = (currentQuestionIndex, answer, answerindex) => {
  //     // Retrieve existing user data array from local storage
  //     const existingUserDataJSON = localStorage.getItem('nouserdata');
  //     let existingUserData = [];
  //     if (existingUserDataJSON) {
  //       existingUserData = JSON.parse(existingUserDataJSON);
  //     }

  //     // Update or append the user's answer for the current question
  //     const updatedUserData = existingUserData.map(nouserdata => {
  //       if (nouserdata.questionindex === currentQuestionIndex) {
  //         return { ...nouserdata, useranswer: answer, useranswerindex: answerindex };
  //       }
  //       return nouserdata;
  //     });

  //     // If no user data exists for the current question, append it
  //     if (!updatedUserData.some(nouserdata => nouserdata.questionindex === currentQuestionIndex)) {
  //       updatedUserData.push({ questionindex: currentQuestionIndex, useranswer: answer, useranswerindex: answerindex });
  //     }

  //     // Store the updated user data array back into local storage
  //     localStorage.setItem('nouserdata', JSON.stringify(updatedUserData));
  //     setunanswereddata(updatedUserData)
  //   };

  //   // const handleNextQuestion = () => {
  //   //   if (!clearoption) {
  //   //     noattemedAnswer(currentQuestionIndex, '', null)
  //   //   }

  //   //   if (currentQuestionIndex < queslength.length - 1) {
  //   //     setCurrentQuestionIndex(currentQuestionIndex + 1);
  //   //     const userDataJSON = localStorage.getItem('userdata');
  //   //     if (userDataJSON) {
  //   //       const userData = JSON.parse(userDataJSON);
  //   //       //  ("next " + userstorage)
  //   //       userData.map(item =>
  //   //         //  ("nextindex  " + item[currentQuestionIndex].useranswerindex)
  //   //         setClearOption(queslength[item[currentQuestionIndex].questionindex - 1].options.indexOf(item[currentQuestionIndex - 1].useranswer))
  //   //       )
  //   //     }
  //   //     // setClearOption(null)
  //   //   }

  //   // };

  //   const handleNextQuestion = () => {
  //     if (!clearoption) {
  //       noattemedAnswer(currentQuestionIndex, '', null);
  //     }

  //     setiscorrect(null)

  //     // Check if there are more questions to navigate to
  //     if (currentQuestionIndex < queslength.length - 1) {
  //       setCurrentQuestionIndex(currentQuestionIndex + 1);
  //       const userDataJSON = localStorage.getItem('userdata');
  //       if (userDataJSON) {
  //         const userData = JSON.parse(userDataJSON);
  //         // Find the user's answer for the next question
  //         const nextQuestionAnswer = userData.find(data => data.questionindex === currentQuestionIndex + 1);
  //         if (nextQuestionAnswer) {
  //           // Set the clear option to the user's selected option for the next question
  //           setClearOption(nextQuestionAnswer.useranswerindex);
  //         } else {
  //           // If the user hasn't answered the next question, clear the option
  //           setClearOption(null);
  //         }
  //       }
  //     } else {
  //       // If all questions have been answered, redirect to the first question
  //       // setCurrentQuestionIndex(0);
  //       const userDataJSON = localStorage.getItem('userdata');
  //       if (userDataJSON) {
  //         const userData = JSON.parse(userDataJSON);
  //         // Find the user's answer for the first question
  //         const firstQuestionAnswer = userData.find(data => data.questionindex === 0);
  //         if (firstQuestionAnswer) {
  //           // Set the clear option to the user's selected option for the first question
  //           setClearOption(firstQuestionAnswer.useranswerindex);
  //         } else {
  //           // If the user hasn't answered the first question, clear the option
  //           setClearOption(null);
  //         }
  //       }
  //     }


  //   };


  //   const markAnswer = (currentQuestionIndex, answer, answerindex) => {
  //     // Retrieve existing user data array from local storage
  //     const existingUserDataJSON = localStorage.getItem('markuserdata');
  //     let existingUserData = [];
  //     if (existingUserDataJSON) {
  //       existingUserData = JSON.parse(existingUserDataJSON);
  //     }

  //     // Update or append the user's answer for the current question
  //     const updatedUserData = existingUserData.map(markuserdata => {
  //       if (markuserdata.questionindex === currentQuestionIndex) {
  //         return { ...markuserdata, useranswer: answer, useranswerindex: answerindex };
  //       }
  //       return markuserdata;
  //     });

  //     // If no user data exists for the current question, append it
  //     if (!updatedUserData.some(markuserdata => markuserdata.questionindex === currentQuestionIndex)) {
  //       updatedUserData.push({ questionindex: currentQuestionIndex, useranswer: answer, useranswerindex: answerindex });
  //     }

  //     // Store the updated user data array back into local storage
  //     localStorage.setItem('markuserdata', JSON.stringify(updatedUserData));
  //     setmarkanswereddata(updatedUserData)
  //   };

  //   const handleMarkQuestion = () => {
  //     // Check if the current question has been answered
  //     const isOptionSelected = userstorage.some(data => data.questionindex === currentQuestionIndex);
  //     if (!isOptionSelected) {
  //       noattemedAnswer(currentQuestionIndex, '', null);
  //       markAnswer(currentQuestionIndex, '', null)
  //       // const nouserDataJSON = localStorage.getItem('nouserdata');
  //       // const nouserData = JSON.parse(nouserDataJSON);
  //       // const isDifferentIndex = nouserData.map(data => data);
  //       //  ("array1   " + isDifferentIndex)
  //       // const updatedMarkedData = [...markanswereddata, isDifferentIndex[currentQuestionIndex]] || [];
  //       // localStorage.setItem('markuserdata', JSON.stringify(updatedMarkedData));
  //       // setmarkanswereddata(updatedMarkedData);
  //       //  ("Question marked");
  //     }

  //     // If the question has been answered, update the markanswereddata
  //     if (isOptionSelected) {
  //       const userDataJSON = localStorage.getItem('userdata');
  //       const userData = JSON.parse(userDataJSON);
  //       // const isDifferentIndex2 = userData.map(data => data);
  //       if (userDataJSON) {
  //         userData.map(item => {
  //           // setClearOption(item.useranswerindex)
  //           if (item.questionindex === currentQuestionIndex) {
  //             markAnswer(item.questionindex, item.useranswer, item.useranswerindex)
  //           }
  //         });
  //       }
  //       // const updatedMarkedData2 = [...markanswereddata, isDifferentIndex2[currentQuestionIndex]] || [];
  //       // localStorage.setItem('markuserdata', JSON.stringify(updatedMarkedData2));
  //       // setmarkanswereddata(updatedMarkedData2);
  //     }
  //     if (currentQuestionIndex < queslength.length - 1) {
  //       setCurrentQuestionIndex(currentQuestionIndex + 1);
  //     }
  //   };

  //   const loadPreviousAnswer = () => {
  //     // Retrieve user data from local storage
  //     const userDataJSON = localStorage.getItem('userdata');
  //     if (userDataJSON) {
  //       const userData = JSON.parse(userDataJSON);
  //       setUserstorage(userData);

  //       // Check if the previous question index is within the valid range
  //       const previousIndex = currentQuestionIndex - 1;
  //       if (previousIndex >= 0) {
  //         // Check if there is a user answer for the previous question
  //         const previousUserData = userData.find(data => data.questionindex === previousIndex);
  //         if (previousUserData) {
  //           // If there is a user answer for the previous question, set the clear option
  //           const userAnswerIndex = previousUserData.useranswerindex;
  //           if (userAnswerIndex !== null && userAnswerIndex !== undefined) {
  //             setClearOption(queslength[previousIndex].options.indexOf(previousUserData.useranswer));
  //           } else {
  //             setClearOption(null);
  //           }
  //         } else {
  //           // If there is no user answer for the previous question, clear the selection
  //           setClearOption(null);
  //         }
  //       } else {
  //         // If the previous question index is out of range, clear the selection
  //         setClearOption(null);
  //       }
  //     } else {
  //       // If there is no user data in local storage, clear the selection
  //       setClearOption(null);
  //     }
  //   };


  //   const handlePreviousQuestion = () => {
  //     loadPreviousAnswer()

  //     if (currentQuestionIndex > 0) {
  //       setCurrentQuestionIndex(currentQuestionIndex - 1);
  //     }
  //   };

  //   const saveUserAnswer = (currentQuestionIndex, answer, answerindex, answercheck) => {
  //     // Retrieve existing user data array from local storage
  //     const existingUserDataJSON = localStorage.getItem('userdata');
  //     let existingUserData = [];
  //     if (existingUserDataJSON) {
  //       existingUserData = JSON.parse(existingUserDataJSON);
  //     }

  //     // Update or append the user's answer for the current question
  //     const updatedUserData = existingUserData.map(userData => {
  //       if (userData.questionindex === currentQuestionIndex) {
  //         return { ...userData, QNo: queslength[currentQuestionIndex].QNo, question: queslength[currentQuestionIndex].Question, useranswer: answer, useranswerindex: answerindex, useranswercheck: answercheck };
  //       }
  //       return userData;
  //     });

  //     // If no user data exists for the current question, append it
  //     if (!updatedUserData.some(userData => userData.questionindex === currentQuestionIndex)) {
  //       updatedUserData.push({ questionindex: currentQuestionIndex, QNo: queslength[currentQuestionIndex].QNo, question: queslength[currentQuestionIndex].Question, useranswer: answer, useranswerindex: answerindex, useranswercheck: answercheck, correctanswer: queslength[currentQuestionIndex].Answer });
  //     }

  //     // Store the updated user data array back into local storage
  //     localStorage.setItem('userdata', JSON.stringify(updatedUserData));
  //     setUserstorage(updatedUserData)
  //     // Set clearoption based on the user's answer index
  //     const userAnswerData = updatedUserData.find(userData => userData.questionindex === currentQuestionIndex);
  //     if (userAnswerData) {
  //       if (userAnswerData.useranswercheck === userAnswerData.correctanswer) {
  //         setiscorrect(true)
  //         settime(true)
  //         setTimeout(() => {
  //           settime(false)
  //         }, 50000)
  //         setClearOption(userAnswerData.useranswerindex);
  //       } else {
  //         setiscorrect(false)
  //         // setTimeout(()=>{
  //         //   setiscorrect(null)
  //         // },500)
  //         // setiscorrectgobal(iscorrect)
  //         setClearOption(userAnswerData.useranswerindex);
  //       }
  //     } else {
  //       setClearOption(null);
  //     }
  //     updateUnansweredData();
  //     // setiscorrectgobal(iscorrect)
  //   };



  //   const handlecheckanswer = async () => {
  //     // Clear previous scores

  //     // Retrieve user data from local storage
  //     const userDataJSON = localStorage.getItem('userdata');
  //     const userData = JSON.parse(userDataJSON);

  //     // Initialize arrays to store correct and incorrect answers
  //     let CorrectAnswers = [];
  //     let inCorrectAnswers = [];
  //     let positiveScore = 0;
  //     let negativeScore = 0;

  //     // Calculate scores
  //     if (userData) {
  //       userData.forEach(data => {
  //         const correctAnswer = queslength[data.questionindex].Answer;
  //         // Check if user answered the question
  //         if (data.useranswer !== null && data.useranswer !== undefined) {
  //           if (data.useranswercheck === correctAnswer) {
  //             positiveScore += 2;
  //             CorrectAnswers.push({
  //               questionIndex: data.questionindex,
  //               QNo: data.QNo,
  //               question: data.question,
  //               useranswer: data.useranswer, // Use data.useranswer instead of data.useranswercheck
  //               useranswercheck: data.useranswercheck, // Use data.useranswercheck
  //               correctAnswer: correctAnswer
  //             });
  //             setcorrectdataarray(CorrectAnswers);
  //           } else {
  //             negativeScore += 0.67;
  //             inCorrectAnswers.push({
  //               questionIndex: data.questionindex,
  //               QNo: data.QNo,
  //               question: data.question,
  //               useranswer: data.useranswer, // Use data.useranswer instead of data.useranswercheck
  //               useranswercheck: data.useranswercheck, // Use data.useranswer instead of data.useranswercheck
  //               correctAnswer: correctAnswer
  //             });
  //             setincorrectdataarray(inCorrectAnswers);
  //           }
  //         }
  //       });
  //     }
  //     setPositiveScore(positiveScore);
  //     setNegativeScore(parseFloat(negativeScore.toFixed(2)));



  //     // Store correct and incorrect answers in local storage
  //     localStorage.setItem('correctAnswers', JSON.stringify(CorrectAnswers));
  //     localStorage.setItem('incorrectAnswers', JSON.stringify(inCorrectAnswers));

  //     // Store scores in local storage
  //     localStorage.setItem('positiveScore', JSON.stringify(positiveScore));
  //     localStorage.setItem('negativeScore', JSON.stringify(negativeScore));
  //     try {
  //       // Call the finalsubmittest function with the necessary parameters
  //       const responseData = await finalsubmittest({
  //         attempted: userstorage.length,
  //         correct: CorrectAnswers.length,
  //         answersheet: userData,
  //         testsession: sessionid
  //       });

  //       // Log response data

  //       // Navigate to the final result page
  //       navigate('/testseries/finalresult');

  //       // Show success alert
  //       alert.show('Test submitted successfully');
  //     } catch (error) {
  //       // Show error alert if submission fails
  //       alert.show('Test submission failed. Please try again.');
  //     }
  //   };




  //   const clearAnswer = () => {
  //     // Retrieve userData array from local storage
  //     const userDataJSON = localStorage.getItem('userdata');
  //     if (userDataJSON) {
  //       const userData = JSON.parse(userDataJSON);
  //       // Remove the user's answer for the current question
  //       const updatedUserData = userData.filter(data => data.questionindex !== currentQuestionIndex);
  //       // Update the userData array in local storage
  //       localStorage.setItem('userdata', JSON.stringify(updatedUserData));
  //       // Clear the selected option
  //       setUserstorage(updatedUserData);
  //       setClearOption(null);
  //     }

  //     // Retrieve nouserData array from local storage
  //     const nouserDataJSON = localStorage.getItem('nouserdata');
  //     if (nouserDataJSON) {
  //       const nouserData = JSON.parse(nouserDataJSON);
  //       // Remove the user's answer for the current question
  //       const updatedUserData1 = nouserData.filter(data => data.questionindex !== currentQuestionIndex);
  //       // Update the nouserData array in local storage
  //       localStorage.setItem('nouserdata', JSON.stringify(updatedUserData1));
  //       // Clear the selected option
  //       setunanswereddata(updatedUserData1);
  //       setClearOption(null);
  //     }

  //     // Retrieve markuserData array from local storage
  //     const markuserDataJSON = localStorage.getItem('markuserdata');
  //     if (markuserDataJSON) {
  //       const markuserData = JSON.parse(markuserDataJSON);
  //       // Remove the user's answer for the current question
  //       const updatedUserData3 = markuserData.filter(data => data.questionindex !== currentQuestionIndex);
  //       // Update the markuserData array in local storage
  //       localStorage.setItem('markuserdata', JSON.stringify(updatedUserData3));
  //       // Clear the selected option
  //       setmarkanswereddata(updatedUserData3);
  //       setClearOption(null);
  //     }
  //   };

  //   // Function to handle clearing the answer
  //   const handleClear = () => {
  //     clearAnswer();
  //   };

  const renderhtml = (paragraphs) => {
    return <i className='renderhtml' dangerouslySetInnerHTML={{ __html: paragraphs }} />;
  };
  console.log(selectedSection, selectedSubject)
  return (
    <>
      <div className='container-fluid p-3 pt-0'>
        <div className='row'>
          <div className='col-lg-12' >
            <Questionpreview2 quizQuestions={quizQuestions} selectedSection={selectedSection} selectedSubject={selectedSubject} loadingQuestions={loadingQuestions} setVisitedQuestionIDsArray={setVisitedQuestionIDsArray} setCurrentQuestion={setCurrentQuestion} suggestedQuestion={suggestedQuestion} setSuggestedQuestion={setSuggestedQuestion} setNewSubject={setNewSubject} selectedSectionIndex={selectedSectionIndex} setSelectedSectionIndex={setSelectedSectionIndex} sectionsLength={sectionsLength} />
            <p className='py-2'><b>More Questions Selected Just For You. Attempt Now!</b></p>

            <div className='mt-3 card questionpreviewpadding' style={{ boxShadow: "0 3px 10px rgba(0,0,0,0.3)" }} >
              {suggestedQuestions.map((question, index) => (
                <p onClick={() => setSuggestedQuestion(question)} key={index} className='p-3 suggestedquestion'><b>{index + 1}. {renderhtml(question.Question)}</b></p>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  )
  // }
}

export default QuestionLayout2