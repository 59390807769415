import React, { useState } from 'react';
import "./Calendar.css";
import { FaRegRectangleList } from "react-icons/fa6";
import { BsFillCalendarWeekFill } from "react-icons/bs";

function RangeCalendar({ searchDateWise, startDate, setStartDate, endDate, setEndDate }) {
    return (
        <>
            <div className='bg-input-padding rangepadding'>
                <div className='card C-background m-0 mb-3 p-3 py-1'>
                    <div className='row my-2 d-flex align-items-center'>
                        <div className='col-lg-5'>
                            {/* Start Date: */}
                            <div className="input-group flex-nowrap">
                                <span className="input-group-text Purple-background pb-0" id="addon-wrapping"><h6>Start Date:</h6></span>
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder="MM/DD/YYYY"
                                    aria-label="Start Date"
                                    Placeholder="Select a date"
                                    aria-describedby="addon-wrapping"
                                    value={startDate}
                                    onChange={(e) => setStartDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='my-2 col-lg-5'>
                            {/* End Date: */}
                            <div className="input-group flex-nowrap">
                                <span className="input-group-text Purple-background pb-0" id="addon-wrapping"><h6>End Date:</h6></span>
                                <input
                                    type="date"
                                    className="form-control"
                                    placeholder="MM/DD/YYYY"
                                    aria-label="End Date"
                                    Placeholder="Select a date"
                                    aria-describedby="addon-wrapping"
                                    value={endDate}                                   
                                    onChange={(e) => setEndDate(e.target.value)}
                                />
                            </div>
                        </div>
                        <div className='col-lg-2'>
                            <span className="input-group-text d-flex justify-content-center onClick={handleApply} Purple-background pb-0" style={{cursor: "pointer"}} onClick={searchDateWise} id="addon-wrapping"><h6>Apply</h6></span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RangeCalendar

// import React, { useState } from 'react';
// import "./Calendar.css";

// function RangeCalendar() {
//     const [startDate, setStartDate] = useState("2023-01-31");
//     const [endDate, setEndDate] = useState(() => {
//         const today = new Date();
//         const year = today.getFullYear();
//         const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
//         const day = String(today.getDate()).padStart(2, '0');
//         return `${year}-${month}-${day}`;
//     });

//     const handleStartDateChange = (event) => {
//         setStartDate(event.target.value);
//     };

//     const handleEndDateChange = (event) => {
//         setEndDate(event.target.value);
//     };

//     const handleApply = () => {
//         // Implement functionality to apply the selected date range
//          ("Start Date:", startDate);
//          ("End Date:", endDate);
//     };

//     return (
//         <>
//             <div className='bg-input-padding pe-3'>
//                 <div className='card C-background m-0 mb-3 p-3 py-1'>
//                     <div className='row my-2 d-flex align-items-center'>
//                         <div className='col-lg-5'>
//                             <div className="input-group flex-nowrap">
//                                 <span className="input-group-text Purple-background pb-0" id="addon-wrapping"><h6>Start Date:</h6></span>
//                                 <input
//                                     type="date"
//                                     className="form-control"
//                                     placeholder="MM/DD/YYYY"
//                                     aria-label="Start Date"
//                                     placeholder="Select a date"
//                                     aria-describedby="addon-wrapping"
//                                     value={startDate}
//                                     onChange={handleStartDateChange}
//                                 />
//                             </div>
//                         </div>
//                         <div className='my-2 col-lg-5'>
//                             <div className="input-group flex-nowrap">
//                                 <span className="input-group-text Purple-background pb-0" id="addon-wrapping"><h6>End Date:</h6></span>
//                                 <input
//                                     type="date"
//                                     className="form-control"
//                                     placeholder="MM/DD/YYYY"
//                                     aria-label="End Date"
//                                     placeholder="Select a date"
//                                     aria-describedby="addon-wrapping"
//                                     value={endDate}
//                                     onChange={handleEndDateChange}
//                                 />
//                             </div>
//                         </div>
//                         <div className='col-lg-2'>
//                             <button className="btn btn-primary" onClick={handleApply}>Apply</button>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </>
//     )
// }

// export default RangeCalendar;
