import React from 'react';
import { Helmet } from 'react-helmet-async';
import "../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection.css";
import CivilTopSection from '../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection';
import userImage from "../../../Image/user.png";
import aboutImage from "../../../Image/aboutbuilding.jpg";
import CivilrightSection from '../../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection';
import Content from '../../AllPageContent/AllPageContent';
import ButtonPage from '../../ButtonPage/ButtonPage';
import CurrentAffair from '../../CurrentAffair/CurrentAffair';

function Podcast() { 
  const Buttonname = "Multiplebutton";
  const componentName = "currentaffairs";

  // Banner Content For CivilAbout Page
  const data = {
    academyName: 'Podcasts',
    userImage: userImage,
    userName: 'Priya Rathore',
    Image: aboutImage
  };

  // Content For CivilAbout Page
  const paragraphstatic = [
    {
      text: "Civil Services Podcasts are a great way to stay up to date on current events, learn about new topics, and be informed. We have podcasts for UPSC aspirants, and new ones are popping up from time to time. You can listen to these podcasts on your computer or phone, and they're free. SRIRAM's IAS Civils cloud podcasts are out there for you to support your UPSC exam preparation.",
      styles: [
        { word: 'Founded by SRIRANGAM SRIRAM more than 3 decades ago, SRIRAM’s IAS has emerged as one of the leading institutes in India with the vision to be the Centre of Excellence and the mission to develop knowledgeable and innovative leaders.', style: 'font-weight: bold; color: #26156d;' },
        { word: 'SRIRAM’s IAS', style: 'font-weight: bold; color: red;' }
      ]
    }
  ];
 
  return (
    <>
      <Helmet>
        <title>About Us | Insight IAS, Delhi | Best IAS Coaching in Delhi</title>
        <meta name="description" content="Insight Academy Delhi, is the leading platform in India that provides coaching for candidates preparing for UPSC, IAS, CUET, NEET, UGC-NET examinations."></meta>
        <meta name="keywords" content="about insight ias, about us, in sight ias, insight ias, Baliyan Sir, balyan sir, baliyan sir history, history optional by baliyan sir, baliyan sir notes, history notes, insight ias delhi, insight ias india, insightsonindia, insights IAS, insight delhi, insight ias test series, insight test series, insight ias main test series, insight prelims test series, upsc coaching in delhi, civil services coaching in delhi, neet coaching center in delhi, ugc net coaching in delhi, CUET coaching in delhi, CUET form 2022, how to apply for CUET, CUET 2022, CUET Exam, Pattern of CUET Exam, Syllabus of CUET Exam, NEET Practice Tests."></meta>
        <meta name="subject" content="Insight Academy Delhi, is the leading platform in India that provides coaching for candidates preparing for UPSC, IAS, CUET, NEET, UGC-NET examinations."></meta>
      </Helmet>
      {/* Civil About Section Start */}
      <section className='civilabout'>
        {/* Civil Top Section Start */}
        <CivilTopSection
          academyName={data.academyName}
          userImage={data.userImage}
          userName={data.userName}
          Image={data.Image}
        />
        {/* Civil Top Section End */}
      </section>
      <div className='Civil-section'>
        <div className='civil-row'>
          <div className='col-lg-12 col-md-12 col-sm-12 me-2'>
            {/* Content Component Start */}
            <Content paragraphstatic={paragraphstatic} />
            {/* Content Component End */}
            <div className='container'>
            <div className='row'>
              <div className='col-lg-4 col-md-6 col-sm-12'>
                <div>
                <iframe width="354" height="226" src="https://www.youtube.com/embed/ZQJIokdZYow" title="WHO global summit on traditional medicine | Sriram&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
   <div>
   <iframe width="354" height="226" src="https://www.youtube.com/embed/kQzOLC6DEDc" title="WINDFALL TAXES | POWERCAST" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
<div>
<iframe width="354" height="226" src="https://www.youtube.com/embed/vobyVuTMRRI" title="Nagorno Karabakh| Powercast" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
<div>
<iframe width="354" height="226" src="https://www.youtube.com/embed/vobyVuTMRRI" title="Nagorno Karabakh| Powercast" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
<div>
<iframe width="354" height="226" src="https://www.youtube.com/embed/8AC-4VR3_6A" title="Israels Judicial Reforms &amp; Controversy: Advocates &amp; Critics | Isreal | UPSC" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
                <div>
                <iframe width="354" height="226" src="https://www.youtube.com/embed/4_QZIO1S3aM" title="ARMED FORCES SPECIAL POWERS ACT, 1958 | UPSC Current Affairs PODCASTS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
                <div>
                <iframe width="354" height="226" src="https://www.youtube.com/embed/3jLfgNmIeHo" title="Indo Pacific Economic Framework | UPSC Current Affairs PODCASTS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'> 
<div>
<iframe width="354" height="226" src="https://www.youtube.com/embed/MYaHYvJVZog" title="UGC Allows Two Simultaneous Full Time Program | UPSC Current Affairs PODCASTS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
<div>
<iframe width="354" height="226" src="https://www.youtube.com/embed/EghpmTKxqS8" title="Shale Gas in India | UPSC Current Affairs PODCASTS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
<div>
<iframe width="354" height="226" src="https://www.youtube.com/embed/PnoQLYMKz74" title="Semiconductor Chip shortage and Impact on India | PODCASTS | SRIRAM&#39;s IAS" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
                <div>
                <iframe width="354" height="226" src="https://www.youtube.com/embed/GRU7eUUaEks" title="IPCC AR6 And It’s Relevance To India" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
                <div>
                <iframe width="354" height="226" src="https://www.youtube.com/embed/WApJPejuFnk" title="Academic Bank of Credits" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
<div>
<iframe width="354" height="226" src="https://www.youtube.com/embed/6B340XYtiH0" title="Nipun Bharat Program" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</div>
              </div>
              <div className='col-lg-4 col-md-6 col-sm-12'>
<div>
<iframe width="354" height="226" src="https://www.youtube.com/embed/OLPMjZf1WKs" title="Heat Domes" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</div>
              </div>
            </div>
          </div>
          </div>
          
          {/* Civil right Section Start */}
          {/* <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} />
              </div>
          </div> */}
          {/* Civil right Section End */}
        </div>
      </div>
      {/* Civil About Section End */}
    </>
  )
}

export default Podcast;