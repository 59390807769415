import React, { useEffect, useRef, useState } from 'react'
import Buttontab from '../Buttontabs/Buttontabs'
import QuestionLayoutCoursetest from '../QuizComponent/QuestionLayout/QuestionLayoutCoursetest';
import Reportcard from '../Reportcard/Reportcard';
import { BASE_URL, BASE_URL1 } from '../../insightConfig/insightConfig';
import { RxCross2 } from "react-icons/rx";
import { useAlert } from 'react-alert';
import QuestionLayoutreattempted from '../QuizComponent/QuestionLayout/QuestionLayoutreattempted';
import { Modal } from 'react-bootstrap';
import swal from "sweetalert";
import { Link } from 'react-router-dom';
import axios from 'axios';

function Prelims({ lessonData, courseData }) {
  const [openModal, setOpenModal] = useState(false);

  const [startTest, setStartTest] = useState(false);
  const [userinput, setuserinput] = useState("")
  const alert = useAlert();


  const hanldeStartQuiz = () => {
    console.log(lessonData.TestPassword)
    if (lessonData.TestPassword) {
      setOpenModal(true)
    } else {
      setStartTest(true)
    }
  }
  const submitPassword = () => {
    console.log(userinput, lessonData.TestPassword)
    if (userinput == lessonData.TestPassword) {
      setOpenModal(false)
      setStartTest(true)
    } else {
      swal("Incorrect Password", "Please Enter Correct Password", { icon: "error" })
    }
    setuserinput('')
  }






  const viewReportJSX = <Link to={`/course-lesson-result`} state={{ lessonData, courseData }} type="button" className="btn bg-Question-white btn-md ms-md-3 col-md-2" >VIEW REPORT</Link>

  const reAttemptJSX = <button to={`/testseries/finalresult`} type="button" className="btn bg-Question-white btn-md ms-md-3 col-md-2" onClick={hanldeStartQuiz}>REATTEMPT</button>

  const continueJSX = <button type="button" className="btn bg-Question-white btn-md ms-md-3 col-md-2" onClick={hanldeStartQuiz}>CONTINUE</button>

  const startJSX = <button type="button" className="btn bg-Question-white btn-md ms-md-3 col-md-2" onClick={hanldeStartQuiz}>START</button>



  const selectTestStartButtons = () => {
    const allTestsReportsArray = JSON.parse(localStorage.getItem("LessonReportArray"));
    const currentTest = allTestsReportsArray?.find((test) => test.sectionId == lessonData?.SectionID);
    console.log(currentTest)
    if (currentTest) {
      if (lessonData?.Status == 'Complete') {
        return (
          <>
            {continueJSX}
            {viewReportJSX}
          </>
        )
      } else {
        return (
          <>
            {continueJSX}
          </>

        )
      }

    } else {
      if (lessonData?.Status == 'Complete') {
        if (lessonData?.AttemptCount < lessonData?.TotalAttempt) {
          return (
            <>
              {reAttemptJSX}
              {viewReportJSX}
            </>

          )
        } else {
          return (
            <>
              {viewReportJSX}
            </>

          )
        }
      } else {
        return (
          <>
            {startJSX}
          </>

        )
      }
    }

  }

  const getLeesonData = () => {
    if (lessonData?.Status == 'Complete') {
      return { ...lessonData, reAttempt: true }
    } else if (lessonData?.Status != 'Complete') {
      return { ...lessonData, reAttempt: false }
    } else {
      return lessonData;
    }
  }

  return (
    <>
      {!startTest && <div className="d-flex justify-content-start align-items-baseline">
        <button
          className={`btn btn-prelims-margin activebuttontabmcq pb-2 pt-2 fs-6`}
          type="button">PT MCQs</button>
        {
          selectTestStartButtons()
        }



      </div>}
      {startTest && <><QuestionLayoutreattempted lessonData={getLeesonData()} courseData={courseData}  /></>}

      <Modal onHide={setOpenModal} show={openModal} size="md" backdrop="static" centered
        keyboard={false} >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" style={{ color: "#ff5722" }} id="exampleModalLabel">Please Enter Quiz Password to proceed</h5>
            <button type="button" className="btn-close" onClick={() => setOpenModal(false)}></button>
          </div>
          <div className="modal-body pt-3">
            <div className="mb-3 ">
              <label htmlFor="inputPassword" style={{ color: "#303972" }} className="form-label">Password<span className="text-danger">*</span></label>
              <input type="text" class="form-control py-1" value={userinput} onChange={(e) => setuserinput(e.target.value)} id="inputPassword" style={{ textTransform: 'none' }} />
            </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-danger btn-xs" onClick={() => setOpenModal(false)}>Close</button>
            <button type="button" className="btn btn-xs text-light" onClick={submitPassword} style={{ backgroundColor: "#303972" }}  >Submit</button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Prelims;