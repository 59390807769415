import React from 'react';
import { GoClockFill } from "react-icons/go";
import { FaPlay } from "react-icons/fa";
import { Link } from 'react-router-dom';
import "./CivilCard.css";
import aboutimg from "../../../../Image/aboutimg.png"
import defaultNewsImage from '../CivilTopSection/defaultNewsImage.png'

function CivilCard({ CardData }) {
    // Check if CardData is defined before mapping over it
    if (!CardData || !Array.isArray(CardData)) {
        return null; // or display a loading indicator, error message, etc.
    }

    const convertTimestamp = (originalTimestamp) => {
        // Parse the original timestamp into a Date object
        const originalDate = new Date(originalTimestamp)
        // Convert the Date object into the desired format
        const formattedDate = originalDate.toLocaleString('en-US', {
          month: 'short',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: true,
        });
    
        return formattedDate;
      }  

      function isValidUrl(string) {
        try {
          new URL(string);
          return true;
        } catch (_) {
          return false;  
        }
      }

    return (
        <>
            {CardData.map((item, index) => {
                // Extract category tags for the current item
                const categoryTagsArray = item?.CategoryTag?.split(',');
                return (
                    <div className='CivilCard-background' key={index}>
                        <div className="CivilCard-box">
                            <div className="text-center CivilCard-imgs">
                                <Link to={`/readmore/News/${item.ImageAlt}`}>
                                    {/* <img src={isValidUrl(item.Image) ? item.Image : aboutimg} className='CivilCard-img' alt={`Images ${index}`} /> */}
                                    <img src={item.Image} onError={(e) => {
                                        e.target.src = aboutimg;
                                    }}  className='CivilCard-img' alt={`Images ${index}`} />
                                </Link>
                            </div>
                            <div className="currenttextsec d-flex justify-content-between">
                                <div className='current-box1'>
                                    <ul className='d-flex civilcard-tags'>
                                        {categoryTagsArray?.map((tag, tagIndex) => (
                                            <li key={tagIndex} className='badge m-1 bg-danger p-2 bg-pureorange'>{tag.trim()}</li>
                                        ))}
                                    </ul>
                                    <h6>
                                        <Link className='list-group-item' to={`/readmore/News/${item.ImageAlt}`}>
                                           {item?.Title}
                                        </Link>
                                    </h6>
                                    <p><GoClockFill /> {convertTimestamp(item.PublishDate)}</p>
                                    <div className="current-box2">
                                        <Link to={`/readmore/News/${item.ImageAlt}`} className='list-group-item bg-darkblue p-2 fw-bold text-white'>Read More <FaPlay className='text-pureorange' /></Link>
                                        {/* <Link to={`/readmore/News/${item.ImageAlt}`} className='p-2'>read more</Link> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </>
    );
}

export default CivilCard;