import React, { useState } from 'react';
import "./CivilBatch.css";
import { BsPersonWorkspace } from "react-icons/bs";
import { BsCardChecklist } from "react-icons/bs";
import { BsCalendar2Week } from "react-icons/bs";
import { Link, useNavigate } from 'react-router-dom';
import { FaFacebook } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTelegram } from "react-icons/fa";
import { MdOutlineFileDownload } from "react-icons/md";
import { IoLogoAndroid, IoIosShareAlt } from "react-icons/io";
import { Modal, Spinner } from 'react-bootstrap';
import { FaRupeeSign } from "react-icons/fa";
import ButtonModal from '../../../ToggleButton/ToggleButton';
import YoutubeModal from '../../../YoutubeModal/YoutubeModal';
import CourseShareComponent from '../../../CourseShareComponent/CourseShareComponent';
import CourseShareMobileComponent from '../../../CourseShareComponent/CourseShareMobileComponent.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import CivilModal from '../CivilModal/CivilModal';
import 'animate.css';
import axios from 'axios';
import swal from "sweetalert";
import { BASE_URL } from '../../../../insightConfig/insightConfig.js';



function CivilBatch({ courseData, reloadCourseData, setReloadCourseData }) {
    const [isOpen, setIsOpen] = useState(false);
    const [isOpen1, setIsOpen1] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);
    const [modalBox, setModalBox] = useState(false)
    const navigate = useNavigate();
    const storedAuthToken = localStorage.getItem('authtoken');
    const { CourseTitle, CourseID, BatchId, BatchName, Image, Price, Discount, Subscriber, SyllabusPdf, isEnrolled, VideoId, Topic, TopicCount, Bdate } = courseData;
    const p = Price || 0;
    const displayPrice = Math.round(p * (100 - courseData?.Discount) * 0.01);
    console.log(isEnrolled)
    const formatDate = (dateString) => {
        // Create a Date object from the dateString
        const date = new Date(dateString);
        // Get the day, month, and year
        const day = date.getDate();
        const monthIndex = date.getMonth();
        const year = date.getFullYear();
        // Define the month names array
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
            "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];
        // Format the date string
        const formattedDate = `${day} ${monthNames[monthIndex]}, ${year}`;
        return formattedDate;
    };
    const formattedDate = formatDate(Bdate);




    const openModal = () => {
        setIsOpen(true);
    };

    const openModal1 = () => {
        setIsOpen1(true);
    };

    const openModal2 = () => {
        setIsOpen2(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const closeModal1 = () => {
        setIsOpen1(false);
    };

    const closeModal2 = () => {
        setIsOpen2(false);
    };

    const openModal3 = () => {
        setIsOpen3(true);
    };

    const closeModal3 = () => {
        setIsOpen3(false);
        // navigate(`/course/${coursename}`)
    };
    console.log(Subscriber)
    const formattedNumber1 = displayPrice.toLocaleString('en-IN');

    const formattedNumber2 = Price.toLocaleString('en-IN');

    const handleEnroll = async () => {
        let data = { courseid: CourseID, email: storedAuthToken, batchid: BatchId, amount: 0, transactionid: "Free Course", status: 'Success' }
        if (displayPrice > 0) {
            console.log('open modal')
            setIsOpen3(true);

        } else {
            if (storedAuthToken) {
                const response = await axios.post(`${BASE_URL}/${'payorder'}`, data);
                if (response.data.statusCode == 200) {
                    setReloadCourseData(!reloadCourseData)
                    swal('Enrolled Course Successfully', {
                        icon: "success",
                        buttons: {
                            confirm: {
                                text: "OK",
                                value: true,
                                visible: true,
                                closeModal: true
                            }
                        }
                    })

                }
            } else {
                navigate('/login');
            }
        }


    }


    return (
        <>
            {/* Civil Batch Section Start */}
            <div className='CivilBatch-row laptop-view'>
                <div className='col-lg-8 col-md-6 col-sm-12'>
                    <div className='row'>
                        <h1 className='fs-3 text-white d-flex justify-content-between'>{CourseTitle + ' : ' + BatchName}</h1>
                        <hr className='text-white' />
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-4 col-sm-4 text-center p-3'>
                            <BsCalendar2Week className='text-white icon-size mb-2' />
                            <h5 className='text-white fs-6'>STARTS : {formattedDate}</h5>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-4 text-center p-3'>
                            <BsPersonWorkspace className='text-white icon-size mb-2' />
                            {isEnrolled != 'enrolled' ? <> {Subscriber > 0 ? <h5 className='text-white fs-6'>{Subscriber} SEATS AVAILABLE</h5> : <h5 className='text-white fs-6'>Admissions Closed</h5>}</> : <h5 className='text-white fs-6'>Admissions Closed</h5>}
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-4 text-center p-3'>
                            <BsCardChecklist className='text-white icon-size mb-2' />
                            <h5 className='text-white fs-6'>{TopicCount} LESSONS</h5>
                        </div>
                    </div>
                    <div className='row'>
                        <div className="d-flex gap-3">
                            <button disabled={!SyllabusPdf} className="btn bg-orange w-50 fw-bold fs-6 p-2" type="button" onClick={() => window.open(SyllabusPdf, '_blank')}><MdOutlineFileDownload className='fw-bold mb-1 fs-5' />Download Syllabus</button>
                            <Link className="btn bg-purple w-50 fw-bold fs-6 p-2" to="#" onClick={openModal} type="button"><IoLogoAndroid className='fw-bold mb-1 fs-5' />Download App</Link>

                            {storedAuthToken && isEnrolled != 'enrolled' && <>{VideoId ? <Link className="btn bg-orange w-50 fw-bold fs-6 p-2" to="#" onClick={openModal1} type="button"><BsPersonWorkspace className='fw-bold mb-1 fs-5' /> Demo Class</Link> : ""}</>}

                            {/* {isEnrolled != 'enrolled' && storedAuthToken ? <>{VideoId ? <Link className="btn bg-orange w-50 fw-bold fs-6 p-2" to="#" onClick={openModal1} type="button"><BsPersonWorkspace className='fw-bold mb-1 fs-5' /> Demo Class</Link> : ""}</> : <Link className="btn bg-orange w-50 fw-bold fs-6 p-2" to="/login" type="button"><BsPersonWorkspace className='fw-bold mb-1 fs-5' /> Demo Class</Link>} */}
                        </div>
                    </div>
                    <div className='row py-0'>
                        <p className='text-white batch-para'>
                            {Topic}
                            {/* {Price !== 0 && (Discount !== 0 ? <h1 className='text-white fs-2'> <FaRupeeSign className='fs-4' />{displayPrice}<b className='fs-4 fw-normal px-1 text-decoration-line-through'><FaRupeeSign className='fs-5' />{Price}</b><span className='text-white fs-5 px-0'>{Discount}% off</span></h1> : <h1 className='text-white fs-2'><FaRupeeSign /> {displayPrice}</h1>)} */}
                        </p>
                        <ul className='d-flex justify-content-bewteen'>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-primary p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaFacebook />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-success p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaWhatsapp />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-danger p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaYoutube />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-primary p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaTelegram />
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className='row'>
                        {/* <ul className='d-flex justify-content-bewteen'>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-primary p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaFacebook />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-success p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaWhatsapp />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-danger p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaYoutube />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-primary p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaTelegram />
                                </Link>
                            </li>
                        </ul> */}
                    </div>
                </div>
                <div className='col-lg-3 col-md-6 col-sm-12'>
                    <div className='d-grid pb-2'>
                        <img src={Image} className="batch-img" alt='...' />
                    </div>
                    <div className='row'>
                        {isEnrolled != 'enrolled' && (Discount !== 0 ? <h1 className='text-white ps-2 fs-4'> <FaRupeeSign className='fs-4' />{formattedNumber1}<b className='fs-6 fw-normal px-2 text-decoration-line-through'><FaRupeeSign className='fs-6' />{formattedNumber2}</b><span className='text-white fs-6 px-0'>{Discount}% off</span></h1> : <h1 className='text-white fs-2'><FaRupeeSign /> {formattedNumber1}</h1>)}
                    </div>
                    <div className='row'>
                        <div className="d-grid gap-3">
                            {isEnrolled == 'enrolled' ? <Link className="btn bg-orange fw-bold fs-5 p-2" to="#" type="button">ENROLLED</Link> :
                                (storedAuthToken ? <Link className="btn bg-orange fw-bold fs-5 p-2" to="#" onClick={handleEnroll} type="button"><FaRupeeSign /> {formattedNumber1} {displayPrice < 1 ? 'ENROLL FOR FREE' : 'ENROLL'}</Link>
                                    :
                                    <Link className="btn bg-orange fw-bold fs-5 p-2" to="/login" type="button"><FaRupeeSign /> {formattedNumber1} {displayPrice < 1 ? 'ENROLL FOR FREE' : 'ENROLL'}</Link>
                                )
                            }

                            <Link className=" btn bg-green fw-bold fs-5 p-2" to="https://api.whatsapp.com/send?phone=919821333936" target='_blank' type="button"><FaWhatsapp className='fw-bold mb-1 fs-5' /> 982 133 3936</Link>
                        </div>
                        <div className='d-grid pt-2'>
                            {storedAuthToken && isEnrolled == 'enrolled' ? <Link className="btn bg-orange w-100 fw-bold fs-6 p-2" to="https://docs.google.com/forms/d/1LAL_L1mBhQWFpRUQVn9RgC7KpeguCGe81KTgAedFmTQ/viewform?edit_requested=true" target='_blank' type="button">BOOK DELIVERY ADDRESS</Link> : <></>}
                        </div>

                    </div>
                </div>
            </div>

            <div className='CivilBatch-row phone-view'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                    <div className='pb-2'>
                        <img src={Image} className="batch-img" alt='...' />
                    </div>
                    <div className='row py-1'>
                        {/* <h1 className='fs-3 text-white d-flex flex-wrap justify-content-between'>{batchName} <span className='d-flex align-items-center py-2'><Link className='fs-6 text-decoration-none text-white' onClick={openModal2} ><IoIosShareAlt /> Share</Link></span></h1> */}
                        <h1 className='fs-3 text-white d-flex justify-content-between'>{CourseTitle + ' : ' + BatchName}</h1>
                        <hr className='text-white' />
                    </div>
                    <div className='py-3 general-icon-container'>
                        <div className='col-lg-4 text-center p-3'>
                            <BsCalendar2Week className='text-white icon-size mb-2' />
                            <h6 className='text-white'>STARTS : {formattedDate}</h6>
                        </div>
                        <div className='col-lg-4 text-center p-3'>
                            <BsPersonWorkspace className='text-white icon-size mb-2' />
                            {isEnrolled != 'enrolled' ? <> {Subscriber > 0 ? <h5 className='text-white fs-6'>{Subscriber} SEATS AVAILABLE</h5> : <h5 className='text-white fs-6'>Admissions Closed</h5>}</> : <h5 className='text-white fs-6'>Admissions Closed</h5>}
                        </div>
                        <div className='col-lg-4 text-center p-3'>
                            <BsCardChecklist className='text-white icon-size mb-2' />
                            <h6 className='text-white'>{TopicCount} LESSONS</h6>
                        </div>
                    </div>
                    <div className='row py-1'>
                        <div className="d-grid gap-2">
                            <button disabled={!SyllabusPdf} className="btn bg-orange fw-bold fs-6 p-2" type="button" onClick={() => window.open(SyllabusPdf, '_blank')} ><MdOutlineFileDownload className='fw-bold mb-1 fs-5' />Download Syllabus</button>
                            <Link className="btn bg-purple fw-bold fs-6 p-2" to="#" onClick={openModal} type="button"><IoLogoAndroid className='fw-bold mb-1 fs-5' />Download App</Link>

                            {(isEnrolled != 'enrolled' && VideoId) ?
                                (storedAuthToken ?
                                    <Link className="btn bg-orange fw-bold fs-6 p-2" to="#" onClick={openModal1} type="button"><BsPersonWorkspace className='fw-bold mb-1 fs-5' /> Demo Class</Link>
                                    :
                                    <Link className="btn bg-orange fw-bold fs-6 p-2" to="/login" type="button"><BsPersonWorkspace className='fw-bold mb-1 fs-5' /> Demo Class</Link>
                                )
                                : null
                            }
                            <Link className="btn bg-green fw-bold fs-6 p-2" to="https://api.whatsapp.com/send?phone=919821333936" target='_blank' type="button"><FaWhatsapp className='fw-bold mb-1 fs-5' />982 133 3936</Link>
                        </div>
                    </div>
                    <div className='row py-1'>
                        <div className="d-grid gap-3">
                            {
                                isEnrolled == 'enrolled' ?
                                    <Link className="btn bg-orange fw-bold fs-6 p-2" to="#" type="button">ENROLLED</Link> : (
                                        storedAuthToken ?
                                            <button className="btn bg-orange fw-bold fs-6 p-2" to="#" onClick={handleEnroll} type="button"><FaRupeeSign /> {displayPrice} {displayPrice < 1 ? 'ENROLL FOR FREE' : 'ENROLL'}</button>
                                            :
                                            <button className="btn bg-orange fw-bold fs-6 p-2" to="/login" type="button"><FaRupeeSign /> {displayPrice} {displayPrice < 1 ? 'ENROLL FOR FREE' : 'ENROLL'}</button>
                                    )
                            }

                            {storedAuthToken && isEnrolled == 'enrolled' && <Link className="btn bg-orange fw-bold fs-6 p-2" to="https://docs.google.com/forms/d/1LAL_L1mBhQWFpRUQVn9RgC7KpeguCGe81KTgAedFmTQ/viewform?edit_requested=true" type="button">BOOK DELIVERY ADDRESS</Link>}
                        </div>
                    </div>
                    <div className='row'>
                        {isEnrolled != 'enrolled' && (Discount !== 0 ? <h1 className='text-white ps-2 fs-2'> <FaRupeeSign className='fs-2' />{displayPrice}<b className='fs-5 fw-normal px-2 text-decoration-line-through'><FaRupeeSign className='fs-5' />{Price}</b><span className='text-white fs-5 px-0'>{Discount}% off</span></h1> : <h1 className='text-white fs-2'><FaRupeeSign /> {displayPrice}</h1>)}
                    </div>
                    <div className='row py-2'>
                        <p className='text-white batch-para'>
                            {Topic}
                        </p>
                    </div>
                    <div className='row'>
                        <ul className='d-flex justify-content-bewteen'>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-primary p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaFacebook />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-success p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaWhatsapp />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-danger p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaYoutube />
                                </Link>
                            </li>
                            <li className='list-group-item m-1 my-0'>
                                <Link className='list-group-item bg-primary p-2 text-white fs-5 d-flex justify-content-center rounded-circle'>
                                    <FaTelegram />
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            {/* Civil Batch Section End */}
            <Modal show={isOpen} centered className="animate__animated animate__fadeIn mobiledownload">
                <ButtonModal closeModal={closeModal} />
            </Modal>
            <Modal show={isOpen1} centered className="animate__animated animate__fadeIn mobileyoutube">
                <YoutubeModal closeModal={closeModal1} VideoId={VideoId} />
            </Modal>
            <Modal dialogClassName="modal-90w" show={isOpen2} centered className="animate__animated animate__fadeIn">
                <Modal.Body className='mobilesharewidth' style={{ backgroundColor: '#FFC107', borderRadius: "9px", width: "610px" }}>

                </Modal.Body>
            </Modal>
            <Modal dialogClassName="modal-90vw" show={isOpen3} centered className="animate__animated animate__fadeIn">

            </Modal>
            <Modal show={isOpen3} centered className="animate__animated animate__fadeIn mobileyoutube">
                <CivilModal paymentFor='course' payamount={displayPrice} BatchID={BatchId} authtoken={storedAuthToken} CourseID={CourseID} setOpen={setIsOpen3} setModalBox={setModalBox} />
            </Modal>
            <Modal show={modalBox} top  backdrop="static" dialogClassName="custom-modal-width">
                <div className="modal-content" style={{ backgroundColor: "#f0f8ff", padding: "2rem", borderRadius: "10px" }}>
                    <div className="modal-body" style={{ padding: "2rem" }}>
                        <div className="mb-3 d-block text-center">
                            <Spinner
                                animation="border"                              
                                className="mt-5 mb-3"
                                size="xl"
                                style={{ width: "200px", height: "200px", color: "#ff2f00" }}
                             
                            />
                            <h5
                                className="modal-title mb-5"
                                id="exampleModalLabel"
                                style={{ color: "#17a2b8", fontFamily: "Arial, sans-serif" }}
                            >
                                Saving Order Details. This may take a moment...
                            </h5>
                        </div>
                    </div>
                </div>
            </Modal>

        </>
    );
}

export default CivilBatch;