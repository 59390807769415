import React from 'react';
import { Helmet } from 'react-helmet-async';
import "../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection.css";
import CivilTopSection from '../../ReusableComponent/CIVIL/CivilTopSection/CivilTopSection';
import userImage from "../../../Image/user.png";
import aboutImage from "../../../Image/aboutbuilding.jpg";
import CivilrightSection from '../../ReusableComponent/CIVIL/CivilrightSection/CivilrightSection';
import Content from '../../AllPageContent/AllPageContent';
import ButtonPage from '../../ButtonPage/ButtonPage';
import CurrentAffair from '../../CurrentAffair/CurrentAffair';
import ArticleCard from '../../Articlecard/Articlecard';

function CurrentFAQ() { 
  const Buttonname = "Multiplebutton";
  const componentName = "currentaffairs";

  // Banner Content For CivilAbout Page
  const data = {
    academyName: 'UPSC Current Affairs',
    userImage: userImage,
    userName: 'Priya Rathore',
    Image: aboutImage
  };

  // Content For CivilAbout Page
  // const paragraphstatic = [
  //   {
  //     text: "Founded by SRIRANGAM SRIRAM more than 3 decades ago, SRIRAM’s IAS has emerged as one of the leading institutes in India with the vision to be the Centre of Excellence and the mission to develop knowledgeable and innovative leaders.",
  //     styles: [
  //       { word: 'Founded by SRIRANGAM SRIRAM more than 3 decades ago, SRIRAM’s IAS has emerged as one of the leading institutes in India with the vision to be the Centre of Excellence and the mission to develop knowledgeable and innovative leaders.', style: 'font-weight: bold; color: #26156d;' },
  //       { word: 'SRIRAM’s IAS', style: 'font-weight: bold; color: red;' }
  //     ]
  //   },
  //   {
  //     text: "We are a premier institute dedicated to training candidates appearing for Civil Services Examination. We have 35 years of experience in guiding candidates successfully to prestigious government positions.Our faculty is predominantly in house though we do borrow services of distinguished professors and public servants (Civil and Defence).We have developed techniques and methods for cutting short the learning curve because year on year we get to interact with tens of thousands of fine young minds.We hesitate to brag about our record of success because much of the work is that of the students.Rest assured our success rate has been the highest.",
  //     styles: [
  //       { word: 'Shri S. Baliyan', style: 'font-weight: bold;' },
  //       { word: 'History and Art and Culture', style: 'font-weight: bold;' },
  //       { word: '‘A Compendium of Indian Art & Culture’', style: 'font-weight: bold; color: #C00000;' }
  //     ]
  //   },
  //   {
  //     text: "Our High Success Rate",
  //     images: [{src: aboutImage}],
  //     styles: [
  //       { word: 'Our High Success Rate', style: 'font-weight: bold; color: #26156d;' },
  //       { word: 'topped the UPSC Civil Services Examination', style: 'font-weight: bold;' }
  //     ]
  //   },
  //   {
  //     text: "Our experienced and dynamic team of experts and teachers is convinced that the method of teaching should be completely oriented on examination lines. Previous question papers, spanning decades are the basis of our approach. As the examination trends change, so does our emphasis. In fact quite often the trends are predicted in the classroom. That explains our high success rate.",
  //     styles: [
  //       { word: 'holistic learning approach', style: 'font-weight: bold;' },
  //       { word: 'Maximum attention', style: 'font-weight: bold;' },
  //       { word: 'build solid foundations', style: 'font-weight: bold;' },
  //       { word: 'concept building', style: 'font-weight: bold;' },
  //       { word: 'regular interaction', style: 'font-weight: bold;' }
  //     ]
  //   },
  //   {
  //     text: "The strategy of SRIRAMs IAS for making students benefit immensely by their association with us is",
  //     styles: [{
  //       word: 'The strategy of SRIRAMs IAS for making students benefit immensely by their association with us is', style: 'font-weight: bold;'
  //     }],
  //     lists: [
  //       ' Exam-oriented classroom coaching.',
  //       'Study material that is indispensable.',
  //       'Relevant information in the form of Q&As, MCQs and other channels.',
  //       'No dumbing down in the classroom because learning is interactive and accountable process.',
  //       'Every teacher everywhere has answers but SRIRAMs IAS teachers have CORRECT ANSWERS.',
  //       'Our notice board is an effective way of briefing the students on the most important current events and analysis - selected from all top newspapers from the country like The Indian Express, Times of India, Hindustan Times and some pink papers like Economic Times.',
  //       'We are the only institution that gives a three page digital handout everyday. Analyzing all current events contained in The Hindu newspaper Delhi edition.',
  //        'Everday our students are familiarized with MCQs so that their preparation in the Preliminary Examination is on a sure footing.',
  //        'Our Test Series is of immense value as thousands of discerning student would vouch for.',
  //        'Our students are given AAA study material for atleast 50 GENERAL ESSAYS in the course of the coaching programme.',
  //        'Our monthly current affairs magazine adds decisive value to the aspirants preparation.',
  //        'Everyday students are given practice question which they answer for evaluation by our experienced team.',
  //        'Our research team consists of young students from prestigious universities who have ample experience with the examination process including the Personality Test.',
  //     ] 
  //   },
  //   {
  //     text: "Students are continously interacting with the teachers before and after the class; by email; and in the faculty room and on WhatsApp.",
  //     styles: [{
  //       word: 'SRIRAMs IAS is at your service till you are in Civil Service.', style: 'font-weight: bold;'
  //     }],
  //   },
  //   {
  //     text: "SRIRAMs IAS is at your service till you are in Civil Service.",
  //     styles: [{
  //       word: 'SRIRAMs IAS is at your service till you are in Civil Service.', style: 'font-weight: bold;'
  //     }],
  //   }
  // ];

  const articles = [
    {
        title: "2G Ethanol Production in India: FAQs",
        date: "Sep 12, 2024",
        content: "2G Ethanol Production in India: FAQs 1. What is 2G ethanol? 2G ethanol, or second-generation ethanol, is a type of biofuel produced from non-food sources like agricultural residues (e.g., sugarcane bagasse, corn stover, rice straw). This contrasts with 1G ethanol, which is derived from food crops like sugarcane and corn. 2. Why is India focusing on 2G ethanol production? India aims to achieve 20% ethanol blending in petrol (E20) by 2025-26, reducing its reliance on imported fossil fuels and improving energy security. 2G ethanol offers a sustainable solution by using agricultural waste that would otherwise be burned...",
        link: "#"
    },
    {
        title: "GIFT CITY an IFSC",
        date: "Sep 10, 2024",
        content: "GIFT CITY an IFSC 1. What is an International Financial Services Centre (IFSC) and how does it work? An International Financial Services Centre (IFSC) is a designated area that provides financial services to customers outside the domestic economy. IFSCs handle cross-border transactions, such as fund-raising, asset management, and insurance. For example, GIFT City in Gujarat, India, is an IFSC that offers services like wealth management and risk management to global clients. 2. What is GIFT City, and how has it performed so far? Gujarat International Finance Tec-City (GIFT City) is India’s first operational IF...",
        link: "#"
    },
    {
        title: "TRANSIT-ORIENTED DEVELOPMENT (TOD) IN INDIA",
        date: "Sep 8, 2024",
        content: "TRANSIT-ORIENTED DEVELOPMENT (TOD) IN INDIA 1. What is Transit-Oriented Development (TOD)? Transit-Oriented Development (TOD) is a planning approach that focuses on building communities around public transportation hubs, like metro stations. The idea is to make it easier for people to live, work, and shop near transit options, reducing the need for cars and making cities more liveable. 2. Why is TOD important? TOD helps reduce traffic congestion, lowers pollution, and makes cities more people-friendly. For example, in cities like Bengaluru and Delhi, if more people live near metro stations, they are less likely to ...",
        link: "#"
    },
    {
        title: "​GENOMIC ANALYSIS IN INFECTIOUS DISEASE MANAGEMENT",
        date: "Sep 8, 2024",
        content: "GENOMIC ANALYSIS IN INFECTIOUS DISEASE MANAGEMENT 1. What is genomic analysis? Genomic analysis involves examining the complete DNA sequence of an organism to understand its genetic structure. In the context of infectious diseases, this analysis helps in identifying and characterizing pathogens, such as viruses, bacteria, fungi, and parasites. This process is crucial for detecting pathogens, understanding their properties, and developing targeted treatments. 2. Why are genomic tests conducted for certain diseases? Genomic tests allow for the precise identification of pathogens by an... ",
        link: "#"
    },
    {
        title: "​THE HARM PRINCIPLE: UNDERSTANDING JOHN STUART MILL'S THEORY OF LIBERTY",
        date: "Sep 8, 2024",
        content: "THE HARM PRINCIPLE: UNDERSTANDING JOHN STUART MILL'S THEORY OF LIBERTY [1️⃣]  What is John Stuart Mill's harm principle? John Stuart Mill's harm principle states that individuals should be free to act as they wish unless their actions cause harm to others. This principle is the foundation of his argument for minimal state intervention in personal liberties. According to Mill, the only justification for restricting an individual's liberty is to prevent harm to others. [2️⃣]  How does Mill define 'harm'? Mill defines harm as an action that makes another person worse off due... ",
        link: "#"
    },
    {
        title: "NAVRATNA PSUs:SIGNIFICANCE OF NHPC, SJVN, RAILTEL, AND SECI BECOMING NAVARATNAS",
        date: "Sep 8, 2024",
        content: "NAVRATNA PSUs:SIGNIFICANCE OF NHPC, SJVN, RAILTEL, AND SECI BECOMING NAVARATNAS 1. What is a Navratna PSU? A Navratna PSU (Public Sector Undertaking) is a central government enterprise in India that has been granted enhanced financial and operational autonomy due to its outstanding performance. This status allows these companies greater flexibility in making strategic decisions, investing in projects, and expanding operations without requiring extensive government approvals. 2. What are the benefits of being a Navratna PSU? Navratna PSUs enjoy several key benefits, including: ...",
        link: "#"
    },
    {
        title: "ARTIFICIAL GENERAL INTELLIGENCE (AGI)",
        date: "Sep 8, 2024",
        content: "ARTIFICIAL GENERAL INTELLIGENCE (AGI) 1. What is Artificial General Intelligence (AGI)? Artificial General Intelligence (AGI) refers to a type of artificial intelligence that can perform any intellectual task that a human being can do. Unlike narrow AI, which is designed for specific tasks like playing chess or recommending movies, AGI has the ability to understand, learn, and apply knowledge across a wide range of tasks, much like a human. The ultimate goal of AGI is to create machines that possess the same cognitive abilities as humans, including reasoning, problem-solving, and even creativity. 2. How is... ",
        link: "#"
    },
    {
        title: "FOREST FIRES IN UTTARAKHAND: CAUSES, IMPACT, AND MANAGEMENT",
        date: "Sep 8, 2024",
        content: "FOREST FIRES IN UTTARAKHAND: CAUSES, IMPACT, AND MANAGEMENT 1. HOW SERIOUS IS THE FOREST FIRE PROBLEM IN UTTARAKHAND? Uttarakhand has been experiencing an increasing frequency of forest fires in recent years. The fires are becoming more intense and widespread, causing damage to large tracts of forest land and threatening human settlements. In 2023, the region witnessed over a thousand large fires, making it one of the worst-affected states in India. 2. WHAT ARE THE PRIMARY CAUSES OF FOREST FIRES IN UTTARAKHAND? Forest fires in Uttarakhand are largely caused by human activities. The main cau...",
        link: "#"
    },
    {
        title: "​FOREST FIRES IN INDIA: AN OVERVIEW",
        date: "Sep 8, 2024",
        content: "FOREST FIRES IN INDIA: AN OVERVIEW 1. WHAT IS A FOREST FIRE? A forest fire is an uncontrolled and rapidly spreading combustion that consumes natural fuels in a forest ecosystem. When such a fire burns out of control, it is referred to as a wildfire. Forest fires have been a significant factor in shaping the development and management of forests worldwide. While some ecosystems have adapted to frequent fires, others are highly susceptible to their destructive effects. 2. HOW DO FOREST FIRES IMPACT THE ENVIRONMENT AND ECONOMY? Forest fires can have varied impacts depending on the ecos... ",
        link: "#"
    },
    {
        title: "​RANSOMWARE: AN OVERVIEW AND ITS IMPACT ON INDIA",
        date: "Sep 8, 2024",
        content: "RANSOMWARE: AN OVERVIEW AND ITS IMPACT ON INDIA What is Ransomware? Ransomware is a type of malicious software (malware) that blocks access to a user's system or files, demanding a ransom payment to restore access. It typically encrypts files or locks the entire system, leaving the victim unable to retrieve their data unless they pay the ransom, often in cryptocurrency. Who Launches Ransomware Attacks and Why? Ransomware attacks are carried out by cybercriminals, ranging from individuals to organized groups. Motivated by financial gain, they often target businesses, government institutions, healthcare providers...",
        link: "#"
    },
    {
        title: "PHOSPHORUS POLLUTION IN INDIAN WATER BODIES: SOURCES, EFFECTS, AND REMEDIES",
        date: "Sep 8, 2024",
        content: "PHOSPHORUS POLLUTION IN INDIAN WATER BODIES: SOURCES, EFFECTS, AND REMEDIES 1. What are the main sources of phosphorus in Indian water bodies? In India, significant sources of phosphorus include natural deposits like rock phosphate, particularly in states like Madhya Pradesh and Rajasthan. Additionally, phosphorus enters water bodies through agricultural runoff, wastewater from treatment plants, and improper disposal of animal manure. 2. How does phosphorus affect aquatic ecosystems in India? High levels of phosphorus can lead to eutrophication, where increased nutrient levels cause excessive growt... ",
        link: "#"
    },
    {
        title: "THE TEESTA WATER TREATY BETWEEN INDIA AND BANGLADESH",
        date: "Sep 8, 2024",
        content: "THE TEESTA WATER TREATY BETWEEN INDIA AND BANGLADESH 1. What is the Teesta River and its significance? The Teesta River is a major tributary of the Brahmaputra, originating from the Tso Lhamo Lake in Sikkim. It flows through Sikkim and West Bengal in India before entering Bangladesh, where it is crucial for agriculture, supporting 8.5% of Bangladesh’s population and 14% of its crop production. 2. What happened to the Teesta water-sharing agreement? In 2011, a proposed agreement suggested that India would receive 42.5% of Teesta’s water and Bangladesh 37.5% during the dry season (December to March). Howe... ",
        link: "#"
    },
    {
        title: "AL-WAHBAH CRATER: EXPLAINER",
        date: "Sep 8, 2024",
        content: "AL-WAHBAH CRATER: EXPLAINER FAQs [1️⃣]  What makes Al-Wahbah Crater unique compared to other volcanic craters? Al-Wahbah Crater is unique due to its size, being the largest and deepest volcanic crater in Saudi Arabia. It spans 2.3 km in diameter and reaches a depth of 250 meters. Additionally, it features a shallow salt lake formed by rainwater accumulation and is part of a volcanic field that includes 175 volcanic cones. Its formation as a maar crater, caused by a phreatic eruption in an aquatic environment, further distinguishes it. [2️⃣]  Why was Al-Wahbah Crater selected as one of the top 100 g...",
        link: "#"
    },
    {
        title: "ANCIENT INDIAN MEDICAL SYSTEM: AN OVERVIEW",
        date: "Sep 8, 2024",
        content: "ANCIENT INDIAN MEDICAL SYSTEM: AN OVERVIEW INTRO Ancient India’s medical knowledge is deeply intertwined with its philosophical and spiritual traditions, resulting in a diverse array of healing practices. Among the most significant systems are Ayurveda, Siddha, and the contributions of ancient scholars like Sushruta. Additionally, the materialist philosophy of Charvaka offers a contrasting perspective on health and wellness. SYSTEMS AND CONTRIBUTIONS Ayurveda is a foundational medical system that has shaped Indian health practices for over 3,000 years. Originating from the Vedic texts like the... ",
        link: "#"
    },
    {
        title: "NARCOTERRORISM: THREAT TO INDIA'S NATIONAL SECURITY",
        date: "Sep 8, 2024",
        content: "NARCOTERRORISM: THREAT TO INDIA'S NATIONAL SECURITY Introduction: Narcoterrorism, a term that describes the nexus between drug trafficking and terrorism, poses a severe threat to India's national security. The phenomenon has evolved significantly since its initial conception in the 1980s, when it referred to the use of violence by Latin American drug cartels against law enforcement. In the Indian context, narcoterrorism involves terrorist groups using drug trafficking networks to fund their activities and destabilize regions. This comprehensive analysis explores the challenges posed by narcoterrorism in India, particularly in regions like Punjab a... ",
        link: "#"
    },
];

const cardData = {
  image: 'https://sriramsias.com/images/upsc-daily-current-affairs.jpg', // Replace with your image URL
  title: 'Popular UPSC Current Affairs',
  description: 'Simultaneous Elections to Lok Sabha (House of The People) and Vidhan Sabhas (State Legislative Assemblies) For and Against',
  articles: [
    { title: 'Simultaneous Elections to Lok Sabha (House of The People) and Vidhan Sabhas (State Legislative Assemblies) For and Against', date: 'Jul 28, 2023' },
    { title: 'Stapled visa issue between India and China', date: 'Jul 28, 2023' },
    { title: 'No Confidence Motion, Confidence Motion and constructive no confidence motion', date: 'Jul 29, 2023' },
    { title: 'Vibrant Village Programme vs Chinese Border Development Programmes', date: 'Jul 31, 2023' },
    { title: 'Ethanol & Food Security:The Trade-off', date: 'Jul 31, 2023' },
    { title: 'Chabahar ,International North-South Transport Corridor (INSTC) and India', date: 'Jul 31, 2023' },
    { title: 'Nuclear families comprise half of Indian households in 2022. Implications of India having 50% of its families as nuclear:', date: 'Jul 31, 2023' },
    { title: 'Jan Vishwas (Amendment of Provisions) Bill, 2023', date: 'Aug 01, 2023' },
    { title: 'Jammu and Kashmir Reorganization (Amendment) Bill, 2023', date: 'Aug 01, 2023' },
    { title: 'Decline in Sex Ratio at Birth in India: Trends', date: 'Aug 01, 2023' },
    { title: 'BRICS Summit and Expansion', date: 'Aug 01, 2023' },
    { title: "India's Proposal for UN Counter-Terrorism Sanctions Regime and China's Double Standards", date: 'Aug 01, 2023' },
    { title: "Reservation Benefits for Transgender Persons in India", date: 'Aug 02, 2023' },
    { title: "Israel's Judicial Reforms and Controversy", date: 'Aug 02, 2023' },
    { title: "Superconductivity at Room Temperature South Korea Claims Breakthrough", date: 'Aug 02, 2023' },
    { title: "Sportswomen of India Today", date: 'Aug 03, 2023' },
    { title: "Goa Civil Code", date: 'Aug 03, 2023' },
    { title: "Right to Silence", date: 'Aug 03, 2023' },
    { title: "India and UAE Local Currency Trade", date: 'Aug 03, 2023' },
    { title: "India and Critical Minerals & Minerals Security Partnership (MSP)", date: 'Aug 04, 2023' },
  ],
};
 
  return (
    <>
      <Helmet>
        <title>About Us | Insight IAS, Delhi | Best IAS Coaching in Delhi</title>
        <meta name="description" content="Insight Academy Delhi, is the leading platform in India that provides coaching for candidates preparing for UPSC, IAS, CUET, NEET, UGC-NET examinations."></meta>
        <meta name="keywords" content="about insight ias, about us, in sight ias, insight ias, Baliyan Sir, balyan sir, baliyan sir history, history optional by baliyan sir, baliyan sir notes, history notes, insight ias delhi, insight ias india, insightsonindia, insights IAS, insight delhi, insight ias test series, insight test series, insight ias main test series, insight prelims test series, upsc coaching in delhi, civil services coaching in delhi, neet coaching center in delhi, ugc net coaching in delhi, CUET coaching in delhi, CUET form 2022, how to apply for CUET, CUET 2022, CUET Exam, Pattern of CUET Exam, Syllabus of CUET Exam, NEET Practice Tests."></meta>
        <meta name="subject" content="Insight Academy Delhi, is the leading platform in India that provides coaching for candidates preparing for UPSC, IAS, CUET, NEET, UGC-NET examinations."></meta>
      </Helmet>
      {/* Civil About Section Start */}
      <section className='civilabout'>
        {/* Civil Top Section Start */}
        <CivilTopSection
          academyName={data.academyName}
          userImage={data.userImage}
          userName={data.userName}
          Image={data.Image}
        />
        {/* Civil Top Section End */}
      </section>
      <div className='Civil-section'>
        <div className='civil-row'>
          <div className='col-lg-12 col-md-12 col-sm-12 me-2'>
            {/* Content Component Start */}
            {/* <Content paragraphstatic={paragraphstatic} /> */}
            {/* Content Component End */}
            {/* <ButtonPage Pagename={Buttonname} /> */}
            {/* <div className='continer-fluid'> */}
              {/* <div className='row'>
                <div className='col-lg-6 p-3'>
                    <div className='card p-4 pb-5' style={{background: "#26156d", color: "white"}}>
                      <h1 className='fw-bold'>OUR VISION</h1>
                      <p>To be the Center of Excellence to ensure our students are successful and that achieve their aspirations within a time frame.</p>
                    </div>
                </div>
                <div className='col-lg-6 p-3'>
                  <div className='card p-4' style={{background: "#db261f", color: "white"}}>
                    <h1 className='fw-bold'>OUR MISSION</h1>
                    <p>To empower aspirants of Civil Services to excel by Imparting to them right and relevant information and perspective. The aim is to help them acquire appropriate analytical skills to be on their own.</p>
                  </div>
                </div>
              </div> */}
            {/* </div> */}
            {/* <div className='container'> */}
              {/*  */}
              <div className='row'>
                <div className='col-lg-12 col-md-12 col-sm-12'>
                  <div className='text-center'>
                    <h1>UPSC Daily Current Affairs :: SRIRAM's IAS</h1>
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-lg-8 col-md-12 col-sm-12'>
                {articles.map((article, index) => (
                  <div>
                  <div class="article-card">
    <h3>{article.title}</h3>
    <div class="article-date">
        <i class="fa fa-calendar"></i>{article.date}
    </div>
    <p>
    {article.content} 
    </p>
    <a href={article.link} class="read-more">Read More...</a>
</div>
                  </div>
))}
                </div>
                <div className='col-lg-4 col-md-12 col-sm-12'>
                <div>
                <ArticleCard
                image={cardData.image}
                mainTitle={cardData.title}
                articles={cardData.articles}
            />
        </div>
                </div>
              </div>
          </div>
          {/* Civil right Section Start */}
          {/* <div className='col-lg-4 col-md-12 col-sm-12 card'>
            <div>
              <CivilrightSection />
              </div>
              <div className="card-body">
                <CurrentAffair componentName={componentName} />
              </div>
          </div> */}
          {/* Civil right Section End */}
        </div>
      </div>
      {/* Civil About Section End */}
    </>
  )
}

export default CurrentFAQ;