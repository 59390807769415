import React from 'react';
import "./Footer.css";
import footerlogo from "../../Image/Logo-1.png"
import { IoIosArrowRoundForward } from "react-icons/io";
import { FaInstagram } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTelegramPlane } from "react-icons/fa";
import { Link } from 'react-router-dom';

function Footer() {
    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    return (
        // Footer Section Start
        <div>
            <div className='FooterContainer' id='footer' onClick={handleScrollToTop}>
                <div className='FooterBox'>
                <div className='Foot5'>
                        <div className='ps-0'>
                            <section>
                                <h1>
                                    <img src={footerlogo} style={{width: "300px"}} alt=''/>
                                </h1>
                            </section>
                            <ul>
                                <li>               
Let's Stay Connected!
                                </li>
                                <li className='mt-2 d-flex'>
                                    <Link to='https://www.instagram.com/sriramsias' style={{borderRadius: "10px", width: "135px", padding: "3vmax 0vmax" , fontSize: "60px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }} className='bg-icon1 text-white mx-2'>
                                        <FaInstagram />
                                    </Link>
                                    <Link to='https://www.facebook.com/sriramsias.official' style={{ borderRadius: "10px", width: "135px",padding: "3vmax 0vmax" , fontSize: "60px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }} className='bg-icon2 text-white mx-2'>
                                        <FaFacebookF />
                                    </Link>
                                    </li>
                                    <li className='mt-2 d-flex'>   
                                    <Link to='https://www.youtube.com/channel/UCbiWt_Ckrd4dqESbsXGBX6Q' style={{ borderRadius: "10px", width: "135px", padding: "3vmax 0vmax" , fontSize: "60px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }} className='bg-icon3 text-white mx-2'>
                                        <FaYoutube />
                                    </Link>
                                    <Link to='https://t.me/sriramsiasdelhi' style={{ borderRadius: "10px", width: "135px",padding: "3vmax 0vmax" , fontSize: "60px", height: "40px", display: "flex", justifyContent: "center", alignItems: "center" }} className='bg-icon4 text-white mx-2'>
                                        <FaTelegramPlane />
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className='Foot1'>
                        <section>
                            <h1>Contact Details</h1>
                        </section>
                        <ul className='ps-0'>
                            <li className=''>
                                <Link className='list-group-item fw-bold' to="#">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    OLD RAJINDER NAGAR
                                </Link>
                                <p>SRIRAM's IAS TOWERS, 10-B, Pusa Road, Bada Bazaar Marg, Old Rajinder Nagar, New Delhi - 110060</p>
                                <p>Phone : (011) 42437002, +91 - 9811489560</p>
                            </li>
                            <li>
                                <Link className='list-group-item fw-bold' to="#">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    MUKHERJEE NAGAR
                                </Link>
                                <p>House No. 868, First Floor, Opposite Batra Cinema, Above ICICI Bank, Dr. Mukherjee Nagar, New Delhi - 110009</p>
                                <p>Phone : +91 - 8447273027</p>
                            </li>
                            <li>
                                <Link className='list-group-item fw-bold' to="#">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    PUNE
                                </Link>
                                <p>Centre 1 - Shop No. 1 along with Office Nos. 1 & 2, ChandraShobha, Ground Floor, C.T.S. No.385, Near Modi Ganpati, Narayan Peth, PUNE - 411030</p>
                                <p>Centre 2 - Shop No. 7, Ground Floor, 'Garud Apartment', C.T.S. Number: 518, Modi Ganpati Road, Narayan Peth, Pune - 411030

Phone : +91 - 9689000979</p>
                            </li>
                        </ul>
                    </div>
                    <div className='Foot2'>
                        <section>
                            <h1>RESOURCES</h1>
                        </section>
                        <ul className='ps-0'>
                            <li>
                                <Link className='list-group-item' to="#">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Explore
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to="#">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Exam Pattern
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to="#">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Prelims Exam Pattern
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to="#">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Mains Exam Pattern
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to="#">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Blogs
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to="#">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Information
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to="#">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Upsc Daliy Current Affairs
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to="#">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    External Links
                                </Link>
                            </li>
                        </ul>
                    </div>
                    {/* <div className='Foot3'>

                        <section>
                            <h1>NEET</h1>
                        </section>
                        <ul className='ps-0'>
                            <li>
                                <Link className='list-group-item' to="/about/about-neet-exam">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    About Exam
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to="/about/pattern-of-neet-exam">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Pattern of Exam
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item' to="/about/frequently-asked-question-for-neet-exam">
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    FAQs
                                </Link>
                            </li>
                        </ul>
                    </div> */}
                    {/* <div className='Foot4'>
                        <section>
                            <h1>CUET</h1>
                        </section>
                        <ul className='ps-0'>
                            <li className='ps-0'>
                                <Link className='list-group-item ps-0'>
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Home
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item'>
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Common Aptitude Tests
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item'>
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    Domain Specific Tests
                                </Link>
                            </li>
                            <li>
                                <Link className='list-group-item'>
                                    <IoIosArrowRoundForward className='fs-3 pb-1' style={{marginLeft: "-5px"}}/>
                                    FAQs
                                </Link>
                            </li>
                        </ul>

                    </div> */}
                    
                </div>
                {/* <div className='Footermiddle'>
                    <p className='text-white'><Link className='text-white text-decoration-none'>GS Foundation Course</Link>, <Link className='text-white text-decoration-none'>History Optional (Online)</Link>, <Link className='text-white text-decoration-none'>History Optional (Offline)</Link>, <Link className='text-white text-decoration-none'>GS History & Culture</Link>, <Link className='text-white text-decoration-none'>Prelims Test Series 2023</Link>, <Link className='text-white text-decoration-none'>Pre-Cum-Mains Test Series 2023</Link>, <Link className='text-white text-decoration-none'>Mains Test Series 2022</Link>, <Link className='text-white text-decoration-none'>History Optional Test Series 2023</Link></p>
                </div> */}
                <div className='footerCopy d-flex justify-content-around'>
                    <h1 className='fs-6 fw-lighter'>© 2024 SRIRAM’s IAS All rights reserved.</h1>
                    <h1 className='fs-6 fw-lighter'>Developed, Maintained & Hosted by Technology Xtend</h1>
                </div>
            </div>
        </div>
        // Footer Section End
    )
}

export default Footer;