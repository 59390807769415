import React, { useEffect, useState } from 'react'
import CardsPage from '../CardsPage/CardsPage'
import { fetchMyCourse } from '../DynamicCourse/api';

function MyCourses({  heading}) {
  const [mycourse, setMycourse] = useState([]);


  const authtoken = localStorage.getItem('authtoken');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data1 = await fetchMyCourse(authtoken);
        setMycourse(data1);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <CardsPage cards={mycourse} heading={heading} />
    </>
  )
}

export default MyCourses
