import React, { useEffect, useState } from 'react';
import "../BlogPage/BlogPage.css";
import "./Homequiz.css"
import Buttontabs from '../Buttontabs/Buttontabs';
import InfiniteScroll from 'react-infinite-scroll-component'; // Importing InfiniteScroll
import BlogPage1 from '../BlogPageComponent/BlogPage1';
import BlogPage2 from '../BlogPageComponent/BlogPage2';
import Pagelayout2 from '../EffectonLoad/pagelayout2';
import { Link } from 'react-router-dom';
import { BASE_URL, BASE_URL1 } from '../../insightConfig/insightConfig';
import Homequizmenu from './Homequizmenu';
import QuestionLayoutQuiz from '../QuizComponent/QuestionLayout/QuestionLayoutQuiz';
import axios from 'axios';

// function Homequiz() {
function Homequiz() {
    const previousSubjectId = localStorage.getItem('previousSubjectId')

    const [subjects, setsubjects] = useState([])
    const [selectedSubject, setSelectedSubject] = useState('')
    const [selectedSubjectId, setSelectedSubjectId] = useState(0)
    const [selectedSection, setSelectedSection] = useState('')
    const [selectedSectionId, setSelectedSectionId] = useState(0)
    const [selectedSectionIndex, setSelectedSectionIndex] = useState(0)
    const [sectionsLength, setSectionsLength] = useState(0)
    const [currentSubject, setCurrentSubject] = useState({});

    console.log(selectedSection, selectedSectionId, selectedSubjectId, selectedSubject)


    const setNewSubject = () => {
        let filteredSubjects = subjects?.filter((subject) => subject.subjectId != previousSubjectId);
        let subjectToDisplay = filteredSubjects[Math.floor(Math.random() * filteredSubjects?.length)]
        setCurrentSubject(subjectToDisplay)
        localStorage.setItem('previousSubjectId', subjectToDisplay?.subjectId)
        setSelectedSubject(subjectToDisplay?.subject)
        setSelectedSubjectId(subjectToDisplay?.subjectId)
        setSelectedSection(subjectToDisplay?.sections[selectedSectionIndex]?.sectionName)
        setSelectedSectionId(subjectToDisplay?.sections[selectedSectionIndex]?.sectionId)
        setSectionsLength(subjectToDisplay?.sections?.length)
    }

    useEffect(() => {
        setNewSubject()
    }, [subjects])

    // useEffect(() => {
    //     if (selectedSectionIndex && currentSubject) {
    //         setSelectedSectionId(currentSubject?.sections[selectedSectionIndex]?.sectionId)
    //         setSelectedSection(currentSubject?.sections[selectedSectionIndex]?.sectionName)
    //     }

    //     // setSelectedSectionId(currentSubject?.sections[selectedSectionIndex ?? 0].sectionId)
    // }, [selectedSectionIndex])



    useEffect(() => {
        axios.get(`${BASE_URL1}/getSubjectAndSections`)
            .then(response => {
                let subjects = response.data.data;
                setsubjects(response.data.data);

                if (!previousSubjectId) {
                    setCurrentSubject(subjects[0])
                    localStorage.setItem('previousSubjectId', 1)
                    setSelectedSubject(subjects[0]?.subject)
                    setSelectedSubjectId(subjects[0]?.subjectId)
                    setSelectedSection(subjects[0]?.sections[selectedSectionIndex].sectionName)
                    setSelectedSectionId(subjects[0]?.sections[selectedSectionIndex].sectionId)
                } 
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    useEffect(() => {
        if (selectedSubjectId) {
            localStorage.setItem('previousSubjectId', selectedSubjectId)
        }

    }, [selectedSubjectId])
    return (
        <>
            <div className='BlogPage-background'>
                <div className='BlogPage-box1'>
                    <h1>INSIGHT UPSC QUIZ</h1>
                </div>
            </div>
            <div className='p-3'>
                <div className='container-fluid quizbackground'>
                    <div className='row'>
                        <div className='col-lg-8 col-md-12 col-sm-12'>
                            {/* <QuestionLayout2/> */}
                            <QuestionLayoutQuiz QuizSectionid={selectedSectionId} QuizSubjectid={selectedSubjectId} selectedSection={selectedSection} selectedSubject={selectedSubject} setSelectedSubjectId={setSelectedSubjectId} setNewSubject={setNewSubject}
                                selectedSectionIndex={selectedSectionIndex} setSelectedSectionIndex={setSelectedSectionIndex} sectionsLength={sectionsLength}

                            />
                        </div>
                        <div className='col-lg-4 col-md-12 col-sm-12'>
                            <Homequizmenu subjects={subjects} setSelectedSectionId={setSelectedSectionId} setSelectedSubjectId={setSelectedSubjectId} setSelectedSection={setSelectedSection} setSelectedSubject={setSelectedSubject} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Homequiz;